import { FIELD_FILE_API } from "../../lib/common/field-file/field-file.constant";
import { httpRequest } from "../../main/http";

import {
  PURCHASE_ADMIN_ITEM_PAYMENT_CONFIRM_API,
  PURCHASE_ADMIN_ITEM_PAYMENT_CONFIRM_ACTION_TYPE,
} from "./purchase-admin-item-payment-confirm.constant";

export function uploadPurchaseAdminItemPaymentConfirmForm(data) {
  return async (dispatch) => {
    dispatch({
      type: PURCHASE_ADMIN_ITEM_PAYMENT_CONFIRM_ACTION_TYPE.FORM_PENDING,
    });

    try {
      await httpRequest({
        method: PURCHASE_ADMIN_ITEM_PAYMENT_CONFIRM_API.CHANGE_PASSWORD.TYPE,
        url: PURCHASE_ADMIN_ITEM_PAYMENT_CONFIRM_API.CHANGE_PASSWORD.ENDPOINT(
          data.purchaseId
        ),
        data,
      });

      dispatch({
        type: PURCHASE_ADMIN_ITEM_PAYMENT_CONFIRM_ACTION_TYPE.FORM_SUCCESS,
      });
    } catch (error) {
      if (error) {
        console.log(error);
      }
      if (error.response) {
        dispatch({
          type: PURCHASE_ADMIN_ITEM_PAYMENT_CONFIRM_ACTION_TYPE.FORM_ERROR,
          errorMessage: error.response.data.message,
        });
      }
    }
  };
}

export function uploadPurchaseAdminItemSetInvoiceLink(data, urlInvoice) {
  return async (dispatch) => {
    dispatch({
      type: PURCHASE_ADMIN_ITEM_PAYMENT_CONFIRM_ACTION_TYPE.FORM_PENDING,
    });

    const invoiceLinkUpdate = {
      invoiceLink: urlInvoice,
    };

    try {
      await httpRequest({
        method: PURCHASE_ADMIN_ITEM_PAYMENT_CONFIRM_API.SET_INVOICE.TYPE,
        url: PURCHASE_ADMIN_ITEM_PAYMENT_CONFIRM_API.SET_INVOICE.ENDPOINT(
          data.purchaseId
        ),
        data: { ...invoiceLinkUpdate },
      });
    } catch (error) {
      if (error) {
        console.log(error);
      }
      if (error.response) {
        dispatch({
          type: PURCHASE_ADMIN_ITEM_PAYMENT_CONFIRM_ACTION_TYPE.FORM_ERROR,
          errorMessage: error.response.data.message,
        });
      }
    }
  };
}

export function uploadPurchaseAdminItemFileConfirmForm(data) {
  return async (dispatch) => {
    dispatch({
      type: PURCHASE_ADMIN_ITEM_PAYMENT_CONFIRM_ACTION_TYPE.FORM_PENDING,
    });

    try {
      const res = await httpRequest({
        method: FIELD_FILE_API.FILE_CREATE.TYPE,
        url: FIELD_FILE_API.FILE_CREATE.ENDPOINT,
        data,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      dispatch({
        type: PURCHASE_ADMIN_ITEM_PAYMENT_CONFIRM_ACTION_TYPE.FORM_SUCCESS,
      });

      return res.data;
    } catch (error) {
      if (error) {
        console.log(error);
      }
      if (error.response) {
        dispatch({
          type: PURCHASE_ADMIN_ITEM_PAYMENT_CONFIRM_ACTION_TYPE.FORM_ERROR,
          errorMessage: error.response.data.message,
        });
      }
    }
  };
}

export function resetPurchaseAdminItemPaymentConfirmFormState() {
  return async (dispatch) => {
    dispatch({
      type: PURCHASE_ADMIN_ITEM_PAYMENT_CONFIRM_ACTION_TYPE.FORM_RESET,
    });
  };
}
