import * as React from "react";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";

import { TextComponent } from "../../lib/common/text/text.component";

import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

import MenuIcon from "@mui/icons-material/Menu";

import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import { NavigationNoticeMobileComponent } from "./navigation-notice-mobile.component";

export function NavigationMobileComponent(props) {
  const navigate = useNavigate();
  const { routeList, activePath, noticeAmount } = props;
  const [value, setValue] = React.useState(false);

  const hanldeOpenMenu = () => {
    setValue(true);
  };

  const hanldeCloseMenu = () => {
    setValue(false);
  };

  return (
    <Box>
      <Drawer onClose={hanldeCloseMenu} open={value}>
        <List disablePadding>
          {routeList.map((route, i) => (
            <ListItem
              disablePadding
              sx={{ px: 6, py: 3, mt: i === 0 ? 0 : 1, borderRadius: 1 }}
              selected={activePath === route.path}
              button
              key={i}
              onClick={() => {
                navigate(route.route);
                setValue(false);
              }}            >
              <ListItemText
                disablePadding
                primary={
                  <TextComponent variant="navigationItem" tid={route.tid} />
                }
              />
            </ListItem>
          ))}
        </List>
      </Drawer>
      <AppBar position="fixed">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 4 }}
            onClick={hanldeOpenMenu}
          >
            <MenuIcon />
          </IconButton>
          <Box sx={{ ml: "auto" }}>
            <NavigationNoticeMobileComponent noticeAmount={noticeAmount} />
          </Box>
        </Toolbar>
      </AppBar>
      <Box
        component="main"
        sx={{
          width: "100vw",
          p: 4,
          // mt: '65px',
          px: 4,
          py: 8,
          pt: "96px",
        }}
      >
        {props.children}
      </Box>
    </Box>
  );
}
