import React from "react";
import { createBrowserRouter } from "react-router-dom";

import { Layout } from "../../Layout";
import {
  AuthRouter,
  IndexRouter,
  PrivateRouter,
  PrivateRouterDynamic,
} from "../../lib/common/router/router.redirect";

import { AUTH_LOGIN_ROUTE_PATH } from "../../core/auth-login/auth-login.constant";
import { AuthLoginPage } from "../../core/auth-login/auth-login.page";

import { AUTH_SIGNUP_ROUTE_PATH } from "../../core/auth-signup/auth-signup.constant";
import { AuthSignupPage } from "../../core/auth-signup/auth-signup.page";

import { ACCOUNT_VERIFICATION_EMAIL_ROUTE_PATH } from "../../core/account-verification-email/account-verification-email.constant";
import { AccountVerificationEmailPage } from "../../core/account-verification-email/account-verification-email.page";

import { ACCOUNT_UPDATE_AUTH_ROUTE_PATH } from "../../core/account-update-auth/account-update-auth.constant";
import { AccountUpdateAuthPage } from "../../core/account-update-auth/account-update-auth.page";

import { ACCOUNT_RECOVERY_ROUTE_PATH } from "../../core/account-recovery/account-recovery.constant";
import { AccountRecoveryPage } from "../../core/account-recovery/account-recovery.page";

import { ERROR_ROUTE_PATH } from "../../core/error/error.constant";
import { ErrorPage } from "../../core/error/error.page";

import { INDEX_ROUTE_PATH } from "../../core/index/index.constant";

import { ACCOUNT_SETTINGS_ROUTE_PATH } from "../../core/account-settings/account-settings.constant";
import { AccountSettingsPage } from "../../core/account-settings/account-settings.page";

import { FILE_ADMIN_ROUTE_PATH } from "../../core/file-admin/file-admin.constant";
import { FileAdminPage } from "../../core/file-admin/file-admin.page";

import { GDS_ADMIN_CREATE_ROUTE_PATH } from "../../core/gds-admin-create/gds-admin-create.constant";
import { GdsAdminCreatePage } from "../../core/gds-admin-create/gds-admin-create.page";

import { GdsAdminItemPage } from "../../core/gds-admin-item/gds-admin-item.page";
import { GDS_ADMIN_ITEM_ROUTE_PATH } from "../../core/gds-admin-item/gds-admin-item.constant";

import { GDS_ADMIN_LIST_ROUTE_PATH } from "../../core/gds-admin-list/gds-admin-list.constant";
import { GdsAdminListPage } from "../../core/gds-admin-list/gds-admin-list.page";

import { INDEX_ADMIN_ROUTE_PATH } from "../../core/index-admin/index-admin.constant";
import { IndexAdminPage } from "../../core/index-admin/index-admin.page";

import { NOTICE_ACCOUNT_LIST_ROUTE_PATH } from "../../core/notice-account-list/notice-account-list.constant";
import { NoticeAccountListPage } from "../../core/notice-account-list/notice-account-list.page";

import { NOTICE_ADMIN_LIST_ROUTE_PATH } from "../../core/notice-admin-list/notice-admin-list.constant";
import { NoticeAdminListPage } from "../../core/notice-admin-list/notice-admin-list.page";

import { PURCHASE_ADMIN_LIST_ROUTE_PATH } from "../../core/purchase-admin-list/purchase-admin-list.constant";
import { PurchaseAdminListPage } from "../../core/purchase-admin-list/purchase-admin-list.page";

import { PURCHASE_CUSTOMER_LIST_ROUTE_PATH } from "../../core/purchase-customer-list/purchase-customer-list.constant";
import { PurchaseCustomerListPage } from "../../core/purchase-customer-list/purchase-customer-list.page";

import { USER_ADMIN_CREATE_ROUTE_PATH } from "../../core/user-admin-create/user-admin-create.constant";
import { UserAdminCreatePage } from "../../core/user-admin-create/user-admin-create.page";

import { USER_ADMIN_LIST_ROUTE_PATH } from "../../core/user-admin-list/user-admin-list.constant";
import { UserAdminListPage } from "../../core/user-admin-list/user-admin-list.page";

import { PurchaseCustomerItemPage } from "../../core/purchase-customer-item/purchase-customer-item.page";
import {
  PURCHASE_CUSTOMER_ITEM_ROUTE_PATH,
  PURCHASE_CUSTOMER_ITEM_ROUTE_PATH_DYNAMIC,
} from "../../core/purchase-customer-item/purchase-customer-item.constant";

import { AUTH_LOGOUT_ROUTE_PATH } from "../../core/auth-logout/auth-logout.constant";
import { AuthLogoutPage } from "../../core/auth-logout/auth-logout.page";

import {
  PURCHASE_ADMIN_ITEM_ROUTE_PATH,
  PURCHASE_ADMIN_ITEM_ROUTE_PATH_DYNAMIC,
} from "../../core/purchase-admin-item/purchase-admin-item.constant";
import { PurchaseAdminItemPage } from "../../core/purchase-admin-item/purchase-admin-item.page";

import {
  PURCHASE_ADMIN_ITEM_UPDATE_ROUTE_PATH,
  PURCHASE_ADMIN_ITEM_UPDATE_ROUTE_PATH_DYNAMIC,
} from "../../core/purchase-admin-item-update/purchase-admin-item-update.constant";
import { PurchaseAdminItemUpdatePage } from "../../core/purchase-admin-item-update/purchase-admin-item-update.page";

import { USER_ADMIN_ITEM_ROUTE_PATH } from "../../core/user-admin-item/user-admin-item.constant";
import { UserAdminItemPage } from "../../core/user-admin-item/user-admin-item.page";

import { GDS_ITEM_ADMIN_LIST_ROUTE_PATH } from "../../core/gds-item-admin-list/gds-item-admin-list.constant";
import { GdsItemAdminListPage } from "../../core/gds-item-admin-list/gds-item-admin-list.page";

import { GdsItemAdminCreatePage } from "../../core/gds-item-admin-create/gds-item-admin-create.page";
import { GDS_ITEM_ADMIN_CREATE_ROUTE_PATH } from "../../core/gds-item-admin-create/gds-item-admin-create.constant";

import { GDS_ITEM_ADMIN_ITEM_ROUTE_PATH } from "../../core/gds-item-admin-item/gds-item-admin-item.constant";
import { GdsItemAdminItemPage } from "../../core/gds-item-admin-item/gds-item-admin-item.page";

import { USER_ROLE_TYPE } from "../../lib/common/user/user.type";
import {
  PURCHASE_CUSTOMER_ITEM_UPDATE_ROUTE_PATH,
  PURCHASE_CUSTOMER_ITEM_UPDATE_ROUTE_PATH_DYNAMIC,
} from "../../core/purchase-customer-item-update/purchase-customer-item-update.constant";
import { PurchaseCustomerItemUpdatePage } from "../../core/purchase-customer-item-update/purchase-customer-item-update.page";

export const router = createBrowserRouter([
  {
    path: AUTH_LOGOUT_ROUTE_PATH,
    element: <AuthRouter component={<AuthLogoutPage />} />,
  },
  {
    path: ACCOUNT_VERIFICATION_EMAIL_ROUTE_PATH,
    element: <AccountVerificationEmailPage />,
  },
  {
    element: <AuthRouter component={<Layout />} />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: INDEX_ROUTE_PATH,
        element: <IndexRouter />,
      },
      {
        path: AUTH_LOGIN_ROUTE_PATH,
        element: <AuthLoginPage />,
      },
      {
        path: AUTH_SIGNUP_ROUTE_PATH,
        element: <AuthSignupPage />,
      },
      {
        path: ACCOUNT_VERIFICATION_EMAIL_ROUTE_PATH,
        element: <AccountVerificationEmailPage />,
      },
      {
        path: ACCOUNT_UPDATE_AUTH_ROUTE_PATH,
        element: <AccountUpdateAuthPage />,
      },
      {
        path: ACCOUNT_RECOVERY_ROUTE_PATH,
        element: <AccountRecoveryPage />,
      },
      {
        path: ERROR_ROUTE_PATH,
        element: <ErrorPage />,
      },
      {
        path: ACCOUNT_SETTINGS_ROUTE_PATH,
        element: <AccountSettingsPage />,
      },
      {
        path: FILE_ADMIN_ROUTE_PATH,
        element: (
          <PrivateRouter
            roles={[USER_ROLE_TYPE.ADMIN]}
            component={<FileAdminPage />}
          />
        ),
      },

      {
        path: GDS_ADMIN_CREATE_ROUTE_PATH,
        element: (
          <PrivateRouter
            roles={[USER_ROLE_TYPE.ADMIN, USER_ROLE_TYPE.EDITOR]}
            component={<GdsAdminCreatePage />}
          />
        ),
      },
      {
        path: GDS_ADMIN_LIST_ROUTE_PATH,
        element: (
          <PrivateRouter
            roles={[USER_ROLE_TYPE.ADMIN, USER_ROLE_TYPE.EDITOR]}
            component={<GdsAdminListPage />}
          />
        ),
      },
      {
        path: INDEX_ADMIN_ROUTE_PATH,
        element: (
          <PrivateRouter
            roles={[USER_ROLE_TYPE.ADMIN]}
            component={<IndexAdminPage />}
          />
        ),
      },
      {
        path: NOTICE_ACCOUNT_LIST_ROUTE_PATH,
        element: <NoticeAccountListPage />,
      },
      {
        path: NOTICE_ADMIN_LIST_ROUTE_PATH,
        element: (
          <PrivateRouter
            roles={[USER_ROLE_TYPE.ADMIN]}
            component={<NoticeAdminListPage />}
          />
        ),
      },
      {
        path: PURCHASE_ADMIN_LIST_ROUTE_PATH,
        element: (
          <PrivateRouter
            roles={[USER_ROLE_TYPE.ADMIN]}
            component={<PurchaseAdminListPage />}
            anotherPath={{
              [USER_ROLE_TYPE.USER]: PURCHASE_CUSTOMER_LIST_ROUTE_PATH,
            }}
          />
        ),
      },
      {
        path: PURCHASE_CUSTOMER_LIST_ROUTE_PATH,
        element: (
          <PrivateRouter
            roles={[USER_ROLE_TYPE.USER]}
            component={<PurchaseCustomerListPage />}
            anotherPath={{
              [USER_ROLE_TYPE.ADMIN]: PURCHASE_ADMIN_LIST_ROUTE_PATH,
            }}
          />
        ),
      },
      {
        path: PURCHASE_CUSTOMER_ITEM_ROUTE_PATH,
        element: (
          <PrivateRouterDynamic
            roles={[USER_ROLE_TYPE.USER]}
            component={<PurchaseCustomerItemPage />}
            anotherPath={{
              [USER_ROLE_TYPE.ADMIN]: PURCHASE_ADMIN_ITEM_ROUTE_PATH_DYNAMIC,
            }}
          />
        ),
      },
      {
        path: PURCHASE_ADMIN_ITEM_ROUTE_PATH,
        element: (
          <PrivateRouterDynamic
            roles={[USER_ROLE_TYPE.ADMIN]}
            component={<PurchaseAdminItemPage />}
            anotherPath={{
              [USER_ROLE_TYPE.USER]: PURCHASE_CUSTOMER_ITEM_ROUTE_PATH_DYNAMIC,
            }}
          />
        ),
      },
      {
        path: PURCHASE_ADMIN_ITEM_UPDATE_ROUTE_PATH,
        element: (
          <PrivateRouterDynamic
            roles={[USER_ROLE_TYPE.ADMIN]}
            component={<PurchaseAdminItemUpdatePage />}
            anotherPath={{
              [USER_ROLE_TYPE.USER]:
                PURCHASE_CUSTOMER_ITEM_UPDATE_ROUTE_PATH_DYNAMIC,
            }}
          />
        ),
      },
      {
        path: PURCHASE_CUSTOMER_ITEM_UPDATE_ROUTE_PATH,
        element: (
          <PrivateRouterDynamic
            roles={[USER_ROLE_TYPE.USER]}
            component={<PurchaseCustomerItemUpdatePage />}
            anotherPath={{
              [USER_ROLE_TYPE.ADMIN]:
                PURCHASE_ADMIN_ITEM_UPDATE_ROUTE_PATH_DYNAMIC,
            }}
          />
        ),
      },
      {
        path: USER_ADMIN_CREATE_ROUTE_PATH,
        element: (
          <PrivateRouter
            roles={[USER_ROLE_TYPE.ADMIN]}
            component={<UserAdminCreatePage />}
          />
        ),
      },
      {
        path: USER_ADMIN_LIST_ROUTE_PATH,
        element: (
          <PrivateRouter
            roles={[USER_ROLE_TYPE.ADMIN]}
            component={<UserAdminListPage />}
          />
        ),
      },
      {
        path: GDS_ADMIN_ITEM_ROUTE_PATH,
        element: (
          <PrivateRouter
            roles={[USER_ROLE_TYPE.ADMIN, USER_ROLE_TYPE.EDITOR]}
            component={<GdsAdminItemPage />}
          />
        ),
      },
      {
        path: USER_ADMIN_ITEM_ROUTE_PATH,
        element: (
          <PrivateRouter
            roles={[USER_ROLE_TYPE.ADMIN]}
            component={<UserAdminItemPage />}
          />
        ),
      },
      {
        path: GDS_ITEM_ADMIN_LIST_ROUTE_PATH,
        element: (
          <PrivateRouter
            roles={[USER_ROLE_TYPE.ADMIN, USER_ROLE_TYPE.EDITOR]}
            component={<GdsItemAdminListPage />}
          />
        ),
      },
      {
        path: GDS_ITEM_ADMIN_CREATE_ROUTE_PATH,
        element: (
          <PrivateRouter
            roles={[USER_ROLE_TYPE.ADMIN, USER_ROLE_TYPE.EDITOR]}
            component={<GdsItemAdminCreatePage />}
          />
        ),
      },
      {
        path: GDS_ITEM_ADMIN_ITEM_ROUTE_PATH,
        element: (
          <PrivateRouter
            roles={[USER_ROLE_TYPE.ADMIN, USER_ROLE_TYPE.EDITOR]}
            component={<GdsItemAdminItemPage />}
          />
        ),
      },
    ],
  },
]);
