export const GDS_STATUS_TYPE = {
  ACTIVE: 1,
  DISABLED: 2,
  ENDING: 3,
};

export const GDS_TYPE_TYPE = {
  CLASSIC: 1,
  ITEM: 2,
  MANUAL: 3,
};

export const GDS_TAG_TYPE = {
  SALE: 1,
  NEW: 2,
  BEST_SELLER: 3,
  INSTANT_DELIVERY: 4,
  MANUAL_DELIVARY: 5,
};

export const GDS_CATEGORY_TYPE = {
  PATCHES: 1,
  HOODIES: 2,
  STICKERS: 3,
};

export const GDS_SORT_TYPE = {
  ORDER: 1,
  ID: 2,
};

export const GDS_TAG_VALUE = {
  1: "SALE",
  2: "NEW",
  3: "BEST_SELLER",
  4: "INSTANT_DELIVERY",
  5: "MANUAL_DELIVARY",
};

export const GDS_SIZE_VALUE = {
  1: "S",
  2: "S-M",
  3: "M",
  4: "M-L",
  5: "L",
  6: "L-XL",
  7: "XL",
  8: "2XL",
};

export const GDS_STATUS_VALUE = {
  1: "ACTIVE",
  2: "DISABLED",
  3: "ENDING",
};

export const GDS_TYPE_VALUE = {
  1: "ORDER",
  2: "PREORDER",
};

export const GDS_CATEGORY_VALUE = {
  1: "PATCHES",
  2: "HOODIES",
  3: "STICKERS",
};

export const GDS_CATEGORY_VIEW_VALUE = {
  1: "Патчі",
  2: "Худі",
  3: "Стікери",
};

export const GDS_TYPE_VIEW_VALUE = {
  1: "Замовлення",
  2: "Передзамовлення",
};

export const GDS_SORT_VALUE = {
  1: "ORDER",
  2: "ID",
};

export const GDS_TYPE = {
  ORDER: 1,
  PREORDER: 2,
};

export const GDS_STATUS_OPTION = [
  { id: 1, value: 1, tid: "GDS.DATA.STATUS.ACTIVE" },
  { id: 2, value: 2, tid: "GDS.DATA.STATUS.DISABLED" },
  { id: 3, value: 3, tid: "GDS.DATA.STATUS.ENDING" },
];

export const GDS_TYPE_OPTION = [
  { id: 1, value: 1, tid: "GDS.DATA.TYPE.ORDER" },
  { id: 2, value: 2, tid: "GDS.DATA.TYPE.PREORDER" },
];

export const GDS_TAG_OPTION = [
  { id: 1, value: 1, tid: "GDS.DATA.TAG.SALE" },
  { id: 2, value: 2, tid: "GDS.DATA.TAG.NEW" },
  { id: 3, value: 3, tid: "GDS.DATA.TAG.BEST_SELLER" },
  { id: 4, value: 4, tid: "GDS.DATA.TAG.INSTANT_DELIVERY" },
  { id: 5, value: 5, tid: "GDS.DATA.TAG.MANUAL_DELIVARY" },
];

export const GDS_SIZE_VIEW_VALUE = {
  1: "S",
  2: "S-M",
  3: "M",
  4: "M-L",
  5: "L",
  6: "L-XL",
  7: "XL",
  8: "2XL",
};

export const GDS_SIZE_OPTION = [
  { id: 1, value: 1, tid: "GDS.DATA.SIZE.S" },
  { id: 2, value: 2, tid: "GDS.DATA.SIZE.S-M" },
  { id: 3, value: 3, tid: "GDS.DATA.SIZE.M" },
  { id: 4, value: 4, tid: "GDS.DATA.SIZE.M-L" },
  { id: 5, value: 5, tid: "GDS.DATA.SIZE.L" },
  { id: 6, value: 6, tid: "GDS.DATA.SIZE.L-XL" },
  { id: 7, value: 7, tid: "GDS.DATA.SIZE.XL" },
  { id: 8, value: 8, tid: "GDS.DATA.SIZE.2XL" },
];

export const GDS_CATEGORY_OPTION = [
  { id: 1, value: 1, tid: "GDS.DATA.CATEGORY.PATCHES" },
  { id: 2, value: 2, tid: "GDS.DATA.CATEGORY.HOODIES" },
  { id: 3, value: 3, tid: "GDS.DATA.CATEGORY.STICKERS" },
];

export const GDS_SORT_OPTION = [
  { id: 1, value: 1, tid: "GDS.DATA.SORT.ORDER" },
  { id: 2, value: 2, tid: "GDS.DATA.SORT.ID" },
];
