import { GDS_ITEM_ADMIN_CREATE_DATA_NAME } from "./gds-item-admin-create.constant";
import { convertGdsItemStatus } from "../../lib/common/gds-item/gds-item.convert";

export const convertGdsItemAdminCreateFormData = (data) => ({
  [GDS_ITEM_ADMIN_CREATE_DATA_NAME.AMOUNT]:
    data[GDS_ITEM_ADMIN_CREATE_DATA_NAME.AMOUNT],

  [GDS_ITEM_ADMIN_CREATE_DATA_NAME.SIZE]:
    data[GDS_ITEM_ADMIN_CREATE_DATA_NAME.SIZE].id,

  [GDS_ITEM_ADMIN_CREATE_DATA_NAME.ORDER]:
    data[GDS_ITEM_ADMIN_CREATE_DATA_NAME.ORDER],

  [GDS_ITEM_ADMIN_CREATE_DATA_NAME.STATUS]:
    data[GDS_ITEM_ADMIN_CREATE_DATA_NAME.STATUS].id,
  [GDS_ITEM_ADMIN_CREATE_DATA_NAME.CREATE_DATE]: String(
    data[GDS_ITEM_ADMIN_CREATE_DATA_NAME.CREATE_DATE]
  ),

  [GDS_ITEM_ADMIN_CREATE_DATA_NAME.GDS_ID]:
    data[GDS_ITEM_ADMIN_CREATE_DATA_NAME.GDS_ID],

  [GDS_ITEM_ADMIN_CREATE_DATA_NAME.ID]:
    data[GDS_ITEM_ADMIN_CREATE_DATA_NAME.ID],
});
