import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";

import Grid from "@mui/material/Grid";

import { TextComponent } from "../../../lib/common/text/text.component";
import { FileListComponent } from "../../../lib/common/file-list/file-list.component";
import { GDS_ITEM_ADMIN_ITEM_DATA_DATA_NAME } from "../gds-item-admin-item-data.constant";
import { GDS_SIZE_VIEW_VALUE } from "../../../lib/common/gds/gds.type";

export function GdsItemAdminItemDataViewComponent(props) {
  const { data } = props;

  return (
    <Grid container spacing={3} sx={{ pt: 4 }}>
      <Grid item>
        <TextComponent
          gutterBottom
          variant="dataViewTitle"
          tid="GDS_ITEM.ADMIN.ITEM_DATA.GDS_ID"
        />
        <TextComponent variant="dataViewContent">
          {data[GDS_ITEM_ADMIN_ITEM_DATA_DATA_NAME.GDS].id}
        </TextComponent>
      </Grid>
      <Grid item>
        <TextComponent
          gutterBottom
          variant="dataViewTitle"
          tid="GDS_ITEM.ADMIN.ITEM_DATA.ORDER"
        />
        <TextComponent variant="dataViewContent">
          {data[GDS_ITEM_ADMIN_ITEM_DATA_DATA_NAME.ORDER]}
        </TextComponent>
      </Grid>
      <Grid item>
        <TextComponent
          gutterBottom
          variant="dataViewTitle"
          tid="GDS_ITEM.ADMIN.ITEM_DATA.NAME"
        />
        <TextComponent variant="dataViewContent">
          {data[GDS_ITEM_ADMIN_ITEM_DATA_DATA_NAME.NAME]}
        </TextComponent>
      </Grid>

      <Grid item>
        <TextComponent
          gutterBottom
          variant="dataViewTitle"
          tid="GDS.ADMIN.ITEM_DATA.STATUS"
        />
        <TextComponent variant="dataViewContent">
          {data[GDS_ITEM_ADMIN_ITEM_DATA_DATA_NAME.STATUS].text}
        </TextComponent>
      </Grid>

      <Grid item>
        <TextComponent
          gutterBottom
          variant="dataViewTitle"
          tid="GDS.ADMIN.ITEM_DATA.CREATE_DATE"
        />
        <TextComponent variant="dataViewContent">
          {data[GDS_ITEM_ADMIN_ITEM_DATA_DATA_NAME.CREATE_DATE]}
        </TextComponent>
      </Grid>
      <Grid item>
        <TextComponent
          gutterBottom
          variant="dataViewTitle"
          tid="GDS.ADMIN.ITEM_DATA.UPDATE_DATE"
        />
        <TextComponent variant="dataViewContent">
          {data[GDS_ITEM_ADMIN_ITEM_DATA_DATA_NAME.UPDATE_DATE]}
        </TextComponent>
      </Grid>
      <Grid item>
        <TextComponent
          gutterBottom
          variant="dataViewTitle"
          tid="GDS_ITEM.ADMIN.ITEM_DATA.SIZE"
        />
        <TextComponent sx={{ whiteSpace: "break-spaces" }} variant="body1">
          {GDS_SIZE_VIEW_VALUE[data[GDS_ITEM_ADMIN_ITEM_DATA_DATA_NAME.SIZE]]}
        </TextComponent>
      </Grid>

      <Grid item>
        <Divider />
      </Grid>

      <Grid item>
        <TextComponent
          gutterBottom
          variant="dataViewTitle"
          tid="GDS_ITEM.ADMIN.ITEM_DATA.AMOUNT"
        />
        <TextComponent variant="dataViewContent">
          {data[GDS_ITEM_ADMIN_ITEM_DATA_DATA_NAME.AMOUNT] || (
            <TextComponent tid="GDS_ITEM.ADMIN.ITEM_DATA.AMOUNT" />
          )}
        </TextComponent>
      </Grid>
    </Grid>
  );
}
