import React from "react";

import { useSelector, useDispatch } from "react-redux";

import { NoticeAccountListPaginationComponent } from "./notice-account-list-pagination.component";

import {
  getRequestData,
  isRequestPending,
} from "../../main/store/store.service";
import { NOTICE_ACCOUNT_LIST_STORE_NAME } from "../notice-account-list/notice-account-list.constant";
import { getNoticeAccountList } from "../notice-account-list/notice-account-list.action";

export function NoticeAccountListPaginationContainer() {
  const dispatch = useDispatch();

  const { state, isLoading } = useSelector((state, isLoading) => ({
    state: getRequestData(state[NOTICE_ACCOUNT_LIST_STORE_NAME].request),
    isLoading: isRequestPending(state[NOTICE_ACCOUNT_LIST_STORE_NAME].request),
  }));

  const onChangeFilter = (data) => {
    dispatch(getNoticeAccountList({ ...state, ...data }));
  };

  return (
    <NoticeAccountListPaginationComponent
      data={state}
      onChangeFilter={onChangeFilter}
      isLoading={isLoading}
    />
  );
}
