import { validate } from "../../main/validate";

import { GDS_ADMIN_CREATE_DATA_NAME } from "./gds-admin-create.constant";

import {
  required,
  arrayLengthMax,
  requiredArray,
  maxLength,
  numberPositive,
  url,
} from "../../main/validate/validate.service";

const config = {
  [GDS_ADMIN_CREATE_DATA_NAME.CATEGORY]: [required],
  [GDS_ADMIN_CREATE_DATA_NAME.DESCRIPTION]: [required, maxLength(500)],
  [GDS_ADMIN_CREATE_DATA_NAME.FILE_PREVIEW]: [
    required,
    requiredArray,
    arrayLengthMax(1),
  ],
  [GDS_ADMIN_CREATE_DATA_NAME.FILE_LIST]: [
    required,
    requiredArray,
    arrayLengthMax(10),
  ],
  [GDS_ADMIN_CREATE_DATA_NAME.ORDER]: [required, numberPositive],
  [GDS_ADMIN_CREATE_DATA_NAME.AMOUNT]: [],
  [GDS_ADMIN_CREATE_DATA_NAME.PRICE]: [required, numberPositive],
  [GDS_ADMIN_CREATE_DATA_NAME.WEIGHT]: [required, numberPositive],
  [GDS_ADMIN_CREATE_DATA_NAME.TAG]: [
    required,
    requiredArray,
    arrayLengthMax(5),
  ],
  [GDS_ADMIN_CREATE_DATA_NAME.TITLE]: [required, maxLength(100)],
  [GDS_ADMIN_CREATE_DATA_NAME.TYPE]: [required],
  [GDS_ADMIN_CREATE_DATA_NAME.URL]: [required, maxLength(50), url],
};

export const gdsAdminCreateFormValidation = (values) =>
  validate(values, config);
