export const PURCHASE_CUSTOMER_LIST_ROUTE_PATH = "/customer/purchase";

export const PURCHASE_CUSTOMER_LIST_STORE_NAME = "PURCHASE_CUSTOMER_LIST";

export const PURCHASE_CUSTOMER_LIST_API = {
  PURCHASE_CUSTOMER_LIST: {
    ENDPOINT: `/purchase/customer`,
    TYPE: "GET",
  },
};

export const PURCHASE_CUSTOMER_LIST_ACTION_TYPE = {
  REQUEST_PENDING: "PURCHASE_CUSTOMER_LIST_ACTION_TYPE.REQUEST_PENDING",
  REQUEST_UPDATE_PENDING:
    "PURCHASE_CUSTOMER_LIST_ACTION_TYPE.REQUEST_UPDATE_PENDING",
  REQUEST_SUCCESS: "PURCHASE_CUSTOMER_LIST_ACTION_TYPE.REQUEST_SUCCESS",
  REQUEST_UPDATE_SUCCESS:
    "PURCHASE_CUSTOMER_LIST_ACTION_TYPE.REQUEST_UPDATE_SUCCESS",
  REQUEST_ERROR: "PURCHASE_CUSTOMER_LIST_ACTION_TYPE.REQUEST_ERROR",
};

export const PURCHASE_CUSTOMER_LIST_DATA_NAME = {
  LIST: "list",

  ID: "id",
  TYPE: "type",
  VALUTE: "valute",
  CREATE_DATE: "createDate",
  STATUS: "status",
  CART: "cart",

  PAYMENT: "payment",
  PAYMENT_ID: "id",
  PAID: "paid",
  CONFIRM: "confirm",
  SYSTEM: "system",
  INVOICE_LINK: "invoiceLink",

  TRACK_NUMBER: "trackNumber",

  PRICE: "price",
  WEIGHT: "weight",
  CUSTOMER: "customer",
  GDS_ORDER: "gdsOrder",
  REGION: "region",
  CITY: "city",
  POST_OFFICE: "postOffice",
  FULL_NAME: "fullName",
  PHONE: "phone",
  TRACK_NUMBER: "trackNumber",
  GDS_ORDER: "gdsOrder",
  WEIGHT: "weight",
  COMMENTS: "comments",
};

export const PURCHASE_CUSTOMER_LIST_PAGINATION_CRON_UPDATE = 5000;
