import Box from '@mui/material/Box';

import Grid from '@mui/material/Grid';

import { TextComponent } from '../../../lib/common/text/text.component';

export function UserAdminItemDataViewComponent(props) {
  const { data } = props;

  return (
    <Grid container spacing={3} sx={{ pt: 4 }}>
      <Grid item>
        <TextComponent
          gutterBottom
          variant="dataViewTitle"
          tid="USER.ADMIN.ITEM_DATA.ID"
        />
        <TextComponent variant="dataViewContent">{data.id}</TextComponent>
      </Grid>
      <Grid item>
        <TextComponent
          gutterBottom
          variant="dataViewTitle"
          tid="USER.ADMIN.ITEM_DATA.LOGIN"
        />
        <TextComponent variant="dataViewContent">{data.login}</TextComponent>
      </Grid>
      <Grid item>
        <TextComponent
          gutterBottom
          variant="dataViewTitle"
          tid="USER.ADMIN.ITEM_DATA.EMAIL"
        />

        <TextComponent variant="dataViewContent">{data.email}</TextComponent>
      </Grid>

      <Grid item>
        <TextComponent
          gutterBottom
          variant="dataViewTitle"
          tid="USER.ADMIN.ITEM_DATA.FULL_NAME"
        />

        <TextComponent variant="dataViewContent">{data.fullName}</TextComponent>
      </Grid>

      <Grid item>
        <TextComponent
          gutterBottom
          variant="dataViewTitle"
          tid="USER.ADMIN.ITEM_DATA.POST_OFFICE"
        />

        <TextComponent variant="dataViewContent">
          {data.postOffice}
        </TextComponent>
      </Grid>

      <Grid item>
        <TextComponent
          gutterBottom
          variant="dataViewTitle"
          tid="USER.ADMIN.ITEM_DATA.PHONE"
        />

        <TextComponent variant="dataViewContent">{data.phone}</TextComponent>
      </Grid>

      <Grid item>
        <TextComponent
          gutterBottom
          variant="dataViewTitle"
          tid="USER.ADMIN.ITEM_DATA.CITY"
        />

        <TextComponent variant="dataViewContent">{data.city}</TextComponent>
      </Grid>

      <Grid item>
        <TextComponent
          gutterBottom
          variant="dataViewTitle"
          tid="USER.ADMIN.ITEM_DATA.REGION"
        />

        <TextComponent variant="dataViewContent">{data.region}</TextComponent>
      </Grid>

      <Grid item>
        <TextComponent
          gutterBottom
          variant="dataViewTitle"
          tid="USER.ADMIN.ITEM_DATA.ROLE"
        />

        <TextComponent variant="dataViewContent">
          {data.role.text}
        </TextComponent>
      </Grid>
      <Grid item>
        <TextComponent
          gutterBottom
          variant="dataViewTitle"
          tid="USER.ADMIN.ITEM_DATA.TIMEZONE"
        />
        <TextComponent variant="dataViewContent">
          {data.timezone.text}
        </TextComponent>
      </Grid>
    </Grid>
  );
}
