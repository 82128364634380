export const PURCHASE_CUSTOMER_ITEM_UPDATE_DATA_STORE_NAME =
  'PURCHASE_CUSTOMER_ITEM_UPDATE_DATA';

export const PURCHASE_CUSTOMER_ITEM_UPDATE_DATA_API = {
  CHANGE_DATA: {
    ENDPOINT: (purchaseId) => `/purchase/${purchaseId}/admin`,
    TYPE: 'PATCH',
  },
};

export const PURCHASE_CUSTOMER_ITEM_UPDATE_DATA_ACTION_TYPE = {
  FORM_PENDING: 'PURCHASE_CUSTOMER_ITEM_UPDATE_DATA_ACTION_TYPE.FORM_PENDING',
  FORM_SUCCESS: 'PURCHASE_CUSTOMER_ITEM_UPDATE_DATA_ACTION_TYPE.FORM_SUCCESS',
  FORM_ERROR: 'PURCHASE_CUSTOMER_ITEM_UPDATE_DATA_ACTION_TYPE.FORM_ERROR',
  FORM_RESET: 'PURCHASE_CUSTOMER_ITEM_UPDATE_DATA_ACTION_TYPE.FORM_RESET',
};

export const PURCHASE_CUSTOMER_ITEM_UPDATE_DATA_DATA_NAME = {
  COMMENTS: 'comments',
  NOTES: 'notes',
  PHONE: 'phone',
  CITY: 'city',
  REGION: 'region',
  POST_OFFICE: 'postOffice',
  FULL_NAME: 'fullName',
};
