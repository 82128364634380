import Box from "@mui/material/Box";

import { FieldTextComponent } from "../../../lib/common/field-text/field-text.component";
import { FieldPasswordComponent } from "../../../lib/common/field-password/field-password.component";

import { TextComponent } from "../../../lib/common/text";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { ACCOUNT_SETTINGS_UPDATE_EMAIL_DATA_NAME } from "../account-settings-update-email.constant";

export const AccountSettingsUpdateEmailFormComponent = (props) => {
  const {
    handleChange,
    handleBlur,
    handleSubmit,
    values,
    errors,
    touched,
    isValid,
    isSubmitting,

    pageLoading,
    isSuccess,
    isError,
    isPending,
    userEmail,
  } = props;

  const isFieldError = (name) => {
    return errors[name] && touched[name] && errors[name];
  };

  const getFieldError = (name) => isFieldError(name) && errors[name];

  const isSubmitDisabled = () => {
    if (isError) {
      return false;
    }

    if (isPending) {
      return true;
    }

    if (JSON.stringify(touched) === "{}") {
      return true;
    }

    if (!isValid || isSuccess || pageLoading) {
      return true;
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box sx={{ pt: 4 }}>
        <Grid spacing={3} container>
          <Grid item>
            <FieldPasswordComponent
              label={<TextComponent tid="ACCOUNT.UPDATE_EMAIL.FORM.PASSWORD" />}
              name={ACCOUNT_SETTINGS_UPDATE_EMAIL_DATA_NAME.PASSWORD}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values[ACCOUNT_SETTINGS_UPDATE_EMAIL_DATA_NAME.PASSWORD]}
              error={isFieldError(
                ACCOUNT_SETTINGS_UPDATE_EMAIL_DATA_NAME.PASSWORD
              )}
              fullWidth
              errorText={getFieldError(
                ACCOUNT_SETTINGS_UPDATE_EMAIL_DATA_NAME.PASSWORD
              )}
            />
          </Grid>
          <Grid item spacing={2}>
            <FieldTextComponent
              label={<TextComponent tid="ACCOUNT.UPDATE_EMAIL.FORM.EMAIL" />}
              disabled
              name={ACCOUNT_SETTINGS_UPDATE_EMAIL_DATA_NAME.EMAIL}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values[ACCOUNT_SETTINGS_UPDATE_EMAIL_DATA_NAME.EMAIL]}
              error={isFieldError(
                ACCOUNT_SETTINGS_UPDATE_EMAIL_DATA_NAME.EMAIL
              )}
              fullWidth
              errorText={getFieldError(
                ACCOUNT_SETTINGS_UPDATE_EMAIL_DATA_NAME.EMAIL
              )}
            />
          </Grid>
          <Grid item>
            <FieldTextComponent
              label={
                <TextComponent tid="ACCOUNT.UPDATE_EMAIL.FORM.NEW_EMAIL" />
              }
              name={ACCOUNT_SETTINGS_UPDATE_EMAIL_DATA_NAME.NEW_EMAIL}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values[ACCOUNT_SETTINGS_UPDATE_EMAIL_DATA_NAME.NEW_EMAIL]}
              error={isFieldError(
                ACCOUNT_SETTINGS_UPDATE_EMAIL_DATA_NAME.NEW_EMAIL
              )}
              fullWidth
              errorText={getFieldError(
                ACCOUNT_SETTINGS_UPDATE_EMAIL_DATA_NAME.NEW_EMAIL
              )}
            />
          </Grid>
          <Grid item>
            <Button type="sumbit" disabled={isSubmitDisabled()} fullWidth>
              <TextComponent tid="ACCOUNT.UPDATE_EMAIL.FORM.SUBMIT" />
            </Button>
          </Grid>
        </Grid>
      </Box>
    </form>
  );
};
