import { ACCOUNT_SETTINGS_UPDATE_USER_DATA_NAME } from "./account-settings-update-user-data.constant";

export const convertAccountSettingsUpdateUserDataFormData = (data) => ({
  [ACCOUNT_SETTINGS_UPDATE_USER_DATA_NAME.FULL_NAME]:
    data[ACCOUNT_SETTINGS_UPDATE_USER_DATA_NAME.FULL_NAME],
  [ACCOUNT_SETTINGS_UPDATE_USER_DATA_NAME.POST_OFFICE]:
    data[ACCOUNT_SETTINGS_UPDATE_USER_DATA_NAME.POST_OFFICE],
  [ACCOUNT_SETTINGS_UPDATE_USER_DATA_NAME.PHONE]:
    data[ACCOUNT_SETTINGS_UPDATE_USER_DATA_NAME.PHONE],
  [ACCOUNT_SETTINGS_UPDATE_USER_DATA_NAME.CITY]:
    data[ACCOUNT_SETTINGS_UPDATE_USER_DATA_NAME.CITY],
  [ACCOUNT_SETTINGS_UPDATE_USER_DATA_NAME.REGION]:
    data[ACCOUNT_SETTINGS_UPDATE_USER_DATA_NAME.REGION].value,
});
