import React from "react";

import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

import { TextComponent } from "../../../lib/common/text/text.component";
import {
  GDS_CATEGORY_VIEW_VALUE,
  GDS_SIZE_VIEW_VALUE,
} from "../../../lib/common/gds/gds.type";

export function PurchaseCustomerItemDataGdsViewComponent(props) {
  const { data } = props;
  return (
    <Grid container spacing={4} sx={{ pt: 4 }}>
      <Grid item>
        <Grid container spacing={3}>
          {data?.cart?.gdsOrders ? (
            data.cart.gdsOrders.map((e, index) => (
              <React.Fragment>
                <Grid item>
                  <TextComponent
                    gutterBottom
                    variant="dataViewTitle"
                    tid="PURCHASE.CUSTOMER.GDS.TITLE_LABEL"
                  />
                  <TextComponent
                    tid="PURCHASE.CUSTOMER.GDS.TITLE_DATA"
                    tvalue={{
                      title: e.gds.title,
                    }}
                    variant="dataViewContent"
                  />
                </Grid>
                <Grid item>
                  <TextComponent
                    gutterBottom
                    variant="dataViewTitle"
                    tid="PURCHASE.CUSTOMER.GDS.DESCRIPTION"
                  />
                  <TextComponent
                    tid="PURCHASE.CUSTOMER.GDS.DESCRIPTION_DATA"
                    sx={{ lineHeight: "1.4em" }}
                    tvalue={{
                      title: e.gds.description,
                    }}
                    variant="dataViewContent"
                  />
                </Grid>
                <Grid item>
                  <TextComponent
                    gutterBottom
                    variant="dataViewTitle"
                    tid="PURCHASE.CUSTOMER.GDS.SIZE"
                  />
                  <TextComponent
                    tid="PURCHASE.CUSTOMER.GDS.SIZE_VALUE"
                    tvalue={{
                      size: e.gdsItem?.size
                        ? GDS_SIZE_VIEW_VALUE[e.gdsItem.size]
                        : "no-size",
                    }}
                    variant="dataViewContent"
                  />
                </Grid>
                <Grid item>
                  <TextComponent
                    gutterBottom
                    variant="dataViewTitle"
                    tid="PURCHASE.CUSTOMER.GDS.AMOUNT"
                  />
                  <TextComponent
                    tid="PURCHASE.CUSTOMER.GDS.AMOUNT_DATA"
                    tvalue={{
                      amount: e.amount,
                    }}
                    variant="dataViewContent"
                  />
                </Grid>
                <Grid item>
                  <TextComponent
                    gutterBottom
                    variant="dataViewTitle"
                    tid="PURCHASE.CUSTOMER.GDS.PRICE"
                  />
                  <TextComponent
                    tid="PURCHASE.CUSTOMER.GDS.PRICE_DATA"
                    tvalue={{
                      price: e.gds.price,
                    }}
                    variant="dataViewContent"
                  />
                </Grid>
                <Grid item>
                  <TextComponent
                    gutterBottom
                    variant="dataViewTitle"
                    tid="PURCHASE.CUSTOMER.GDS.WEIGHT"
                  />
                  <TextComponent
                    tid="PURCHASE.CUSTOMER.GDS.WEIGHT_DATA"
                    tvalue={{
                      weight: e.gds.weight,
                    }}
                    variant="dataViewContent"
                  />
                </Grid>
                <Grid item>
                  <TextComponent
                    gutterBottom
                    variant="dataViewTitle"
                    tid="PURCHASE.CUSTOMER.GDS.CATEGORY"
                  />
                  <TextComponent
                    tid="PURCHASE.CUSTOMER.GDS.CATEGORY_DATA"
                    tvalue={{
                      category: GDS_CATEGORY_VIEW_VALUE[e.gds.category],
                    }}
                    variant="dataViewContent"
                  />
                </Grid>
                {index !== data.cart.gdsOrders.length - 1 && (
                  <Grid item>
                    <Divider />
                  </Grid>
                )}
              </React.Fragment>
            ))
          ) : (
            <React.Fragment>
              <Grid item>
                <TextComponent
                  tid="PURCHASE.CUSTOMER.GDS.GDS_NOT_SET"
                  variant="dataViewContent"
                />
              </Grid>
            </React.Fragment>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}
