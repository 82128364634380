import React from "react";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Grid from "@mui/material/Grid";
import styled from "styled-components";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";

import { TextComponent } from "../../../lib/common/text/text.component";
import { PURCHASE_ADMIN_ITEM_ROUTE_PATH_DYNAMIC } from "../../purchase-admin-item/purchase-admin-item.constant";
import {
  GDS_CATEGORY_VIEW_VALUE,
  GDS_SIZE_VIEW_VALUE,
  GDS_TYPE,
  GDS_TYPE_VIEW_VALUE,
} from "../../../lib/common/gds/gds.type";
import { IconButton, Link } from "@mui/material";
import { FieldTextComponent } from "../../../lib/common/field-text/field-text.component";
import { PURCHASE_ADMIN_LIST_DATA_NAME } from "../purchase-admin-list.constant";
import { text } from "../../../lib/common/text";
import { PURCHASE_STATUS_TYPE } from "../../../lib/common/purchase/purchase.type";

export function PurchaseAdminListViewComponent(props) {
  const {
    data,
    handleSubmitForm,
    handleChange,
    handleBlur,
    values,
    isPending,
    pageLoading,
  } = props;

  if (data?.list?.length === 0) {
    return (
      <Box sx={{ px: 6, pt: 4 }}>
        <TextComponent
          variant="body1"
          tid="PURCHASE.ADMIN.LIST.LIST_IS_EMPTY"
        />
      </Box>
    );
  }
  const navigate = useNavigate();

  const handleClickItem = (purchaseId) => (e) => {
    e.preventDefault();
    return navigate(PURCHASE_ADMIN_ITEM_ROUTE_PATH_DYNAMIC({ purchaseId }));
  };

  return (
    <List sx={{ pt: 2 }}>
      {data?.list.map((item, itemIndex) => {
        const itemName = `${PURCHASE_ADMIN_LIST_DATA_NAME.TRACK_NUMBER}${item.id}`;
        const itemValue = values[itemName];
        const isSendButtonDisabled = isPending || pageLoading;

        const isFieldError = false;

        return (
          <React.Fragment key={itemIndex}>
            <ListItem
              sx={{
                px: 6,
              }}
              onClick={handleClickItem(item.id)}
              variant="list"
              button
              component="a"
              href={PURCHASE_ADMIN_ITEM_ROUTE_PATH_DYNAMIC({
                purchaseId: item.id,
              })}
            >
              <Grid width="100%">
                <Container>
                  <Grid
                    sx={{ width: "100%" }}
                    width="100%"
                    item
                    xs={12}
                    md={2.3}
                  >
                    <TextComponent
                      tid="PURCHASE.ADMIN.LIST.ID"
                      variant="body1"
                      component="div"
                      tvalue={{ id: item.id }}
                    />
                    <TextComponent
                      tid="PURCHASE.ADMIN.LIST.CREATE_DATE"
                      variant="body1"
                      component="div"
                      tvalue={{ date: item.createDate }}
                    />
                    {item?.cart?.gds?.type === GDS_TYPE.PREORDER && (
                      <TextComponent
                        tid="PURCHASE.ADMIN.LIST.PREORDER"
                        variant="body1"
                        component="div"
                        sx={{ color: "#f0b102" }}
                      />
                    )}
                  </Grid>

                  <Grid item xs={12} md={2.3}>
                    <TextComponent
                      tid="PURCHASE.ADMIN.LIST.FULL_NAME"
                      variant="body1"
                      tvalue={{
                        fullName: item?.customer?.fullName,
                      }}
                    />
                    <TextComponent
                      tid="PURCHASE.ADMIN.LIST.CONTACTS"
                      variant="body1"
                      tvalue={{
                        contacts: item?.customer?.phone,
                      }}
                    />
                    {item.isCustomer && (
                      <React.Fragment>
                        <Grid item>
                          <TextComponent
                            sx={{
                              wordBreak: "break-all",
                              whiteSpace: "break-spaces",
                            }}
                            tid="PURCHASE.ADMIN.LIST.CUSTOMER"
                            variant="body1"
                            tvalue={{
                              email: item.customer.email,
                            }}
                          />
                        </Grid>
                      </React.Fragment>
                    )}
                    <TextComponent
                      tid="PURCHASE.ADMIN.LIST.REGION"
                      variant="body1"
                      tvalue={{
                        region: item?.customer?.region,
                        city: item?.customer?.city,
                      }}
                    />
                    <TextComponent
                      tid="PURCHASE.ADMIN.LIST.POST_OFFICE"
                      variant="body1"
                      tvalue={{
                        number: item?.customer?.postOffice,
                      }}
                    />
                    {item?.comments && (
                      <TextComponent
                        tid="PURCHASE.ADMIN.LIST.COMMENTS"
                        variant="body1"
                        tvalue={{
                          comments: item?.comments,
                        }}
                      />
                    )}
                  </Grid>

                  <Grid item xs={12} md={2}>
                    <Grid container spacing={1}>
                      <Grid item>
                        <TextComponent
                          tid="PURCHASE.ADMIN.LIST.STATUS"
                          variant="body1"
                          sx={{
                            color:
                              item.status.id === PURCHASE_STATUS_TYPE.DELETED &&
                              "red",
                          }}
                          tvalue={{
                            status: item.status.text,
                          }}
                        />
                      </Grid>

                      {item.paid ? (
                        <React.Fragment>
                          <Grid item>
                            <TextComponent
                              tid="PURCHASE.ADMIN.LIST.PAYMENT_SYSTEM"
                              variant="body1"
                              sx={{ color: "green" }}
                              tvalue={{ system: item?.payment?.system }}
                            />
                          </Grid>
                          <Grid item>
                            {item.payment.confirm ? (
                              <TextComponent
                                tid="PURCHASE.ADMIN.LIST.PAYMENT_CONFIRM_TRUE"
                                variant="body1"
                                sx={{ color: "green" }}
                              />
                            ) : (
                              <TextComponent
                                tid="PURCHASE.ADMIN.LIST.PAYMENT_CONFIRM_FALSE"
                                variant="body1"
                                sx={{ color: "red" }}
                              />
                            )}
                          </Grid>
                        </React.Fragment>
                      ) : (
                        <Grid item>
                          <TextComponent
                            tid="PURCHASE.ADMIN.LIST.PAID_FALSE"
                            variant="body1"
                            sx={{ color: "red" }}
                          />
                        </Grid>
                      )}
                      <Grid item>
                        <TextComponent
                          sx={{
                            wordBreak: "break-all",
                            whiteSpace: "break-spaces",
                          }}
                          tid="PURCHASE.ADMIN.LIST.PRICE"
                          variant="body1"
                          tvalue={{
                            price: item.price,
                          }}
                        />
                        <TextComponent
                          sx={{
                            wordBreak: "break-all",
                            whiteSpace: "break-spaces",
                          }}
                          tid="PURCHASE.ADMIN.LIST.WEIGHT"
                          variant="body1"
                          tvalue={{
                            weight: item.weight.substring(0, 4),
                          }}
                        />
                        {item.invoiceLink && (
                          <Grid item>
                            <Link
                              sx={{
                                color: "#ededed",
                                textDecorationColor: "#ededed !important",
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                              href={item.invoiceLink}
                            >
                              <TextComponent
                                tid="PURCHASE.ADMIN.ITEM_DATA.INVOICE_URL"
                                variant="dataViewContent"
                              />
                            </Link>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={2.3}>
                    {item?.cart?.gdsOrders &&
                      item.cart.gdsOrders.map((e) => {
                        const isSize = !!e.gdsItem?.size;
                        return (
                          <TextComponent
                            tid={`${e?.gds?.title}${isSize ? "-" : ""}${GDS_SIZE_VIEW_VALUE[[e.gdsItem?.size]] || ""}-${e.amount}`}
                            variant="body1"
                          />
                        );
                      })}
                  </Grid>
                </Container>
              </Grid>
            </ListItem>
            <form
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
              }}
            >
              <ContainerTrackNumber>
                <TextComponentStyled
                  tid="PURCHASE.ADMIN.TRACK_NUMBER"
                  variant="body1"
                />
                <FieldTextComponent
                  name={itemName}
                  type="number"
                  error={isFieldError}
                  endAdornment={
                    <IconButton
                      type="submit"
                      onClick={() => {
                        handleSubmitForm(itemValue, item.id);
                      }}
                      disabled={isSendButtonDisabled}
                    >
                      <DoneOutlinedIcon />
                    </IconButton>
                  }
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={itemValue}
                />
              </ContainerTrackNumber>
            </form>
            {itemIndex !== data?.list.length - 1 && (
              <ListItem sx={{ padding: "16px" }}>
                <Divider sx={{ width: "100%", opacity: 0.35 }} />
              </ListItem>
            )}
          </React.Fragment>
        );
      })}
    </List>
  );
}

const TextComponentStyled = styled(TextComponent)`
  white-space: nowrap;
`;

const Container = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 12px;

  @media (max-width: 1200px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media (max-width: 900px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`;

const ContainerTrackNumber = styled.div`
  margin-top: 8px;
  padding: 0 24px;
  display: flex;
  gap: 10px;
`;
