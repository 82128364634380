import { PURCHASE_ADMIN_LIST_DATA_NAME } from "./purchase-admin-list.constant";

import {
  convertPurchaseType,
  convertPurchaseStatus,
} from "../../lib/common/purchase/purchase.convert";
import { convertDatetime } from "../../lib/common/convert/convert.ua";

export const convertPurchaseAdminList = (d) => ({
  [PURCHASE_ADMIN_LIST_DATA_NAME.STATUS]:
    d[PURCHASE_ADMIN_LIST_DATA_NAME.STATUS],
  [PURCHASE_ADMIN_LIST_DATA_NAME.TYPE]: d[PURCHASE_ADMIN_LIST_DATA_NAME.TYPE],
  [PURCHASE_ADMIN_LIST_DATA_NAME.PAID]: d[PURCHASE_ADMIN_LIST_DATA_NAME.PAID],
  [PURCHASE_ADMIN_LIST_DATA_NAME.TAKE]: d[PURCHASE_ADMIN_LIST_DATA_NAME.TAKE],

  [PURCHASE_ADMIN_LIST_DATA_NAME.SKIP]: d[PURCHASE_ADMIN_LIST_DATA_NAME.SKIP],

  [PURCHASE_ADMIN_LIST_DATA_NAME.LIST]: d[
    PURCHASE_ADMIN_LIST_DATA_NAME.LIST
  ].map((data) => {
    const purchaseType = convertPurchaseType(
      data[PURCHASE_ADMIN_LIST_DATA_NAME.TYPE]
    );

    const paid = !!data[PURCHASE_ADMIN_LIST_DATA_NAME.PAYMENT];

    const paymentData = data[PURCHASE_ADMIN_LIST_DATA_NAME.PAYMENT];

    const payment = paid
      ? {
          [PURCHASE_ADMIN_LIST_DATA_NAME.PAYMENT_ID]:
            paymentData[PURCHASE_ADMIN_LIST_DATA_NAME.PAYMENT_ID],
          [PURCHASE_ADMIN_LIST_DATA_NAME.CONFIRM]:
            paymentData[PURCHASE_ADMIN_LIST_DATA_NAME.CONFIRM],
          [PURCHASE_ADMIN_LIST_DATA_NAME.SYSTEM]:
            paymentData[PURCHASE_ADMIN_LIST_DATA_NAME.SYSTEM],
        }
      : null;

    const isCustomer = !!data[PURCHASE_ADMIN_LIST_DATA_NAME.CUSTOMER];
    const customerData = data[PURCHASE_ADMIN_LIST_DATA_NAME.CUSTOMER];

    const customer = {
      [PURCHASE_ADMIN_LIST_DATA_NAME.CUSTOMER_ID]:
        customerData?.id &&
        customerData[PURCHASE_ADMIN_LIST_DATA_NAME.CUSTOMER_ID],
      [PURCHASE_ADMIN_LIST_DATA_NAME.CUSTOMER_EMAIL]:
        data[PURCHASE_ADMIN_LIST_DATA_NAME.CUSTOMER_EMAIL],
      [PURCHASE_ADMIN_LIST_DATA_NAME.CITY]:
        data[PURCHASE_ADMIN_LIST_DATA_NAME.CITY],
      [PURCHASE_ADMIN_LIST_DATA_NAME.FULL_NAME]:
        data[PURCHASE_ADMIN_LIST_DATA_NAME.FULL_NAME],
      [PURCHASE_ADMIN_LIST_DATA_NAME.REGION]:
        data[PURCHASE_ADMIN_LIST_DATA_NAME.REGION],
      [PURCHASE_ADMIN_LIST_DATA_NAME.PHONE]:
        data[PURCHASE_ADMIN_LIST_DATA_NAME.PHONE],
      [PURCHASE_ADMIN_LIST_DATA_NAME.POST_OFFICE]:
        data[PURCHASE_ADMIN_LIST_DATA_NAME.POST_OFFICE],
    };

    return {
      [PURCHASE_ADMIN_LIST_DATA_NAME.ID]:
        data[PURCHASE_ADMIN_LIST_DATA_NAME.ID],
      [PURCHASE_ADMIN_LIST_DATA_NAME.PRICE]:
        data[PURCHASE_ADMIN_LIST_DATA_NAME.PRICE],
      [PURCHASE_ADMIN_LIST_DATA_NAME.WEIGHT]:
        data[PURCHASE_ADMIN_LIST_DATA_NAME.WEIGHT],
      [PURCHASE_ADMIN_LIST_DATA_NAME.COMMENTS]:
        data[PURCHASE_ADMIN_LIST_DATA_NAME.COMMENTS],

      [PURCHASE_ADMIN_LIST_DATA_NAME.INVOICE_LINK]:
        data[PURCHASE_ADMIN_LIST_DATA_NAME.INVOICE_LINK],

      [PURCHASE_ADMIN_LIST_DATA_NAME.STATUS]: convertPurchaseStatus(
        data[PURCHASE_ADMIN_LIST_DATA_NAME.STATUS]
      ),
      [PURCHASE_ADMIN_LIST_DATA_NAME.TRACK_NUMBER]:
        data[PURCHASE_ADMIN_LIST_DATA_NAME.TRACK_NUMBER],
      [PURCHASE_ADMIN_LIST_DATA_NAME.CART]:
        data[PURCHASE_ADMIN_LIST_DATA_NAME.CART],

      [PURCHASE_ADMIN_LIST_DATA_NAME.TYPE]: purchaseType,

      [PURCHASE_ADMIN_LIST_DATA_NAME.CREATE_DATE]: convertDatetime(
        data[PURCHASE_ADMIN_LIST_DATA_NAME.CREATE_DATE]
      ),

      [PURCHASE_ADMIN_LIST_DATA_NAME.CREATE_DATE_STOCK]:
        data[PURCHASE_ADMIN_LIST_DATA_NAME.CREATE_DATE],
      [PURCHASE_ADMIN_LIST_DATA_NAME.PAID]: paid,
      [PURCHASE_ADMIN_LIST_DATA_NAME.PAYMENT]: payment,

      [PURCHASE_ADMIN_LIST_DATA_NAME.IS_CUSTOMER]: isCustomer,
      [PURCHASE_ADMIN_LIST_DATA_NAME.CUSTOMER]: customer,

      [PURCHASE_ADMIN_LIST_DATA_NAME.METADATA]:
        data[PURCHASE_ADMIN_LIST_DATA_NAME.METADATA],
    };
  }),
});
