import { validate } from "../../main/validate";
import { required } from "../../main/validate/validate.service";

import { PURCHASE_ADMIN_ITEM_TIME_CREATE_DATA_NAME } from "./purchase-list-upload.constant";

const config = {
  [PURCHASE_ADMIN_ITEM_TIME_CREATE_DATA_NAME.FROM_TIME]: [required],
  [PURCHASE_ADMIN_ITEM_TIME_CREATE_DATA_NAME.TO_TIME]: [required],
};

export const purchaseListUploadFormValidation = (values) =>
  validate(values, config);
