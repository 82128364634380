import React from "react";
import { useParams } from "react-router-dom/dist";
import { useDispatch, useSelector } from "react-redux";

import {
  getRequestData,
  getRequestErrorMessage,
  isRequestError,
  isRequestPending,
  isRequestSuccess,
} from "../../main/store/store.service";
import { NAVIGATION_STORE_NAME } from "../../lib/common/navigation";

import { uploadPurchaseCustomerItemUpdateDataForm } from "./purchase-customer-item-update-data.action";
import {
  PURCHASE_CUSTOMER_ITEM_UPDATE_DATA_DATA_NAME,
  PURCHASE_CUSTOMER_ITEM_UPDATE_DATA_STORE_NAME,
} from "./purchase-customer-item-update-data.constant";
import { convertPurchaseCustomerItemUpdateDataFormData } from "./purchase-customer-item-update-data.convert";
import { purchaseCustomerItemUpdateDataFormValidation } from "./purchase-customer-item-update-data.validation";
import { PurchaseCustomerItemUpdateDataComponent } from "./purchase-customer-item-update-data.component";
import { PURCHASE_CUSTOMER_ITEM_DATA_NAME } from "../purchase-customer-item/purchase-customer-item.constant";
import { PURCHASE_CUSTOMER_ITEM_STORE_NAME } from "../purchase-customer-item/purchase-customer-item.constant";
import { getPurchaseCustomerItem } from "../purchase-customer-item/purchase-customer-item.action";
import { REGION_OPTION } from "../account-settings-update-user-data/account-settings-update-user-data.constant";

export function PurchaseCustomerItemUpdateDataContainer() {
  const dispatch = useDispatch();
  let { purchaseId } = useParams();

  const { customerDataState, pageLoading, state } = useSelector((state) => ({
    state: state[PURCHASE_CUSTOMER_ITEM_UPDATE_DATA_STORE_NAME],
    pageLoading: state[NAVIGATION_STORE_NAME].pageLoading,
    customerDataState: state[PURCHASE_CUSTOMER_ITEM_STORE_NAME],
  }));

  React.useEffect(() => {
    const data = { purchaseId };

    dispatch(getPurchaseCustomerItem(data));
  }, []);

  const purchaseCustomerItemUpdateDataFormSendData = (values) => {
    const data = convertPurchaseCustomerItemUpdateDataFormData(values);

    data.purchaseId = purchaseId;

    dispatch(uploadPurchaseCustomerItemUpdateDataForm(data));
  };

  const getInitialValue = () => {
    if (isRequestSuccess(customerDataState.request)) {
      const data = getRequestData(customerDataState.request);

      return {
        [PURCHASE_CUSTOMER_ITEM_UPDATE_DATA_DATA_NAME.NOTES]:
          data[PURCHASE_CUSTOMER_ITEM_DATA_NAME.NOTES] || "",
        [PURCHASE_CUSTOMER_ITEM_DATA_NAME.PHONE]:
          data.customer[PURCHASE_CUSTOMER_ITEM_DATA_NAME.PHONE] || "",
        [PURCHASE_CUSTOMER_ITEM_DATA_NAME.CITY]:
          data.customer[PURCHASE_CUSTOMER_ITEM_DATA_NAME.CITY] || "",
        [PURCHASE_CUSTOMER_ITEM_DATA_NAME.REGION]:
          REGION_OPTION.find(
            (e) =>
              e.value === data.customer[PURCHASE_CUSTOMER_ITEM_DATA_NAME.REGION]
          ) || "",
        [PURCHASE_CUSTOMER_ITEM_DATA_NAME.POST_OFFICE]:
          data.customer[PURCHASE_CUSTOMER_ITEM_DATA_NAME.POST_OFFICE] || "",
        [PURCHASE_CUSTOMER_ITEM_DATA_NAME.FULL_NAME]:
          data.customer[PURCHASE_CUSTOMER_ITEM_DATA_NAME.FULL_NAME] || "",
      };
    }

    return {};
  };

  return (
    <PurchaseCustomerItemUpdateDataComponent
      data={getRequestData(customerDataState.request)}
      isPending={isRequestPending(state?.form)}
      isError={isRequestError(state?.form)}
      isDataSuccess={isRequestSuccess(customerDataState.request)}
      isSuccess={isRequestSuccess(state?.form)}
      initialValue={getInitialValue()}
      validation={purchaseCustomerItemUpdateDataFormValidation}
      onSubmitForm={purchaseCustomerItemUpdateDataFormSendData}
      pageLoading={pageLoading}
      errorMessage={getRequestErrorMessage(state?.form)}
    />
  );
}
