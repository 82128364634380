import { validate } from "../../main/validate";

import { GDS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME } from "./gds-admin-item-update-data.constant";

import {
  requiredArray,
  arrayLengthMax,
  required,
  maxLength,
  numberPositive,
  url,
} from "../../main/validate/validate.service";

const config = {
  [GDS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.FILE_PREVIEW]: [arrayLengthMax(1)],
  [GDS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.FILE_LIST]: [arrayLengthMax(10)],
  [GDS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.CATEGORY]: [],
  [GDS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.DESCRIPTION]: [maxLength(500)],
  [GDS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.PRICE]: [numberPositive],
  [GDS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.AMOUNT]: [numberPositive],
  [GDS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.STATUS]: [],
  [GDS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.TAG]: [],
  [GDS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.PPO]: [],
  [GDS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.TITLE]: [maxLength(100)],
  [GDS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.TYPE]: [],
  [GDS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.URL]: [maxLength(50), url],
};

export const gdsAdminItemUpdateDataFormValidation = (values) =>
  validate(values, config);
