import { ACCOUNT_SETTINGS_ROUTE_PATH } from "../account-settings/account-settings.constant";
import { USER_ADMIN_LIST_ROUTE_PATH } from "../user-admin-list/user-admin-list.constant";
import { PURCHASE_ADMIN_LIST_ROUTE_PATH } from "../purchase-admin-list/purchase-admin-list.constant";
import { PURCHASE_CUSTOMER_LIST_ROUTE_PATH } from "../purchase-customer-list/purchase-customer-list.constant";
import { GDS_ADMIN_LIST_ROUTE_PATH } from "../gds-admin-list/gds-admin-list.constant";

import SettingsIcon from "@mui/icons-material/Settings";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import CategoryIcon from "@mui/icons-material/Category";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import NotificationsIcon from "@mui/icons-material/Notifications";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";

import { NOTICE_ACCOUNT_LIST_ROUTE_PATH } from "../notice-account-list/notice-account-list.constant";
import { NOTICE_ADMIN_LIST_ROUTE_PATH } from "../notice-admin-list/notice-admin-list.constant";
import { FILE_ADMIN_ROUTE_PATH } from "../file-admin/file-admin.constant";

export const NAVIGATION_ADMIN_ROUTE_LIST = [
  {
    id: 6,
    route: PURCHASE_ADMIN_LIST_ROUTE_PATH,
    tid: "NAVIGATION.ROUTE.PURCHASE",
    path: PURCHASE_ADMIN_LIST_ROUTE_PATH,
    icon: <ShoppingCartIcon />,
  },
  {
    id: 6,
    route: GDS_ADMIN_LIST_ROUTE_PATH,
    tid: "NAVIGATION.ROUTE.GDS",
    path: GDS_ADMIN_LIST_ROUTE_PATH,
    icon: <CategoryIcon />,
  },
  {
    id: 2,
    route: USER_ADMIN_LIST_ROUTE_PATH,
    tid: "NAVIGATION.ROUTE.USER",
    path: USER_ADMIN_LIST_ROUTE_PATH,
    icon: <PeopleAltIcon />,
  },
  {
    id: 4,
    route: FILE_ADMIN_ROUTE_PATH,
    tid: "NAVIGATION.ROUTE.FILE",
    path: FILE_ADMIN_ROUTE_PATH,
    icon: <InsertDriveFileIcon />,
  },
  {
    id: 6,
    route: NOTICE_ADMIN_LIST_ROUTE_PATH,
    tid: "NAVIGATION.ROUTE.ADMIN_NOTICE",
    path: NOTICE_ADMIN_LIST_ROUTE_PATH,
    icon: <NotificationsIcon />,
  },
  {
    id: 13,
    route: NOTICE_ACCOUNT_LIST_ROUTE_PATH,
    tid: "NAVIGATION.ROUTE.NOTICE",
    path: NOTICE_ACCOUNT_LIST_ROUTE_PATH,
    icon: <NotificationsIcon />,
  },
  {
    id: 1,
    route: ACCOUNT_SETTINGS_ROUTE_PATH,
    tid: "NAVIGATION.ROUTE.SETTINGS",
    path: ACCOUNT_SETTINGS_ROUTE_PATH,
    icon: <SettingsIcon />,
  },
];

export const NAVIGATION_USER_ROUTE_LIST = [
  {
    id: 1,
    route: PURCHASE_CUSTOMER_LIST_ROUTE_PATH,
    tid: "NAVIGATION.ROUTE.CUSTOMER_PURCHASE",
    path: PURCHASE_CUSTOMER_LIST_ROUTE_PATH,
    icon: <ShoppingBasketIcon sx={{ fontSize: 28 }} />,
  },
  {
    id: 13,
    route: NOTICE_ACCOUNT_LIST_ROUTE_PATH,
    tid: "NAVIGATION.ROUTE.NOTICE",
    path: NOTICE_ACCOUNT_LIST_ROUTE_PATH,
    icon: <NotificationsIcon sx={{ fontSize: 28 }} />,
  },
  {
    id: 2,
    route: ACCOUNT_SETTINGS_ROUTE_PATH,
    tid: "NAVIGATION.ROUTE.SETTINGS",
    path: ACCOUNT_SETTINGS_ROUTE_PATH,
    icon: <SettingsIcon sx={{ fontSize: 28 }} />,
  },
];

export const NAVIGATION_EDITOR_ROUTE_LIST = [
  {
    id: 6,
    route: GDS_ADMIN_LIST_ROUTE_PATH,
    tid: "NAVIGATION.ROUTE.GDS",
    path: GDS_ADMIN_LIST_ROUTE_PATH,
    icon: <CategoryIcon />,
  },
  {
    id: 13,
    route: NOTICE_ACCOUNT_LIST_ROUTE_PATH,
    tid: "NAVIGATION.ROUTE.NOTICE",
    path: NOTICE_ACCOUNT_LIST_ROUTE_PATH,
    icon: <NotificationsIcon />,
  },
  {
    id: 2,
    route: ACCOUNT_SETTINGS_ROUTE_PATH,
    tid: "NAVIGATION.ROUTE.SETTINGS",
    path: ACCOUNT_SETTINGS_ROUTE_PATH,
    icon: <SettingsIcon />,
  },
];
