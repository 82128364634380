export const palette = {
  mode: 'dark',
  primary: {
    main: 'rgb(199, 155, 59)',
    light: 'rgb(199, 143, 59)',
    dark: 'rgb(199, 143, 59)',
    contrastText: '#121212',
  },
  secondary: {
    main: '#FF782D',
    light: '#ff9357',
    dark: '#b2541f',
    contrastText: '#121212',
  },
  classic: {
    main: '#FF782D',
    light: '#ff9357',
    dark: '#b2541f',
    contrastText: '#121212',
  },
  divider: 'rgba(255, 255, 255, 0.12)',
  primaryDark: {
    50: '#E2EDF8',
    100: '#CEE0F3',
    200: '#91B9E3',
    300: '#5090D3',
    400: '#265D97',
    500: '#1E4976',
    600: '#173A5E',
    700: '#132F4C',
    800: '#001E3C',
    900: '#252525',
    main: '#5090D3',
  },
  common: { black: '#1D1D1D', white: '#646464' },
  text: {
    primary: '#787878',
    secondary: '#646464',
    disabled: 'rgba(255, 255, 255, 0.7)',
  },
  contrastThreshold: 3,
  tonalOffset: 0.2,
  background: { paper: '#121212', default: '#F1F1F1' },
  action: {
    active: 'rgba(255, 255, 255, 0.54)',
    hover: 'rgba(255, 255, 255, 0.04)',
    hoverOpacity: 0.3,
    selected: 'rgb(199, 143, 59)',
    selectedOpacity: 0.2,
    disabled: 'rgba(255, 255, 255, 0.1)',
    disabledBackground: 'rgb(92, 91, 91)',
    disabledOpacity: 0.38,
    focus: 'rgba(255, 255, 255, 0.12)',
    focusOpacity: 0.12,
    activatedOpacity: 0.12,
  },
};
