import { convertFileFieldValue } from "../../lib/common/field-file/field-file.convert";
import { convertSelectFieldValue } from "../../lib/common/field-select/field-select.convert";
import { GDS_ADMIN_CREATE_DATA_NAME } from "./gds-admin-create.constant";

export const convertGdsAdminCreateFormData = (data) => ({
  [GDS_ADMIN_CREATE_DATA_NAME.ORDER]: Number(
    data[GDS_ADMIN_CREATE_DATA_NAME.ORDER]
  ),
  [GDS_ADMIN_CREATE_DATA_NAME.AMOUNT_STATUS]:
    data[GDS_ADMIN_CREATE_DATA_NAME.AMOUNT_STATUS],
  [GDS_ADMIN_CREATE_DATA_NAME.AMOUNT]: Number(
    data[GDS_ADMIN_CREATE_DATA_NAME.AMOUNT]
  ),

  [GDS_ADMIN_CREATE_DATA_NAME.PRICE]: Number(
    data[GDS_ADMIN_CREATE_DATA_NAME.PRICE]
  ),
  [GDS_ADMIN_CREATE_DATA_NAME.WEIGHT]: Number(
    data[GDS_ADMIN_CREATE_DATA_NAME.WEIGHT]
  ),

  [GDS_ADMIN_CREATE_DATA_NAME.TITLE]: String(
    data[GDS_ADMIN_CREATE_DATA_NAME.TITLE]
  ),

  [GDS_ADMIN_CREATE_DATA_NAME.DESCRIPTION]: String(
    data[GDS_ADMIN_CREATE_DATA_NAME.DESCRIPTION]
  ),
  [GDS_ADMIN_CREATE_DATA_NAME.BODY]: String(
    data[GDS_ADMIN_CREATE_DATA_NAME.BODY]
  ),

  [GDS_ADMIN_CREATE_DATA_NAME.URL]: String(
    data[GDS_ADMIN_CREATE_DATA_NAME.URL]
  ),

  [GDS_ADMIN_CREATE_DATA_NAME.PPO]: Number(
    data[GDS_ADMIN_CREATE_DATA_NAME.PPO]
  ),

  [GDS_ADMIN_CREATE_DATA_NAME.CATEGORY]: convertSelectFieldValue(
    data[GDS_ADMIN_CREATE_DATA_NAME.CATEGORY]
  ),
  [GDS_ADMIN_CREATE_DATA_NAME.TYPE]: convertSelectFieldValue(
    data[GDS_ADMIN_CREATE_DATA_NAME.TYPE]
  ),
  [GDS_ADMIN_CREATE_DATA_NAME.TAG]: convertSelectFieldValue(
    data[GDS_ADMIN_CREATE_DATA_NAME.TAG]
  ),

  [GDS_ADMIN_CREATE_DATA_NAME.FILE_PREVIEW]: convertFileFieldValue(
    data[GDS_ADMIN_CREATE_DATA_NAME.FILE_PREVIEW]
  )[0],
  [GDS_ADMIN_CREATE_DATA_NAME.FILE_LIST]: convertFileFieldValue(
    data[GDS_ADMIN_CREATE_DATA_NAME.FILE_LIST]
  ),
});
