import React from "react";

import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";
import styled from "styled-components";

export const FieldDatePickerComponent = ({
  errorText,
  error,
  label,
  name,
  setValue,
  onBlur,
  onChange,
  ...props
}) => {
  const handleChange = (value) => {
    onBlur && onBlur({ target: { value: value, name } });
    onChange && onChange({ target: { value: value, name } });
    setValue && setValue(name, value);
    setOpen(false);
  };

  const [isOpen, setOpen] = React.useState(false);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Grid container spacing={2}>
        <Grid item>
          <Typography variant="fieldLabel">{label}</Typography>
        </Grid>
        <Grid item onClick={() => setOpen(true)}>
          <MobileDateTimePicker
            sx={{
              "& .MuiPickersToolbarText-root.Mui-selected": {
                color: "#fff",
                backgroundColor: "#e91e63",
                fontWeight: "600",
                WebkitTransition:
                  "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                transition:
                  "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                borderRadius: "50%",
                fontSize: "0.75rem",
                width: "36px",
                height: "36px",
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "center",
              },
              "& .Mui-selected:hover": {
                backgroundColor: "#F95D44",
                color: "white",
              },
              "& .MuiDateTimePickerToolbar-separator": {
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "center",
              },
            }}
            error={error}
            renderInput={(params) => (
              <TextField
                {...params}
                inputProps={{
                  ...params.inputProps,
                  placeholder: "",
                }}
              />
            )}
            onChange={handleChange}
            {...props}
          />
        </Grid>
        {error && (
          <Grid item>
            <Typography variant="errorText">{errorText}</Typography>
          </Grid>
        )}
      </Grid>
    </LocalizationProvider>
  );
};

const MobileDateTimePickerStyled = styled(MobileDateTimePicker)``;
