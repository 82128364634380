import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";

import { TextComponent } from "../../../lib/common/text";
import { FieldDatePickerComponent } from "../../../lib/common/field-date-picker/field-date-picker.component";
import { PURCHASE_ADMIN_ITEM_TIME_CREATE_DATA_NAME } from "../purchase-list-upload.constant.js";
import styled from "styled-components";

export const PurchaseAdminItemCalendarCreateFormComponent = (props) => {
  const {
    isEmptyList,
    values,
    touched,
    isValid,
    pageLoading,
    isSuccess,
    isError,
    isPending,
    setFieldValue,
    handleUploadPurchase,
    handleUploadGds,
    validation,
  } = props;
  const errors = validation(values);

  const isFieldError = (name) => {
    return errors[name] && touched[name] && errors[name];
  };
  const getFieldError = (name) => isFieldError(name) && errors[name];
  const isSubmitDisabled = () => {
    if (isEmptyList) {
      return true;
    }

    if (
      errors[PURCHASE_ADMIN_ITEM_TIME_CREATE_DATA_NAME.FROM_TIME] ||
      errors[PURCHASE_ADMIN_ITEM_TIME_CREATE_DATA_NAME.TO_TIME]
    ) {
      return true;
    }

    if (isError) {
      return false;
    }

    if (isPending) {
      return true;
    }

    if (!isValid || isSuccess || pageLoading) {
      return true;
    }
  };

  return (
    <form>
      <Box sx={{ pt: 4 }}>
        <GridStyled spacing={3} container>
          <Grid item>
            <FieldDatePickerComponent
              label={
                <TextComponent tid="PURCHASE.ADMIN.ITEM_TIME.CREATE.FORM.FIELD.FROM_TIME" />
              }
              name={PURCHASE_ADMIN_ITEM_TIME_CREATE_DATA_NAME.FROM_TIME}
              setValue={setFieldValue}
              value={
                values[PURCHASE_ADMIN_ITEM_TIME_CREATE_DATA_NAME.FROM_TIME]
              }
              error={isFieldError(
                PURCHASE_ADMIN_ITEM_TIME_CREATE_DATA_NAME.FROM_TIME
              )}
              errorText={getFieldError(
                PURCHASE_ADMIN_ITEM_TIME_CREATE_DATA_NAME.FROM_TIME
              )}
            />
          </Grid>
          <Grid item>
            <FieldDatePickerComponent
              label={
                <TextComponent tid="PURCHASE.ADMIN.ITEM_TIME.CREATE.FORM.FIELD.TO_TIME" />
              }
              name={PURCHASE_ADMIN_ITEM_TIME_CREATE_DATA_NAME.TO_TIME}
              setValue={setFieldValue}
              value={values[PURCHASE_ADMIN_ITEM_TIME_CREATE_DATA_NAME.TO_TIME]}
              error={isFieldError(
                PURCHASE_ADMIN_ITEM_TIME_CREATE_DATA_NAME.TO_TIME
              )}
              errorText={getFieldError(
                PURCHASE_ADMIN_ITEM_TIME_CREATE_DATA_NAME.TO_TIME
              )}
            />
          </Grid>
          <Grid item>
            <Button
              disabled={isSubmitDisabled()}
              fullWidth
              onClick={() => handleUploadPurchase(values, "purchase")}
            >
              <TextComponent tid="PURCHASE.ADMIN.ITEM_TIME.CREATE.FORM.BUTTON.UPLOAD_PURCHASE" />
            </Button>
          </Grid>
          <Grid item>
            <Button
              disabled={isSubmitDisabled()}
              fullWidth
              onClick={() => handleUploadPurchase(values, "gds")}
            >
              <TextComponent tid="PURCHASE.ADMIN.ITEM_TIME.CREATE.FORM.BUTTON.UPLOAD_GDS" />
            </Button>
          </Grid>
        </GridStyled>
      </Box>
    </form>
  );
};

const GridStyled = styled(Grid)`
  display: grid !important;
  grid-template-columns: 1fr 1fr 1.5fr 1.5fr !important;
  align-items: end !important;

  @media screen and (max-width: 1071px) {
    grid-template-columns: 1fr !important;
  }
`;
