import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { AccountSettingsUpdateEmailComponent } from "./account-settings-update-email.component";
import { accountSettingsUpdateEmailFormValidation } from "./account-settings-update-email.validation";
import { convertAccountSettingsUpdateEmailFormData } from "./account-settings-update-email.convert";
import {
  uploadAccountSettingsUpdateEmailForm,
  resetAccountSettingsUpdateEmailFormState,
} from "./account-settings-update-email.action";

import {
  ACCOUNT_SETTINGS_UPDATE_EMAIL_STORE_NAME,
  ACCOUNT_SETTINGS_UPDATE_EMAIL_DATA_NAME,
} from "./account-settings-update-email.constant";

import { NAVIGATION_STORE_NAME } from "../../lib/common/navigation";
import { AUTH_STORE_NAME } from "../../lib/common/auth";

import {
  getRequestErrorMessage,
  isRequestError,
  isRequestPending,
  isRequestSuccess,
} from "../../main/store/store.service";

export function AccountSettingsUpdateEmailContainer() {
  const dispatch = useDispatch();
  const { state, pageLoading, auth } = useSelector((state) => ({
    state: state[ACCOUNT_SETTINGS_UPDATE_EMAIL_STORE_NAME],
    pageLoading: state[NAVIGATION_STORE_NAME].pageLoading,
    auth: state[AUTH_STORE_NAME],
  }));

  React.useEffect(() => {
    dispatch(resetAccountSettingsUpdateEmailFormState());
  }, []);

  const accountSettingsUpdateEmailFormSendData = (values) => {
    const data = convertAccountSettingsUpdateEmailFormData(values);
    dispatch(uploadAccountSettingsUpdateEmailForm(data));
  };

  const getInitialValue = () => {
    return {
      [ACCOUNT_SETTINGS_UPDATE_EMAIL_DATA_NAME.PASSWORD]: "",
      [ACCOUNT_SETTINGS_UPDATE_EMAIL_DATA_NAME.EMAIL]: auth.logged
        ? auth.user.email
        : null,
      [ACCOUNT_SETTINGS_UPDATE_EMAIL_DATA_NAME.NEW_EMAIL]: "",
    };
  };

  return (
    <AccountSettingsUpdateEmailComponent
      isPending={isRequestPending(state.form)}
      isError={isRequestError(state.form)}
      isSuccess={isRequestSuccess(state.form)}
      initialValue={getInitialValue()}
      validation={accountSettingsUpdateEmailFormValidation}
      onSubmitForm={accountSettingsUpdateEmailFormSendData}
      pageLoading={pageLoading}
      errorMessage={getRequestErrorMessage(state.form)}
      userEmail={auth.logged ? auth.user.email : null}
    />
  );
}
