import { text } from "../../text";

import AttachFileIcon from "@mui/icons-material/AttachFile";

import * as React from "react";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import CloseIcon from "@mui/icons-material/Close";
import Snackbar from "@mui/material/Snackbar";

const Input = styled("input")({
  display: "none",
});

export const FieldFileIconComponent = (props) => {
  const { onFileAdd, iconProps, isSuccess, valueEmpty, errorMessage } = props;

  React.useEffect(() => {
    if (errorMessage) {
      onErrorIs();
    }
  }, [errorMessage]);

  const [copied, setCopied] = React.useState(false);

  const onErrorIs = () => {
    setCopied(true);
  };

  const handleClose = (event, reason) => {
    setCopied(false);
  };

  return (
    <Stack direction="row" alignItems="center" spacing={2}>
      <label htmlFor="icon-button-file">
        <Input
          onChange={onFileAdd}
          id="icon-button-file"
          type="file"
          accept="*"
        />

        <IconButton
          {...iconProps}
          aria-label="upload picture"
          component="span"
          color={isSuccess && !valueEmpty ? "primary" : "default"}
        >
          <AttachFileIcon />
        </IconButton>
      </label>
      <Snackbar
        onClose={handleClose}
        message={text(`ERROR.${errorMessage}`)}
        open={copied}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </Stack>
  );
};
