export const PURCHASE_ADMIN_LIST_ROUTE_PATH = '/admin/purchase';

export const PURCHASE_ADMIN_LIST_STORE_NAME = 'PURCHASE_ADMIN_LIST';

export const PURCHASE_ADMIN_ITEM_TIME_CREATE_DATA_NAME = {
  FROM_TIME: "fromTime",
  TO_TIME: "toTime",
};

export const PURCHASE_ADMIN_LIST_API = {
  PURCHASE_ADMIN_LIST: {
    ENDPOINT: `/purchase/admin/xlsx`,
    TYPE: 'GET',
  },
};

export const PURCHASE_ADMIN_LIST_ACTION_TYPE = {
  REQUEST_PENDING: 'PURCHASE_ADMIN_LIST_ACTION_TYPE.REQUEST_PENDING',
  REQUEST_SUCCESS: 'PURCHASE_ADMIN_LIST_ACTION_TYPE.REQUEST_SUCCESS',
  REQUEST_ERROR: 'PURCHASE_ADMIN_LIST_ACTION_TYPE.REQUEST_ERROR',
  REQUEST_UPDATE_SUCCESS:
    'PURCHASE_ADMIN_LIST_ACTION_TYPE.REQUEST_UPDATE_SUCCESS',
  REQUEST_UPDATE_PENDING:
    'PURCHASE_ADMIN_LIST_ACTION_TYPE.REQUEST_UPDATE_PENDING',
};

export const PURCHASE_LIST_UPLOAD_DATA_NAME = {
  LIST: 'list',

  ID: 'id',
  TYPE: 'type',
  CREATE_DATE: 'createDate',
  STATUS: 'status',
  PRICE: 'price',
  LANG: 'lang',
  GDS: 'gds',
  METADATA: 'metadata',

  PAYMENT: 'payment',
  PAYMENT_ID: 'id',
  CONTACTS: 'contacts',
  REGION: 'region',
  CITY: 'city',
  POST_OFFICE: 'postOffice',
  FULL_NAME: 'fullName',
  PHONE: 'phone',
  TRACK_NUMBER: 'trackNumber',
  GDS_ORDER: 'gdsOrder',
  WEIGHT: 'weight',

  SKIP: 'skip',
  TAKE: 'take',
  PAID: 'paid',

  PRICE: 'price',

  IS_CUSTOMER: 'isCustomer',
  CUSTOMER: 'customer',
  CUSTOMER_ID: 'id',
  CUSTOMER_EMAIL: 'email',
};
