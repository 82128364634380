import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";

import Alert from "@mui/material/Alert";

import { Formik } from "formik";

import { LoaderBarComponent } from "../../lib/common/loader-bar";
import { TextComponent } from "../../lib/common/text";

import { PurchaseCustomerItemUpdateDataFormComponent } from "./frame/purchase-customer-item-update-data-form.component";
import { PURCHASE_STATUS_TYPE } from "../../lib/common/purchase/purchase.type";

export function PurchaseCustomerItemUpdateDataComponent(props) {
  const {
    initialValue,
    pageLoading,
    isPending,
    validation,
    onSubmitForm,
    isError,
    isSuccess,
    errorMessage,
    data,
    isDataSuccess,
  } = props;

  console.log("initialValue", initialValue);

  const status = data?.status;
  const isTrackNumber = !!data?.trackNumber;

  const isErrorStatus =
    isTrackNumber || status?.id !== PURCHASE_STATUS_TYPE.NEW;

  return (
    <Box>
      <Paper sx={{ p: 6 }}>
        <Box>
          <Box sx={{ pb: 4 }}>
            <TextComponent
              variant="title"
              component="div"
              tid="PURCHASE.ADMIN.UPDATE.DATA.TITLE"
            />
          </Box>
          <Divider />
          <Formik
            enableReinitialize
            initialValues={initialValue}
            validate={validation}
            onSubmit={onSubmitForm}
          >
            {(props) => (
              <PurchaseCustomerItemUpdateDataFormComponent
                {...props}
                isErrorStatus={isErrorStatus}
                isPending={isPending}
                isError={isError}
                errorMessage={errorMessage}
                pageLoading={pageLoading}
              />
            )}
          </Formik>
          {isErrorStatus && (
            <Box sx={{ pt: 4 }}>
              <Alert severity="error">
                <TextComponent tid="ERROR.STATUS" />
              </Alert>
            </Box>
          )}
          {isError && (
            <Box sx={{ pt: 4 }}>
              <Alert severity="error">
                <TextComponent tid={`ERROR.${errorMessage}`} />
              </Alert>
            </Box>
          )}
          {isSuccess && (
            <Box sx={{ pt: 4 }}>
              <Alert severity="success">
                <TextComponent tid="PURCHASE.ADMIN.UPDATE.DATA.SUCCESS_MESSAGE" />
              </Alert>
            </Box>
          )}
          {isPending && <LoaderBarComponent />}
        </Box>
      </Paper>
    </Box>
  );
}
