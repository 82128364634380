import { PURCHASE_CUSTOMER_ITEM_DATA_NAME } from "./purchase-customer-item.constant";

import {
  convertPurchaseType,
  convertPurchaseStatus,
} from "../../lib/common/purchase/purchase.convert";

import { convertTimezone } from "../../lib/common/user/user.convert";
import { convertDatetimePm } from "../../lib/common/convert/convert.core";
import { convertPaymentSystemType } from "../../lib/common/payment/payment.convert";

export const convertPurchaseCustomerItem = (data) => {
  const purchaseType = convertPurchaseType(
    data[PURCHASE_CUSTOMER_ITEM_DATA_NAME.TYPE]
  );

  const paid = !!data[PURCHASE_CUSTOMER_ITEM_DATA_NAME.PAYMENT];

  const paymentData = data[PURCHASE_CUSTOMER_ITEM_DATA_NAME.PAYMENT];

  const payment = paid
    ? {
        [PURCHASE_CUSTOMER_ITEM_DATA_NAME.PAYMENT_ID]:
          paymentData[PURCHASE_CUSTOMER_ITEM_DATA_NAME.PAYMENT_ID],
        [PURCHASE_CUSTOMER_ITEM_DATA_NAME.CONFIRM]:
          paymentData[PURCHASE_CUSTOMER_ITEM_DATA_NAME.CONFIRM],
        [PURCHASE_CUSTOMER_ITEM_DATA_NAME.SYSTEM]: convertPaymentSystemType(
          paymentData[PURCHASE_CUSTOMER_ITEM_DATA_NAME.SYSTEM]
        ),
      }
    : null;

  return {
    [PURCHASE_CUSTOMER_ITEM_DATA_NAME.ID]:
      data[PURCHASE_CUSTOMER_ITEM_DATA_NAME.ID],
    [PURCHASE_CUSTOMER_ITEM_DATA_NAME.TIMEZONE]: convertTimezone(
      data[PURCHASE_CUSTOMER_ITEM_DATA_NAME.TIMEZONE]
    ),
    [PURCHASE_CUSTOMER_ITEM_DATA_NAME.METADATA]:
      data[PURCHASE_CUSTOMER_ITEM_DATA_NAME.METADATA],

    [PURCHASE_CUSTOMER_ITEM_DATA_NAME.NOTES]:
      data[PURCHASE_CUSTOMER_ITEM_DATA_NAME.NOTES],

    [PURCHASE_CUSTOMER_ITEM_DATA_NAME.COMMENTS]:
      data[PURCHASE_CUSTOMER_ITEM_DATA_NAME.COMMENTS],

    [PURCHASE_CUSTOMER_ITEM_DATA_NAME.PRICE]:
      data[PURCHASE_CUSTOMER_ITEM_DATA_NAME.PRICE],

    [PURCHASE_CUSTOMER_ITEM_DATA_NAME.WEIGHT]:
      data[PURCHASE_CUSTOMER_ITEM_DATA_NAME.WEIGHT],

    [PURCHASE_CUSTOMER_ITEM_DATA_NAME.TRACK_NUMBER]:
      data[PURCHASE_CUSTOMER_ITEM_DATA_NAME.TRACK_NUMBER],

    [PURCHASE_CUSTOMER_ITEM_DATA_NAME.STATUS]: convertPurchaseStatus(
      data[PURCHASE_CUSTOMER_ITEM_DATA_NAME.STATUS]
    ),

    [PURCHASE_CUSTOMER_ITEM_DATA_NAME.TYPE]: purchaseType,

    [PURCHASE_CUSTOMER_ITEM_DATA_NAME.CREATE_DATE]: convertDatetimePm(
      data[PURCHASE_CUSTOMER_ITEM_DATA_NAME.CREATE_DATE]
    ),

    [PURCHASE_CUSTOMER_ITEM_DATA_NAME.PAID]: paid,
    [PURCHASE_CUSTOMER_ITEM_DATA_NAME.PAYMENT]: payment,

    [PURCHASE_CUSTOMER_ITEM_DATA_NAME.CART]:
      data[PURCHASE_CUSTOMER_ITEM_DATA_NAME.CART],

    [PURCHASE_CUSTOMER_ITEM_DATA_NAME.CUSTOMER]:
      data[PURCHASE_CUSTOMER_ITEM_DATA_NAME.CUSTOMER],

    [PURCHASE_CUSTOMER_ITEM_DATA_NAME.INVOICE_LINK]:
      data[PURCHASE_CUSTOMER_ITEM_DATA_NAME.INVOICE_LINK],
  };
};
