import React from "react";
import styled from "styled-components";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Grid from "@mui/material/Grid";

import { TextComponent } from "../../../lib/common/text/text.component";

import { PURCHASE_CUSTOMER_ITEM_ROUTE_PATH_DYNAMIC } from "../../purchase-customer-item/purchase-customer-item.constant";
import { PURCHASE_STATUS_VIEW_VALUE } from "../../../lib/common/purchase/purchase.type";
import {
  GDS_CATEGORY_VIEW_VALUE,
  GDS_SIZE_VIEW_VALUE,
} from "../../../lib/common/gds/gds.type";
import { useNavigate } from "react-router-dom";
import { Link } from "@mui/material";

export function PurchaseCustomerListViewComponent(props) {
  const { data } = props;

  if (data.list.length === 0) {
    return (
      <Box sx={{ px: 6, pt: 4 }}>
        <TextComponent
          variant="body1"
          tid="PURCHASE.CUSTOMER.LIST.LIST_IS_EMPTY"
        />
      </Box>
    );
  }

  const navigate = useNavigate();

  const handleClickItem = (purchaseId) => (e) => {
    e.preventDefault();
    return navigate(PURCHASE_CUSTOMER_ITEM_ROUTE_PATH_DYNAMIC({ purchaseId }));
  };

  return (
    <List sx={{ pt: 4 }}>
      {data.list.map((item, index) => (
        <>
          <ListItem
            alignItems="flex-start"
            onClick={handleClickItem(item.id)}
            sx={{
              px: 6,
              flexDirection: "column",
            }}
            variant="list"
            button
            component="a"
            href={PURCHASE_CUSTOMER_ITEM_ROUTE_PATH_DYNAMIC({
              purchaseId: item.id,
            })}
          >
            <Container>
              <GridStyled>
                <TextComponent
                  tid="PURCHASE.CUSTOMER.LIST.TRACK_NUMBER"
                  variant="body1"
                  component="div"
                  tvalue={{
                    number: item.trackNumber ? item.trackNumber : "не вказаний",
                  }}
                />
                <TextComponent
                  tid="PURCHASE.CUSTOMER.LIST.CREATE_DATE"
                  variant="body1"
                  component="div"
                  tvalue={{ date: item.createDate }}
                />
                <TextComponent
                  tid="PURCHASE.CUSTOMER.LIST.STATUS"
                  variant="body1"
                  component="div"
                  tvalue={{
                    status: PURCHASE_STATUS_VIEW_VALUE[item.status],
                  }}
                />
                {item.paid ? (
                  <React.Fragment>
                    <Grid item>
                      <TextComponent
                        tid="PURCHASE.ADMIN.LIST.PAYMENT_SYSTEM"
                        variant="body1"
                        sx={{ color: "green" }}
                        tvalue={{ system: item?.payment?.system?.text }}
                      />
                    </Grid>
                    <Grid item>
                      {item.payment.confirm ? (
                        <TextComponent
                          tid="PURCHASE.ADMIN.LIST.PAYMENT_CONFIRM_TRUE"
                          variant="body1"
                          sx={{ color: "green" }}
                        />
                      ) : (
                        <TextComponent
                          tid="PURCHASE.ADMIN.LIST.PAYMENT_CONFIRM_FALSE"
                          variant="body1"
                          sx={{ color: "red" }}
                        />
                      )}
                    </Grid>
                  </React.Fragment>
                ) : (
                  <Grid item>
                    <TextComponent
                      tid="PURCHASE.ADMIN.LIST.PAID_FALSE"
                      variant="body1"
                      sx={{ color: "red" }}
                    />
                  </Grid>
                )}
                {item.invoiceLink && (
                  <Grid item>
                    <Link
                      sx={{
                        color: "#ededed",
                        textDecorationColor: "#ededed !important",
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      href={item.invoiceLink}
                    >
                      <TextComponent
                        tid="PURCHASE.CUSTOMER.LIST.INVOICE_URL"
                        variant="dataViewContent"
                      />
                    </Link>
                  </Grid>
                )}
              </GridStyled>
              <Grid width="100%">
                <TextComponent
                  tid="PURCHASE.CUSTOMER.LIST.REGION"
                  variant="body1"
                  component="div"
                  tvalue={{
                    region: item?.customer?.region,
                    city: item?.customer?.city,
                  }}
                />
                <TextComponent
                  tid="PURCHASE.CUSTOMER.LIST.POST"
                  variant="body1"
                  component="div"
                  tvalue={{ post: item.customer?.postOffice }}
                />
              </Grid>
              <Grid item>
                <Grid
                  sx={{ marginBottom: `${item?.cart?.gdsOrders && "12px"}` }}
                >
                  {item?.cart?.gdsOrders &&
                    item.cart.gdsOrders.map((e) => {
                      const size = e.gdsItem?.size;
                      const sizeViewValue =
                        size !== undefined
                          ? GDS_SIZE_VIEW_VALUE[size]
                          : "no size";

                      return (
                        <TextComponent
                          key={`${e?.gds?.title}-${sizeViewValue}-${e.amount}`}
                          tid={`${e?.gds?.title}-${sizeViewValue}-${e.amount}`}
                          variant="body1"
                        />
                      );
                    })}
                </Grid>
                <TextComponent
                  tid="PURCHASE.CUSTOMER.LIST.TOTAL_PRICE"
                  variant="body1"
                  component="div"
                  tvalue={{ price: item.price }}
                />
                <TextComponent
                  tid="PURCHASE.CUSTOMER.LIST.TOTAL_WEIGHT"
                  variant="body1"
                  component="div"
                  tvalue={{ weight: item.weight }}
                />
              </Grid>
            </Container>
          </ListItem>

          {index !== data?.list.length - 1 && (
            <DividerContainer>
              <Divider />
            </DividerContainer>
          )}
        </>
      ))}
    </List>
  );
}

const Container = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 12px;

  @media (max-width: 900px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`;
const Divider = styled.div`
  height: 2px;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.12);
`;

const DividerContainer = styled.div`
  padding: 16px 24px;
`;

const GridStyled = styled(Grid)`
  :not(:last-child) {
    margin-right: 50px;
  }
`;
