import { GDS_ADMIN_ITEM_DATA_DATA_NAME } from "./gds-admin-item-data.constant";

import {
  convertGdsStatus,
  convertGdsTagList,
  convertGdsType,
  convertGdsCategory,
  convertGdsSizeList,
} from "../../lib/common/gds/gds.convert";

import { convertDatetime } from "../../lib/common/convert/convert.core";
import {
  convertFile,
  convertFileList,
} from "../../lib/common/file/file.convert";

export const convertGdsAdminItemData = (data) => ({
  [GDS_ADMIN_ITEM_DATA_DATA_NAME.CATEGORY]: convertGdsCategory(
    data[GDS_ADMIN_ITEM_DATA_DATA_NAME.CATEGORY]
  ),
  [GDS_ADMIN_ITEM_DATA_DATA_NAME.CREATE_DATE]: convertDatetime(
    data[GDS_ADMIN_ITEM_DATA_DATA_NAME.CREATE_DATE]
  ),
  [GDS_ADMIN_ITEM_DATA_DATA_NAME.DESCRIPTION]:
    data[GDS_ADMIN_ITEM_DATA_DATA_NAME.DESCRIPTION],
  [GDS_ADMIN_ITEM_DATA_DATA_NAME.BODY]:
    data[GDS_ADMIN_ITEM_DATA_DATA_NAME.BODY],

  [GDS_ADMIN_ITEM_DATA_DATA_NAME.FILE_PREVIEW]: convertFile(
    data[GDS_ADMIN_ITEM_DATA_DATA_NAME.FILE_PREVIEW]
  ),
  [GDS_ADMIN_ITEM_DATA_DATA_NAME.FILE_LIST]: convertFileList(
    data[GDS_ADMIN_ITEM_DATA_DATA_NAME.FILE_LIST]
  ),

  [GDS_ADMIN_ITEM_DATA_DATA_NAME.ID]: data[GDS_ADMIN_ITEM_DATA_DATA_NAME.ID],
  [GDS_ADMIN_ITEM_DATA_DATA_NAME.ORDER]:
    data[GDS_ADMIN_ITEM_DATA_DATA_NAME.ORDER],
  [GDS_ADMIN_ITEM_DATA_DATA_NAME.STATUS]: convertGdsStatus(
    data[GDS_ADMIN_ITEM_DATA_DATA_NAME.STATUS]
  ),
  [GDS_ADMIN_ITEM_DATA_DATA_NAME.AMOUNT]:
    data[GDS_ADMIN_ITEM_DATA_DATA_NAME.AMOUNT],
  [GDS_ADMIN_ITEM_DATA_DATA_NAME.WEIGHT]:
    data[GDS_ADMIN_ITEM_DATA_DATA_NAME.WEIGHT],
  [GDS_ADMIN_ITEM_DATA_DATA_NAME.PPO]: data[GDS_ADMIN_ITEM_DATA_DATA_NAME.PPO],
  [GDS_ADMIN_ITEM_DATA_DATA_NAME.PRICE]:
    data[GDS_ADMIN_ITEM_DATA_DATA_NAME.PRICE],
  [GDS_ADMIN_ITEM_DATA_DATA_NAME.TAG]: convertGdsTagList(
    data[GDS_ADMIN_ITEM_DATA_DATA_NAME.TAG]
  ),

  [GDS_ADMIN_ITEM_DATA_DATA_NAME.TITLE]:
    data[GDS_ADMIN_ITEM_DATA_DATA_NAME.TITLE],
  [GDS_ADMIN_ITEM_DATA_DATA_NAME.TYPE]: convertGdsType(
    data[GDS_ADMIN_ITEM_DATA_DATA_NAME.TYPE]
  ),
  [GDS_ADMIN_ITEM_DATA_DATA_NAME.UPDATE_DATE]: convertDatetime(
    data[GDS_ADMIN_ITEM_DATA_DATA_NAME.UPDATE_DATE]
  ),
  [GDS_ADMIN_ITEM_DATA_DATA_NAME.URL]: data[GDS_ADMIN_ITEM_DATA_DATA_NAME.URL],
});
