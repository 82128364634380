import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { AccountSettingsUpdateUserDataComponent } from "./account-settings-update-user-data.component";
import { accountSettingsUpdateUserDataFormValidation } from "./account-settings-update-user-data.validation";
import { convertAccountSettingsUpdateUserDataFormData } from "./account-settings-update-user-data.convert";
import {
  uploadAccountSettingsUpdateUserDataForm,
  resetAccountSettingsUpdateUserDataFormState,
  getUserAdminItemData,
} from "./account-settings-update-user-data.action";
import {
  ACCOUNT_SETTINGS_UPDATE_USER_DATA_STORE_NAME,
  ACCOUNT_SETTINGS_UPDATE_USER_DATA_NAME,
  REGION_OPTION,
} from "./account-settings-update-user-data.constant";

import { NAVIGATION_STORE_NAME } from "../../lib/common/navigation";

import {
  getRequestData,
  getRequestErrorMessage,
  isRequestError,
  isRequestPending,
  isRequestSuccess,
} from "../../main/store/store.service";
import { AUTH_STORE_NAME } from "../../lib/common/auth";

export function AccountSettingsUpdateUserDataContainer() {
  const dispatch = useDispatch();
  const { state, pageLoading, auth, userData } = useSelector((state) => ({
    state: state[ACCOUNT_SETTINGS_UPDATE_USER_DATA_STORE_NAME],
    pageLoading: state[NAVIGATION_STORE_NAME].pageLoading,
    auth: state[AUTH_STORE_NAME],
    userData: state["USER_ADMIN_ITEM_DATA"],
  }));

  React.useEffect(() => {
    const userId = auth.logged ? auth.user.id : null;

    dispatch(getUserAdminItemData({ userId: userId }));
    dispatch(resetAccountSettingsUpdateUserDataFormState());
  }, []);

  const accountSettingsUpdateEmailFormSendData = (values) => {
    const data = convertAccountSettingsUpdateUserDataFormData(values);
    dispatch(uploadAccountSettingsUpdateUserDataForm(data));
  };

  const getInitialValue = () => {
    const data = getRequestData(userData.request);

    console.log("userDAta", data);

    return {
      [ACCOUNT_SETTINGS_UPDATE_USER_DATA_NAME.FULL_NAME]:
        data[ACCOUNT_SETTINGS_UPDATE_USER_DATA_NAME.FULL_NAME] || "",
      [ACCOUNT_SETTINGS_UPDATE_USER_DATA_NAME.PHONE]:
        data[ACCOUNT_SETTINGS_UPDATE_USER_DATA_NAME.PHONE] || "",
      [ACCOUNT_SETTINGS_UPDATE_USER_DATA_NAME.POST_OFFICE]:
        data[ACCOUNT_SETTINGS_UPDATE_USER_DATA_NAME.POST_OFFICE] || "",
      [ACCOUNT_SETTINGS_UPDATE_USER_DATA_NAME.CITY]:
        data[ACCOUNT_SETTINGS_UPDATE_USER_DATA_NAME.CITY] || "",
      [ACCOUNT_SETTINGS_UPDATE_USER_DATA_NAME.REGION]:
        REGION_OPTION.find(
          (e) => e.value === data[ACCOUNT_SETTINGS_UPDATE_USER_DATA_NAME.REGION]
        ) || "",
    };
  };

  return (
    <AccountSettingsUpdateUserDataComponent
      isPending={isRequestPending(state.form)}
      isError={isRequestError(state.form)}
      isSuccess={isRequestSuccess(state.form)}
      initialValue={getInitialValue()}
      validation={accountSettingsUpdateUserDataFormValidation}
      onSubmitForm={accountSettingsUpdateEmailFormSendData}
      pageLoading={pageLoading}
      errorMessage={getRequestErrorMessage(state.form)}
    />
  );
}
