import React from "react";

import { useDispatch } from "react-redux";

import { httpRequest } from "../../../main/http";
import { FIELD_FILE_API } from "./field-file.constant";

import { FieldFileComponent } from "./field-file.component";

export function FieldFileContainer(props) {
  const {
    errorText,
    error,
    label,
    onChange,
    onFileAdd,
    name,
    onBlur,
    value = [],
    oneFile,

    icon,
    iconProps,
  } = props;

  const updateFileListData = async (data) => {
    setRequestPending(true);
    setRequestSuccess(false);
    setRequestError(false);
    setRequestErrorMessage(null);

    try {
      const res = await httpRequest({
        method: FIELD_FILE_API.FILE_CREATE.TYPE,
        url: FIELD_FILE_API.FILE_CREATE.ENDPOINT,
        data,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      setRequestPending(false);
      setRequestSuccess(true);
      return res.data;
    } catch (error) {
      if (error.response) {
        setRequestError(true);
        setRequestPending(false);
        setRequestErrorMessage(error.response.data.message);
      }

      return null;
    }
  };

  const handleDelete = (index) => {
    const data = [...getData];

    data.splice(index, 1);

    handleSetData(data);
  };

  const handleOnFileAdd = async (e) => {
    let file = e.target.files[0];

    e.preventDefault();

    let formData = new FormData();
    formData.append("file", file);
    const resData = await updateFileListData(formData);

    if (!resData) return null;

    const data = oneFile ? [resData] : [...getData, resData];

    handleSetData(data);
  };

  const handleSetData = (data) => {
    onFileAdd && onFileAdd(data);
    onBlur && onBlur({ target: { value: data, name } });
    onChange && onChange({ target: { value: data, name } });
    setData([...data]);
  };

  const [isRequestPending, setRequestPending] = React.useState(null);
  const [isRequestError, setRequestError] = React.useState(null);
  const [isRequestSuccess, setRequestSuccess] = React.useState(null);
  const [getRequestErrorMessage, setRequestErrorMessage] = React.useState(null);

  const [getData, setData] = React.useState([]);

  React.useEffect(() => {
    const stateValue = Array.isArray(value) ? value : [value];

    setData(stateValue);
  }, [value.length]);

  return (
    <FieldFileComponent
      isPending={isRequestPending}
      isError={isRequestError}
      label={label}
      isSuccess={isRequestSuccess}
      onFileAdd={handleOnFileAdd}
      data={getData}
      errorText={errorText}
      error={error}
      valueEmpty={value.length === 0}
      errorMessage={getRequestErrorMessage}
      handleDelete={handleDelete}
      icon={icon}
      iconProps={iconProps}
    />
  );
}
