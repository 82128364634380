import axios from "axios";

import { BASE_URL } from "./http.constant";
import { HTTP_ERROR_ROUTER } from "./index";
import { authLogout } from "src/lib/common/auth";
import { initStore } from "../store/store.core";

// const isDev = process.env.NODE_ENV !== "production";

export const httpRequest = axios.create({
  baseURL: BASE_URL,
  timeout: 100000,
});

const onResponseSuccess = (response) => {
  return response;
};

const onResponseError = async (error) => {
  const dist = JSON.stringify({
    client: error,
    server: error.response,
  });

  const queryParams = `?stack=${error?.stack}&message=${error?.response?.data?.message}&dist=${dist}`;

  if (!error.response) {
    // if (isDev) {
    //   throw error;
    // }
    // return redirect(HTTP_ERROR_ROUTER.INTERNAL_SERVER_ERROR, {
    //   query: { stack: error.stack, dist },
    // });
  }

  if (error.response) {
    // if (isDev) {
    //   throw error;
    // }

    if (error.response.status === 401) {
      const store = initStore();
      store.dispatch(authLogout());

      return window.location.replace(
        `${HTTP_ERROR_ROUTER.UNAUTHORIZED_ERROR}${queryParams}`
      );
    }
    if (error.response.status === 403) {
      return window.location.replace(
        `${HTTP_ERROR_ROUTER.ACCESS_DENIED}${queryParams}`
      );
    }
    if (error.response.status === 500) {
      return window.location.replace(
        `${HTTP_ERROR_ROUTER.SERVER_ERROR}${queryParams}`
      );
    }
    if (error.response.status === 404) {
      return window.location.replace(
        `${HTTP_ERROR_ROUTER.NOT_FOUND}${queryParams}`
      );
    }

    return Promise.reject(error);
  }
};

httpRequest.interceptors.response.use(onResponseSuccess, onResponseError);
