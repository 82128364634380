export const USER_ROLE_TYPE = {
  BLOCKED: 0,
  USER: 1,
  ADMIN: 2,
  EDITOR: 4,
};

export const USER_ROLE_VALUE = {
  0: 'BLOCKED',
  1: 'USER',
  2: 'ADMIN',
  3: 'EDITOR',
};

export const USER_TIMEZONE_OPTION = [
  { id: 14, value: 14, tid: 'USER.DATA.TIMEZONE.14' },
  { id: 13, value: 13, tid: 'USER.DATA.TIMEZONE.13' },
  { id: 12.75, value: 12.75, tid: 'USER.DATA.TIMEZONE.1275' },
  { id: 12, value: 12, tid: 'USER.DATA.TIMEZONE.12' },
  { id: 11, value: 11, tid: 'USER.DATA.TIMEZONE.11' },
  { id: 10.5, value: 10.5, tid: 'USER.DATA.TIMEZONE.105' },
  { id: 10, value: 10, tid: 'USER.DATA.TIMEZONE.10' },
  { id: 9.5, value: 9.5, tid: 'USER.DATA.TIMEZONE.95' },
  { id: 9, value: 9, tid: 'USER.DATA.TIMEZONE.9' },
  { id: 8.75, value: 8.75, tid: 'USER.DATA.TIMEZONE.875' },
  { id: 8, value: 8, tid: 'USER.DATA.TIMEZONE.8' },
  { id: 7, value: 7, tid: 'USER.DATA.TIMEZONE.7' },
  { id: 6.5, value: 6.5, tid: 'USER.DATA.TIMEZONE.65' },
  { id: 6, value: 6, tid: 'USER.DATA.TIMEZONE.6' },
  { id: 5.75, value: 5.75, tid: 'USER.DATA.TIMEZONE.575' },
  { id: 5.5, value: 5.5, tid: 'USER.DATA.TIMEZONE.55' },
  { id: 5, value: 5, tid: 'USER.DATA.TIMEZONE.5' },
  { id: 4.5, value: 4.5, tid: 'USER.DATA.TIMEZONE.45' },
  { id: 4, value: 4, tid: 'USER.DATA.TIMEZONE.4' },
  { id: 3, value: 3, tid: 'USER.DATA.TIMEZONE.3' },
  { id: 2, value: 2, tid: 'USER.DATA.TIMEZONE.2' },
  { id: 1, value: 1, tid: 'USER.DATA.TIMEZONE.1' },
  { id: 0, value: 0, tid: 'USER.DATA.TIMEZONE.0' },
  { id: -1, value: -1, tid: 'USER.DATA.TIMEZONE.-1' },
  { id: -2, value: -2, tid: 'USER.DATA.TIMEZONE.-2' },
  { id: -2.5, value: -2.5, tid: 'USER.DATA.TIMEZONE.-25' },
  { id: -3, value: -3, tid: 'USER.DATA.TIMEZONE.-3' },
  { id: -4, value: -4, tid: 'USER.DATA.TIMEZONE.-4' },
  { id: -5, value: -5, tid: 'USER.DATA.TIMEZONE.-5' },
  { id: -6, value: -6, tid: 'USER.DATA.TIMEZONE.-6' },
  { id: -7, value: -7, tid: 'USER.DATA.TIMEZONE.-7' },
  { id: -8, value: -8, tid: 'USER.DATA.TIMEZONE.-8' },
  { id: -9, value: -9, tid: 'USER.DATA.TIMEZONE.-9' },
  { id: -9.5, value: -9.5, tid: 'USER.DATA.TIMEZONE.-95' },
  { id: -11, value: -11, tid: 'USER.DATA.TIMEZONE.-10' },
  { id: -11, value: -11, tid: 'USER.DATA.TIMEZONE.-11' },
];

export const USER_ROLE_OPTION = [
  { id: 1, value: 1, tid: 'USER.DATA.ROLE.USER' },
  { id: 4, value: 4, tid: 'USER.DATA.ROLE.EDITOR' },
  { id: 5, value: 5, tid: 'USER.DATA.ROLE.BLOCKED' },
  { id: 2, value: 2, tid: 'USER.DATA.ROLE.ADMIN' },
];
