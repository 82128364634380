import React from "react";

import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Pagination from "@mui/material/Pagination";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Switch from "@mui/material/Switch";

import { TextComponent } from "../../lib/common/text";
import {
  GDS_ITEM_STATUS_OPTION,
  GDS_ITEM_SORT_TYPE,
} from "../../lib/common/gds-item/gds-item.type";

import { FieldSelectComponent } from "../../lib/common/field-select/field-select.component";
import { GDS_ITEM_ADMIN_LIST_DATA_NAME } from "../gds-item-admin-list/gds-item-admin-list.constant";

export function GdsItemAdminListPaginationComponent(props) {
  const { onChangeFilter, data } = props;

  const [openFilter, setOpenFilter] = React.useState();

  const handleOpenFilter = () => {
    if (openFilter === true) {
      onChangeFilter({
        [GDS_ITEM_ADMIN_LIST_DATA_NAME.SORT_ID]: false,
        [GDS_ITEM_ADMIN_LIST_DATA_NAME.SORT_ORDER]: false,
        [GDS_ITEM_ADMIN_LIST_DATA_NAME.STATUS]: null,
      });
    }

    setOpenFilter(!openFilter);
  };

  const handleChangeFilterSelect = (key) => (e) => {
    onChangeFilter({ [key]: e.target.value.id });
  };

  const handleChangeSortType = (e) => {
    const v = e.target.value.value;

    if (v === GDS_ITEM_SORT_TYPE.ID) {
      onChangeFilter({
        [GDS_ITEM_ADMIN_LIST_DATA_NAME.SORT_ID]: true,
        [GDS_ITEM_ADMIN_LIST_DATA_NAME.SORT_ORDER]: false,
      });
    }

    if (v === GDS_ITEM_SORT_TYPE.ORDER) {
      onChangeFilter({
        [GDS_ITEM_ADMIN_LIST_DATA_NAME.SORT_ID]: false,
        [GDS_ITEM_ADMIN_LIST_DATA_NAME.SORT_ORDER]: true,
      });
    }
  };

  const handleChangePagination = (e, value) => {
    onChangeFilter({
      [GDS_ITEM_ADMIN_LIST_DATA_NAME.SKIP]: data.take * value - data.take,
    });
  };

  const handleChangeBoughtFilter = (e, value) => {
    onChangeFilter({
      [GDS_ITEM_ADMIN_LIST_DATA_NAME.BOUGHT]: value,
    });
  };

  const handleChangeOrderFilter = (e, value) => {
    onChangeFilter({
      [GDS_ITEM_ADMIN_LIST_DATA_NAME.SORT_ORDER]: value,
    });
  };

  const paginationCount = Math.ceil(data.amount / data.take);
  const paginationPage = Math.ceil(data.skip / data.take + 1);

  return (
    <Box>
      <Paper sx={{ p: 6 }}>
        <Grid container spacing={4}>
          <Grid item>
            <Paper>
              <FormGroup>
                <FormControlLabel
                  sx={{ py: 1, px: 2, m: 0 }}
                  control={<Switch />}
                  onChange={handleOpenFilter}
                  label={<TextComponent tid="GDS.ADMIN.PAGINATION.FILTER" />}
                />
              </FormGroup>
            </Paper>
          </Grid>
          {openFilter && (
            <React.Fragment>
              <Grid item>
                <FieldSelectComponent
                  onChange={handleChangeFilterSelect(
                    GDS_ITEM_ADMIN_LIST_DATA_NAME.STATUS
                  )}
                  option={GDS_ITEM_STATUS_OPTION}
                  label={
                    <TextComponent tid="GDS_ITEM.ADMIN.PAGINATION.STATUS" />
                  }
                />
              </Grid>
            </React.Fragment>
          )}

          <Grid item>
            <Pagination
              count={paginationCount}
              page={paginationPage}
              onChange={handleChangePagination}
              size="large"
              variant="outlined"
              shape="rounded"
            />
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
}
