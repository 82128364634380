import React from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  PURCHASE_ADMIN_ITEM_UPDATE_DATA_DATA_NAME,
  PURCHASE_ADMIN_ITEM_DELETE_STORE_NAME,
  PURCHASE_ADMIN_ITEM_DELETE_ACTION_TYPE,
} from "./purchase-admin-item-delete.constant";
import {
  PURCHASE_ADMIN_ITEM_DATA_NAME,
  PURCHASE_ADMIN_ITEM_STORE_NAME,
} from "../purchase-admin-item/purchase-admin-item.constant";
import { NAVIGATION_STORE_NAME } from "../../lib/common/navigation";
import { convertPurchaseAdminItemUpdateDataFormData } from "./purchase-admin-item-delete.convert";
import { uploadPurchaseAdminItemUpdateDataForm } from "./purchase-admin-item-delete.action";
import {
  getRequestData,
  getRequestErrorMessage,
  isRequestError,
  isRequestPending,
  isRequestSuccess,
  isRequestUpdateSuccess,
} from "../../main/store/store.service";
import { purchaseAdminItemUpdateDataFormValidation } from "../purchase-admin-item-update-data/purchase-admin-item-update-data.validation";
import { PurchaseAdminItemDeleteFormComponent } from "./purchase-admin-item-delete.component";

export function PurchaseAdminItemDeleteContainer() {
  const dispatch = useDispatch();
  let { purchaseId } = useParams();

  const { state, pageLoading, purchaseState } = useSelector((state) => ({
    state: state[PURCHASE_ADMIN_ITEM_DELETE_STORE_NAME],
    purchaseState: state[PURCHASE_ADMIN_ITEM_STORE_NAME],
    pageLoading: state[NAVIGATION_STORE_NAME].pageLoading,
  }));

  const purchaseAdminItemDeleteStatusSendData = (values) => {
    const data = convertPurchaseAdminItemUpdateDataFormData(values);

    data.purchaseId = purchaseId;

    dispatch(uploadPurchaseAdminItemUpdateDataForm(data));
  };

  React.useEffect(() => {
    dispatch({
      type: PURCHASE_ADMIN_ITEM_DELETE_ACTION_TYPE.FORM_RESET,
    });
  }, []);

  const getInitialValue = () => {
    if (isRequestSuccess(purchaseState.request)) {
      const data = getRequestData(purchaseState.request);

      return {
        [PURCHASE_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.COMMENTS]:
          data[PURCHASE_ADMIN_ITEM_DATA_NAME.COMMENTS] || "",
        [PURCHASE_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.NOTES]:
          data[PURCHASE_ADMIN_ITEM_DATA_NAME.NOTES] || "",
        [PURCHASE_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.STATUS]:
          data[PURCHASE_ADMIN_ITEM_DATA_NAME.STATUS],
        [PURCHASE_ADMIN_ITEM_DATA_NAME.TRACK_NUMBER]:
          data[PURCHASE_ADMIN_ITEM_DATA_NAME.TRACK_NUMBER],
        [PURCHASE_ADMIN_ITEM_DATA_NAME.PHONE]:
          data.customer[PURCHASE_ADMIN_ITEM_DATA_NAME.PHONE] || "",
        [PURCHASE_ADMIN_ITEM_DATA_NAME.CITY]:
          data.customer[PURCHASE_ADMIN_ITEM_DATA_NAME.CITY] || "",
        [PURCHASE_ADMIN_ITEM_DATA_NAME.REGION]:
          data.customer[PURCHASE_ADMIN_ITEM_DATA_NAME.REGION] || "",
        [PURCHASE_ADMIN_ITEM_DATA_NAME.POST_OFFICE]:
          data.customer[PURCHASE_ADMIN_ITEM_DATA_NAME.POST_OFFICE] || "",
        [PURCHASE_ADMIN_ITEM_DATA_NAME.FULL_NAME]:
          data.customer[PURCHASE_ADMIN_ITEM_DATA_NAME.FULL_NAME] || "",
      };
    }

    return {};
  };

  return (
    <PurchaseAdminItemDeleteFormComponent
      isPending={isRequestPending(state?.form)}
      isError={isRequestError(state?.form)}
      isSuccess={isRequestSuccess(state?.form)}
      initialValue={getInitialValue()}
      validation={purchaseAdminItemUpdateDataFormValidation}
      onSubmitForm={purchaseAdminItemDeleteStatusSendData}
      pageLoading={pageLoading}
      errorMessage={getRequestErrorMessage(state?.form)}
    />
  );
}
