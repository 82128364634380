import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import InputMask from "react-input-mask";

import { TextComponent } from "../../../lib/common/text";
import { FieldTextComponent } from "../../../lib/common/field-text/field-text.component";
import { FieldSelectComponent } from "../../../lib/common/field-select/field-select.component";
import { PURCHASE_STATUS_OPTION } from "../../../lib/common/purchase/purchase.type";
import { PURCHASE_ADMIN_ITEM_UPDATE_DATA_DATA_NAME } from "../purchase-admin-item-update-data.constant";
import { ModalConfirmComponent } from "../../../lib/common/modal-confirm/modal-confirm.component";
import { REGION_OPTION } from "../../account-settings-update-user-data/account-settings-update-user-data.constant";

export const PurchaseAdminItemUpdateDataFormComponent = (props) => {
  const {
    handleChange,
    handleBlur,
    handleSubmit,
    values,
    errors,
    touched,
    isValid,
    setFieldValue,
    submitForm,
    pageLoading,
    isSuccess,
    isError,
    isPending,
    initialValue,
  } = props;

  const isFieldError = (name) => {
    return errors[name] && touched[name] && errors[name];
  };

  const getFieldError = (name) => isFieldError(name) && errors[name];

  const isSubmitDisabled = () => {
    if (isError) {
      return false;
    }

    if (isPending) {
      return true;
    }

    if (JSON.stringify(touched) === "{}") {
      return true;
    }

    if (!isValid || isSuccess || pageLoading) {
      return true;
    }
  };

  console.log("initialValue", initialValue);

  return (
    <form onSubmit={handleSubmit}>
      <Box sx={{ pt: 4 }}>
        <Grid spacing={3} container>
          <Grid item>
            <FieldSelectComponent
              option={PURCHASE_STATUS_OPTION}
              label={
                <TextComponent tid="PURCHASE.ADMIN.UPDATE.DATA.FORM.STATUS" />
              }
              name={PURCHASE_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.STATUS}
              onChange={handleChange}
              setFieldValue={setFieldValue}
              onBlur={handleBlur}
              value={values[PURCHASE_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.STATUS]}
              error={isFieldError(
                PURCHASE_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.STATUS
              )}
              fullWidth
              errorText={getFieldError(
                PURCHASE_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.STATUS
              )}
            />
          </Grid>
          <Grid item>
            <FieldTextComponent
              label={
                <TextComponent tid="PURCHASE.ADMIN.UPDATE.DATA.FORM.COMMENTS" />
              }
              name={PURCHASE_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.COMMENTS}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values[PURCHASE_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.COMMENTS]}
              error={isFieldError(
                PURCHASE_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.COMMENTS
              )}
              fullWidth
              errorText={getFieldError(
                PURCHASE_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.COMMENTS
              )}
            />
          </Grid>
          <Grid item>
            <FieldTextComponent
              label={
                <TextComponent tid="PURCHASE.ADMIN.UPDATE.DATA.FORM.NOTES" />
              }
              name={PURCHASE_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.NOTES}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values[PURCHASE_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.NOTES]}
              error={isFieldError(
                PURCHASE_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.NOTES
              )}
              fullWidth
              errorText={getFieldError(
                PURCHASE_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.NOTES
              )}
            />
          </Grid>
          <Grid item>
            <FieldTextComponent
              label={
                <TextComponent tid="PURCHASE.ADMIN.UPDATE.DATA.FORM.TRACK_NUMBER" />
              }
              name={PURCHASE_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.TRACK_NUMBER}
              type="number"
              onChange={handleChange}
              onBlur={handleBlur}
              value={
                values[PURCHASE_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.TRACK_NUMBER]
              }
            />
          </Grid>
          <Grid item>
            <FieldTextComponent
              label={
                <TextComponent tid="PURCHASE.ADMIN.UPDATE.DATA.FORM.FULL_NAME" />
              }
              name={PURCHASE_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.FULL_NAME}
              onChange={handleChange}
              onBlur={handleBlur}
              value={
                values[PURCHASE_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.FULL_NAME]
              }
            />
          </Grid>
          <Grid item>
            <InputMask
              mask="+380 (99) 999 99 99"
              type="phone"
              placeholder="+380"
              maskChar={null}
              id={PURCHASE_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.PHONE}
              name={PURCHASE_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.PHONE}
              label={
                <TextComponent tid="PURCHASE.ADMIN.UPDATE.DATA.FORM.PHONE" />
              }
              value={values[PURCHASE_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.PHONE]}
              onChange={handleChange}
              onBlur={handleBlur}
              error={isFieldError(
                PURCHASE_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.PHONE
              )}
              fullWidth
              disableUnderline
              errorText={getFieldError(
                PURCHASE_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.PHONE
              )}
            >
              {(inputProps) => <FieldTextComponent {...inputProps} />}
            </InputMask>
          </Grid>
          <Grid item>
            <FieldTextComponent
              label={
                <TextComponent tid="PURCHASE.ADMIN.UPDATE.DATA.FORM.CITY" />
              }
              name={PURCHASE_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.CITY}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values[PURCHASE_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.CITY]}
            />
          </Grid>
          <Grid item>
            <FieldSelectComponent
              option={REGION_OPTION}
              label={
                <TextComponent tid="PURCHASE.ADMIN.UPDATE.DATA.FORM.REGION" />
              }
              name={PURCHASE_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.REGION}
              onChange={handleChange}
              onBlur={handleBlur}
              error={isFieldError(
                PURCHASE_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.REGION
              )}
              defaultValue={
                values[PURCHASE_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.REGION]
              }
              fullWidth
              errorText={getFieldError(
                PURCHASE_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.REGION
              )}
            />
          </Grid>
          <Grid item>
            <FieldTextComponent
              label={
                <TextComponent tid="PURCHASE.ADMIN.UPDATE.DATA.FORM.POST_OFFICE" />
              }
              name={PURCHASE_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.POST_OFFICE}
              onChange={handleChange}
              onBlur={handleBlur}
              value={
                values[PURCHASE_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.POST_OFFICE]
              }
            />
          </Grid>
          <Grid item>
            <ModalConfirmComponent
              disabled={isSubmitDisabled()}
              action={submitForm}
            >
              <Button fullWidth>
                <TextComponent tid="PURCHASE.ADMIN.UPDATE.DATA.FORM.SUBMIT" />
              </Button>
            </ModalConfirmComponent>
          </Grid>
        </Grid>
      </Box>
    </form>
  );
};
