import { initializeNewWebAudioContext } from "../../../main/audio";
import {
  flashDocumentTitle,
  clearFlashDocumentTitle,
} from "../../../main/flash-title";
import { text } from "../text";
import { NOTICE_TYPE_TYPE, VISUAL_NOTICE_TYPE_TYPE } from "./notice.type";
import { PURCHASE_CUSTOMER_ITEM_ROUTE_PATH_DYNAMIC } from "../../../core/purchase-customer-item/purchase-customer-item.constant";
import { PURCHASE_ADMIN_ITEM_ROUTE_PATH_DYNAMIC } from "../../../core/purchase-admin-item/purchase-admin-item.constant";

export function initVisualNoticePlayer() {
  const webAudioContext = initializeNewWebAudioContext();

  const context = webAudioContext();

  context.loadSound(
    "/static/sound/default.mp3",
    VISUAL_NOTICE_TYPE_TYPE.DEFAULT
  );
  context.loadSound(
    "/static/sound/paymentCreate.mp3",
    VISUAL_NOTICE_TYPE_TYPE.PAYMENT_CREATE
  );
  context.loadSound(
    "/static/sound/purchaseAdded.mp3",
    VISUAL_NOTICE_TYPE_TYPE.PURCHASE_ADDED
  );

  const onErrorSound = new Audio("/static/sound/default.mp3");

  return (tid, tvalue, type = VISUAL_NOTICE_TYPE_TYPE.DEFAULT) => {
    try {
      flashDocumentTitle(text(tid, tvalue));

      if (typeof document === "undefined") return null;

      const width = document ? document.body.clientWidth || 1200 : 1200;

      if (width < 1200) return null;

      context.playSound(type);

      console.log("notice play sound one");
    } catch {
      onErrorSound.play();
      console.log("notice play sound one on error");
    }
  };
}

export const clearVisualNotice = () => {
  clearFlashDocumentTitle();
};

export function chooseNoticeCallbackByType(typeId, data, redirect) {
  switch (typeId) {
    case NOTICE_TYPE_TYPE.PURCHASE_CREATE:
      return [
        () =>
          redirect(PURCHASE_CUSTOMER_ITEM_ROUTE_PATH_DYNAMIC, {
            params: { purchaseId: data.purchaseId },
          }),
        PURCHASE_CUSTOMER_ITEM_ROUTE_PATH_DYNAMIC({
          purchaseId: data.purchaseId,
        }),
      ];
    case NOTICE_TYPE_TYPE.PAYMENT_NEED_PAY:
      return [
        () =>
          redirect(PURCHASE_CUSTOMER_ITEM_ROUTE_PATH_DYNAMIC, {
            params: { purchaseId: data.purchaseId },
          }),
        PURCHASE_CUSTOMER_ITEM_ROUTE_PATH_DYNAMIC({
          purchaseId: data.purchaseId,
        }),
      ];

    case NOTICE_TYPE_TYPE.PURCHASE_GET_GDS_ITEM:
      return [
        () =>
          redirect(PURCHASE_CUSTOMER_ITEM_ROUTE_PATH_DYNAMIC, {
            params: { purchaseId: data.purchaseId },
          }),
        PURCHASE_CUSTOMER_ITEM_ROUTE_PATH_DYNAMIC({
          purchaseId: data.purchaseId,
        }),
      ];
    case NOTICE_TYPE_TYPE.PURCHASE_DONE_FOR_CUSTOMER:
      return [
        () =>
          redirect(PURCHASE_CUSTOMER_ITEM_ROUTE_PATH_DYNAMIC, {
            params: { purchaseId: data.purchaseId },
          }),
        PURCHASE_CUSTOMER_ITEM_ROUTE_PATH_DYNAMIC({
          purchaseId: data.purchaseId,
        }),
      ];

    case NOTICE_TYPE_TYPE.PURCHASE_UPDATE_STATUS_FOR_CUSTOMER:
      return [
        () =>
          redirect(PURCHASE_CUSTOMER_ITEM_ROUTE_PATH_DYNAMIC, {
            params: { purchaseId: data.purchaseId },
          }),
        PURCHASE_CUSTOMER_ITEM_ROUTE_PATH_DYNAMIC({
          purchaseId: data.purchaseId,
        }),
      ];
    case NOTICE_TYPE_TYPE.PAYMENT_CREATE:
      return [
        () =>
          redirect(PURCHASE_CUSTOMER_ITEM_ROUTE_PATH_DYNAMIC, {
            params: { purchaseId: data.purchaseId },
          }),
        PURCHASE_CUSTOMER_ITEM_ROUTE_PATH_DYNAMIC({
          purchaseId: data.purchaseId,
        }),
      ];
    case NOTICE_TYPE_TYPE.BOOST_UPDATE_ACTUAL_RANK_FOR_CUSTOMER:
      return [
        () =>
          redirect(PURCHASE_CUSTOMER_ITEM_ROUTE_PATH_DYNAMIC, {
            params: { purchaseId: data.purchaseId },
          }),
        PURCHASE_CUSTOMER_ITEM_ROUTE_PATH_DYNAMIC({
          purchaseId: data.purchaseId,
        }),
      ];
    case NOTICE_TYPE_TYPE.PAYMENT_CREATE_FOR_ADMIN:
      return [
        () =>
          redirect(PURCHASE_ADMIN_ITEM_ROUTE_PATH_DYNAMIC, {
            params: { purchaseId: data.purchaseId },
          }),
        PURCHASE_ADMIN_ITEM_ROUTE_PATH_DYNAMIC,
      ];

    default:
      return [null, null];
  }
}
