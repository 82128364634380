import { USER_ADMIN_ITEM_DATA_DATA_NAME } from './user-admin-item-data.constant';

import {
  convertTimezone,
  convertUserRole,
} from '../../lib/common/user/user.convert';

export const convertUserAdminItemData = (data) => ({
  [USER_ADMIN_ITEM_DATA_DATA_NAME.LOGIN]:
    data[USER_ADMIN_ITEM_DATA_DATA_NAME.LOGIN],
  [USER_ADMIN_ITEM_DATA_DATA_NAME.EMAIL]:
    data[USER_ADMIN_ITEM_DATA_DATA_NAME.EMAIL],
  [USER_ADMIN_ITEM_DATA_DATA_NAME.ID]: data[USER_ADMIN_ITEM_DATA_DATA_NAME.ID],
  [USER_ADMIN_ITEM_DATA_DATA_NAME.TIMEZONE]: convertTimezone(
    data[USER_ADMIN_ITEM_DATA_DATA_NAME.TIMEZONE],
  ),
  [USER_ADMIN_ITEM_DATA_DATA_NAME.ROLE]: convertUserRole(
    data[USER_ADMIN_ITEM_DATA_DATA_NAME.ROLE],
  ),
  [USER_ADMIN_ITEM_DATA_DATA_NAME.FULL_NAME]:
    data[USER_ADMIN_ITEM_DATA_DATA_NAME.FULL_NAME],
  [USER_ADMIN_ITEM_DATA_DATA_NAME.PHONE]:
    data[USER_ADMIN_ITEM_DATA_DATA_NAME.PHONE],
  [USER_ADMIN_ITEM_DATA_DATA_NAME.CITY]:
    data[USER_ADMIN_ITEM_DATA_DATA_NAME.CITY],
  [USER_ADMIN_ITEM_DATA_DATA_NAME.REGION]:
    data[USER_ADMIN_ITEM_DATA_DATA_NAME.REGION],
  [USER_ADMIN_ITEM_DATA_DATA_NAME.POST_OFFICE]:
    data[USER_ADMIN_ITEM_DATA_DATA_NAME.POST_OFFICE],
});
