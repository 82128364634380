import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { PurchaseAdminListComponent } from "./purchase-admin-list.component";
import {
  getPurchaseAdminList,
  setPurchaseAdminTrackNumberForm,
} from "./purchase-admin-list.action";
import { PURCHASE_ADMIN_LIST_STORE_NAME } from "./purchase-admin-list.constant";
import { NAVIGATION_STORE_NAME } from "../../lib/common/navigation";
import { PURCHASE_ADMIN_LIST_DATA_NAME } from "./purchase-admin-list.constant";

import {
  getRequestData,
  getRequestErrorMessage,
  isRequestError,
  isRequestPending,
  isRequestSuccess,
  isRequestUpdatePending,
} from "../../main/store/store.service";
import { PURCHASE_ADMIN_ITEM_UPDATE_DATA_STORE_NAME } from "../purchase-admin-item-update-data/purchase-admin-item-update-data.constant";

export function PurchaseAdminListContainer() {
  const dispatch = useDispatch();
  const { state, pageLoading, purchaseState, purchaseUpdateState } =
    useSelector((state) => ({
      state: state[PURCHASE_ADMIN_LIST_STORE_NAME],
      purchaseState: state[PURCHASE_ADMIN_LIST_STORE_NAME],
      purchaseUpdateState: state[PURCHASE_ADMIN_ITEM_UPDATE_DATA_STORE_NAME],
      pageLoading: state[NAVIGATION_STORE_NAME].pageLoading,
    }));

  React.useEffect(() => {
    dispatch(getPurchaseAdminList());
  }, []);

  const getInitialValue = () => {
    if (isRequestSuccess(purchaseState.request)) {
      const data = getRequestData(purchaseState.request);

      const mappedObject = {};

      for (const obj of data.list) {
        const objectName = `${PURCHASE_ADMIN_LIST_DATA_NAME.TRACK_NUMBER}${obj.id}`;

        mappedObject[objectName] =
          obj[PURCHASE_ADMIN_LIST_DATA_NAME.TRACK_NUMBER];
      }

      return { ...mappedObject };
    }
  };

  const purchaseAdminItemUpdateDataFormSendData = (data, purchaseId) => {
    const requestObject = {
      [PURCHASE_ADMIN_LIST_DATA_NAME.TRACK_NUMBER]: data,
    };

    dispatch(setPurchaseAdminTrackNumberForm(requestObject, purchaseId));
  };

  return (
    <PurchaseAdminListComponent
      initialValue={getInitialValue()}
      onSubmitForm={purchaseAdminItemUpdateDataFormSendData}
      isPending={isRequestPending(state.request)}
      isUpdating={isRequestUpdatePending(state.request)}
      isError={isRequestError(state.request)}
      isSuccess={isRequestSuccess(state.request)}
      pageLoading={pageLoading}
      data={getRequestData(state.request)}
      errorMessage={getRequestErrorMessage(state.request)}
      errorMessageField={getRequestErrorMessage(purchaseUpdateState.form)}
      isErrorField={isRequestError(purchaseUpdateState.form)}
      isSuccessField={isRequestSuccess(purchaseUpdateState.form)}
      isPendingField={isRequestPending(purchaseUpdateState.form)}
    />
  );
}
