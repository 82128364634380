export const PURCHASE_ADMIN_LIST_ROUTE_PATH = '/admin/purchase';

export const PURCHASE_ADMIN_LIST_STORE_NAME = 'PURCHASE_ADMIN_LIST';

export const PURCHASE_ADMIN_LIST_API = {
  PURCHASE_ADMIN_LIST: {
    ENDPOINT: `/purchase/admin`,
    TYPE: 'GET',
  },
};

export const PURCHASE_ADMIN_LIST_ACTION_TYPE = {
  REQUEST_PENDING: 'PURCHASE_ADMIN_LIST_ACTION_TYPE.REQUEST_PENDING',
  REQUEST_SUCCESS: 'PURCHASE_ADMIN_LIST_ACTION_TYPE.REQUEST_SUCCESS',
  REQUEST_ERROR: 'PURCHASE_ADMIN_LIST_ACTION_TYPE.REQUEST_ERROR',
  REQUEST_UPDATE_SUCCESS:
    'PURCHASE_ADMIN_LIST_ACTION_TYPE.REQUEST_UPDATE_SUCCESS',
  REQUEST_UPDATE_PENDING:
    'PURCHASE_ADMIN_LIST_ACTION_TYPE.REQUEST_UPDATE_PENDING',
};

export const PURCHASE_ADMIN_LIST_DATA_NAME = {
  LIST: 'list',

  ID: 'id',
  TYPE: 'type',
  CREATE_DATE: 'createDate',
  CREATE_DATE_STOCK: 'createDateStock',
  STATUS: 'status',
  PRICE: 'price',
  LANG: 'lang',
  GDS: 'gds',
  METADATA: 'metadata',
  INVOICE_LINK: "invoiceLink",

  PAYMENT: 'payment',
  PAYMENT_ID: 'id',
  CONFIRM: "confirm",
  SYSTEM: 'system',
  CONTACTS: 'contacts',
  REGION: 'region',
  CITY: 'city',
  POST_OFFICE: 'postOffice',
  FULL_NAME: 'fullName',
  PHONE: 'phone',
  TRACK_NUMBER: 'trackNumber',
  GDS_ORDER: 'gdsOrder',
  WEIGHT: 'weight',
  COMMENTS: 'comments',
  CART: 'cart',

  SKIP: 'skip',
  TAKE: 'take',
  PAID: 'paid',

  PRICE: 'price',

  IS_CUSTOMER: 'isCustomer',
  CUSTOMER: 'customer',
  CUSTOMER_ID: 'id',
  CUSTOMER_EMAIL: 'email',
};
