export const GDS_ITEM_ADMIN_ITEM_UPDATE_DATA_STORE_NAME =
  "GDS_ITEM_ADMIN_ITEM_UPDATE_DATA";

export const GDS_ITEM_ADMIN_ITEM_UPDATE_DATA_API = {
  CHANGE_DATA: {
    ENDPOINT: (gdsId, gdsItemId) => `/gds/${gdsId}/item/${gdsItemId}`,
    TYPE: "PATCH",
  },
};

export const GDS_ITEM_ADMIN_ITEM_UPDATE_DATA_ACTION_TYPE = {
  FORM_PENDING: "GDS_ITEM_ADMIN_ITEM_UPDATE_DATA_ACTION_TYPE.FORM_PENDING",
  FORM_SUCCESS: "GDS_ITEM_ADMIN_ITEM_UPDATE_DATA_ACTION_TYPE.FORM_SUCCESS",
  FORM_ERROR: "GDS_ITEM_ADMIN_ITEM_UPDATE_DATA_ACTION_TYPE.FORM_ERROR",
  FORM_RESET: "GDS_ITEM_ADMIN_ITEM_UPDATE_DATA_ACTION_TYPE.FORM_RESET",
};

export const GDS_ITEM_ADMIN_ITEM_UPDATE_DATA_DATA_NAME = {
  AMOUNT: "amount",
  SIZE: "size",
  ORDER: "order",
  NAME: "name",
  CREATE_DATE: "createDate",
  UPDATE_DATE: "updateDate",
  ID: "id",
  GDS_ID: "gdsId",
  STATUS: "status",
};
