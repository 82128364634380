import * as React from "react";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";

import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";

import Badge from "@mui/material/Badge";
import NotificationsIcon from "@mui/icons-material/Notifications";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import { NoticeAccountListShortHandler } from "../notice-account-list-short/notice-account-list-short.handler";

export function NavigationMobileAppComponent(props) {
  const navigate = useNavigate();
  const { routeList, activePath, children, noticeAmount } = props;
  const [value, setValue] = React.useState(activePath);

  return (
    <Box>
      <NoticeAccountListShortHandler />
      <Box
        component="main"
        sx={{
          width: "100vw",
          px: 4,
          py: 24,
          pt: 8,
          minHeight: "calc(100vh - 65px)",
        }}
      >
        {children}
      </Box>
      <Box sx={{ position: "fixed", left: 0, bottom: 0, width: "100%" }}>
        <BottomNavigation showLabels value={value}>
          {routeList.map((route) => {
            if (route.id === 13) {
              return (
                <React.Fragment key={route.id}>
                  <BottomNavigationAction
                    value={route.path}
                    // label={<TextComponent variant="navigationItem" tid={'Notification'} />}
                    onClick={() => navigate(route.route)}
                    icon={
                      noticeAmount ? (
                        <Badge badgeContent={noticeAmount} color="primary">
                          <NotificationsIcon sx={{ fontSize: 28 }} />
                        </Badge>
                      ) : (
                        <NotificationsNoneIcon sx={{ fontSize: 28 }} />
                      )
                    }
                  />
                </React.Fragment>
              );
            }

            return (
              <React.Fragment key={route.id}>
                <BottomNavigationAction
                  value={route.path}
                  // label={<TextComponent variant="navigationItem" tid={route.tid} />}
                  onClick={() => navigate(route.route)}
                  icon={route.icon}
                />
              </React.Fragment>
            );
          })}
        </BottomNavigation>
      </Box>
    </Box>
  );
}
