import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import { PurchaseCustomerItemTabContainer } from "../purchase-customer-item-tab/purchase-customer-item-tab.container";
import { PurchaseCustomerItemUpdateDataContainer } from "../purchase-customer-item-update-data/purchase-customer-item-update-data.container";

export function PurchaseCustomerItemUpdatePage() {
  return (
    <Container maxWidth="sm">
      <Grid spacing={3} container>
        <Grid item>
          <PurchaseCustomerItemTabContainer />
        </Grid>
        <Grid item>
          <PurchaseCustomerItemUpdateDataContainer />
        </Grid>
      </Grid>
    </Container>
  );
}
