import Box from '@mui/material/Box';

import List from '@mui/material/List';

import { TextComponent } from '../../../lib/common/text/text.component';

import { NoticeItemComponent } from '../../../lib/common/notice/notice-item.component';

export function NoticeAdminListViewComponent(props) {
  const { data } = props;

  if (data.list.length === 0) {
    return (
      <Box sx={{ px: 6, pt: 4 }}>
        <TextComponent variant="body1" tid="NOTICE.ADMIN.LIST.LIST_IS_EMPTY" />
      </Box>
    );
  }

  return (
    <List sx={{ pt: 4 }}>
      {data.list.map((item, index) => (
        <NoticeItemComponent admin {...item} index={index} />
      ))}
    </List>
  );
}
