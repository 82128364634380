import { httpRequest } from '../../main/http';

import {
  ACCOUNT_SETTINGS_UPDATE_USER_ACTION_TYPE,
  ACCOUNT_SETTINGS_UPDATE_USER_DATA_API,
} from './account-settings-update-user-data.constant';

import { authUpdateUserData } from '../../lib/common/auth';

import {
  USER_ADMIN_ITEM_DATA_API,
  USER_ADMIN_ITEM_DATA_ACTION_TYPE,
} from '../user-admin-item-data/user-admin-item-data.constant';

import { convertUserAdminItemData } from '../user-admin-item-data/user-admin-item-data.convert';

export function uploadAccountSettingsUpdateUserDataForm(data) {
  return async (dispatch) => {
    dispatch({
      type: ACCOUNT_SETTINGS_UPDATE_USER_ACTION_TYPE.FORM_PENDING,
    });

    try {
      await httpRequest({
        method: ACCOUNT_SETTINGS_UPDATE_USER_DATA_API.CHANGE_USER_DATA.TYPE,
        url: ACCOUNT_SETTINGS_UPDATE_USER_DATA_API.CHANGE_USER_DATA.ENDPOINT,
        data,
      });

      await dispatch(authUpdateUserData());

      dispatch({
        type: ACCOUNT_SETTINGS_UPDATE_USER_ACTION_TYPE.FORM_SUCCESS,
      });
    } catch (error) {
      if (error) {
        console.log(error);
      }
      if (error.response) {
        dispatch({
          type: ACCOUNT_SETTINGS_UPDATE_USER_ACTION_TYPE.FORM_ERROR,
          errorMessage: error.response.data.message,
        });
      }
    }
  };
}

export function resetAccountSettingsUpdateUserDataFormState() {
  return async (dispatch) => {
    dispatch({
      type: ACCOUNT_SETTINGS_UPDATE_USER_ACTION_TYPE.FORM_RESET,
    });
  };
}

export function getUserAdminItemData(data) {
  return async (dispatch) => {
    dispatch({
      type: USER_ADMIN_ITEM_DATA_ACTION_TYPE.REQUEST_PENDING,
    });

    try {
      const res = await httpRequest({
        method: USER_ADMIN_ITEM_DATA_API.USER_ADMIN_ITEM_DATA.TYPE,
        url: USER_ADMIN_ITEM_DATA_API.USER_ADMIN_ITEM_DATA.ENDPOINT(
          data.userId,
        ),
      });

      dispatch({
        type: USER_ADMIN_ITEM_DATA_ACTION_TYPE.REQUEST_SUCCESS,
        data: convertUserAdminItemData(res.data),
        userId: data.userId,
      });
    } catch (error) {
      if (error) {
        console.log(error);
      }
      if (error.response) {
        dispatch({
          type: USER_ADMIN_ITEM_DATA_ACTION_TYPE.REQUEST_ERROR,
          errorMessage: error.response.data.message,
        });
      }
    }
  };
}
