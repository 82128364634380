export const PURCHASE_CUSTOMER_ITEM_STORE_NAME = "PURCHASE_CUSTOMER_ITEM";

export const PURCHASE_CUSTOMER_ITEM_ROUTE_PATH =
  "/customer/purchase/:purchaseId";

export const PURCHASE_CUSTOMER_ITEM_ROUTE_PATH_DYNAMIC = (
  { purchaseId } = {
    purchaseId: ":purchaseId",
  }
) => `/customer/purchase/${purchaseId}`;

export const PURCHASE_CUSTOMER_ITEM_API = {
  PURCHASE_CUSTOMER_ITEM: {
    ENDPOINT: (purchaseId) => `/purchase/${purchaseId}/customer`,
    TYPE: "GET",
  },
};

export const PURCHASE_CUSTOMER_ITEM_ACTION_TYPE = {
  REQUEST_PENDING: "PURCHASE_CUSTOMER_ITEM_ACTION_TYPE.REQUEST_PENDING",
  REQUEST_SUCCESS: "PURCHASE_CUSTOMER_ITEM_ACTION_TYPE.REQUEST_SUCCESS",
  REQUEST_ERROR: "PURCHASE_CUSTOMER_ITEM_ACTION_TYPE.REQUEST_ERROR",
  REQUEST_UPDATE_SUCCESS:
    "PURCHASE_CUSTOMER_ITEM_ACTION_TYPE.REQUEST_UPDATE_SUCCESS",
  REQUEST_UPDATE_PENDING:
    "PURCHASE_CUSTOMER_ITEM_ACTION_TYPE.REQUEST_UPDATE_PENDING",
};

export const PURCHASE_CUSTOMER_ITEM_DATA_NAME = {
  ID: "id",
  NOTES: "notes",
  METADATA: "metadata",
  COMMENTS: "comments",
  TYPE: "type",
  CREATE_DATE: "createDate",
  STATUS: "status",
  PRICE: "price",
  TIMEZONE: "timezone",
  IS_PAID: "isPaid",
  TRACK_NUMBER: "trackNumber",
  INVOICE_LINK: "invoiceLink",
  CART: "cart",

  PAYMENT: "payment",
  PAID: "isPaid",
  PAYMENT_ID: "id",
  CONFIRM: "confirm",
  SYSTEM: "system",

  IS_CUSTOMER: "isCustomer",

  WEIGHT: "weight",
  GDS_ORDER: "gdsOrder",
  TITLE: "title",
  DESCRIPTION: "description",

  CUSTOMER: "customer",

  FULL_NAME: "fullName",
  POST_OFFICE: "postOffice",
  REGION: "region",
  EMAIL: "email",
  PHONE: "phone",
  CITY: "city",
};
