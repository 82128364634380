import * as React from "react";
import styled from "styled-components";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { InputAdornment } from "@mui/material";

import { text } from "../text";

export const FieldTextComponent = ({
  errorText,
  error,
  label,
  placeholder,
  endAdornment,
  disabled = false,
  ...props
}) => {
  return (
    <Grid container spacing={2}>
      {label && (
        <Grid item>
          <Typography variant="fieldLabel">{label}</Typography>
        </Grid>
      )}
      <Grid item>
        <TextFildStyled
          disabled={disabled}
          placeholder={placeholder ? text(placeholder) : ""}
          error={error}
          InputProps={{
            endAdornment: endAdornment && (
              <InputAdornmentStyled position="end">
                {endAdornment}
              </InputAdornmentStyled>
            ),
            inputProps: { min: 0 },
          }}
          {...props}
        />
      </Grid>
      {error && errorText && (
        <Grid item>
          <Typography variant="errorText">{errorText}</Typography>
        </Grid>
      )}
    </Grid>
  );
};

const TextFildStyled = styled(TextField)`
  .MuiOutlinedInput-root.Mui-disabled {
    opacity: 0.5 !important;
  }
`;

const InputAdornmentStyled = styled(InputAdornment)`
  padding: 0 6px;
`;
