import i18n from "i18next";
import { addMinutes, format, subDays, formatRelative } from "date-fns";

import { uk, enGB } from "date-fns/locale";

const locales = { uk, enGB };

export const convertDateToCalendarTime = (date) => {
  return formatRelative(subDays(new Date(date), 0), new Date(), {
    locale: locales["uk"],
  });
};

export const convertDatetime = (date) => {
  return format(new Date(date), i18n.t("CONVERT.DATE.DATE_TIME"), {
    locale: locales["uk"],
  });
};

export const convertDate = (date) => {
  return format(new Date(date), i18n.t("LANG.DATA.DATE"), {
    locale: locales["uk"],
  });
};

export const convertTime = (date) => {
  return format(new Date(date), i18n.t("CONVERT.DATE.TIME"), {
    locale: locales["enGB"],
  });
};

export const formatDateToUtc = (date) => {
  try {
    const newDate = format(
      addMinutes(date, date.getTimezoneOffset()),
      "yyyy-MM-dd'T'HH:mm:ss"
    );
    return newDate;
  } catch (error) {
    console.log(error);
  }
};
