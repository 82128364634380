import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import { PurchaseAdminItemUpdateCustomerContainer } from "../purchase-admin-item-update-customer/purchase-admin-item-update-customer.container";
import { PurchaseAdminItemUpdateDataContainer } from "../purchase-admin-item-update-data/purchase-admin-item-update-data.container";
import { PurchaseAdminItemContainer } from "../purchase-admin-item/purchase-admin-item.container";
import { PurchaseAdminItemPaymentConfirmContainer } from "../purchase-admin-item-payment-confirm/purchase-admin-item-payment-confirm.container";
import { PurchaseAdminItemPaymentCreateContainer } from "../purchase-admin-item-payment-create/purchase-admin-item-payment-create.container";
import { PurchaseAdminItemTabContainer } from "../purchase-admin-item-tab/purchase-admin-item-tab.container";
import { PurchaseAdminItemDeleteContainer } from "../purchase-admin-item-delete/purchase-admin-item-delete.container";

export function PurchaseAdminItemUpdatePage() {
  return (
    <>
      <PurchaseAdminItemContainer />
      <Container maxWidth="sm">
        <Grid spacing={3} container>
          <Grid item>
            <PurchaseAdminItemTabContainer />
          </Grid>
          <Grid item>
            <PurchaseAdminItemUpdateCustomerContainer />
          </Grid>
          <Grid item>
            <PurchaseAdminItemDeleteContainer />
          </Grid>
          <Grid item>
            <PurchaseAdminItemPaymentCreateContainer />
          </Grid>
          <Grid item>
            <PurchaseAdminItemPaymentConfirmContainer />
          </Grid>
          <Grid item>
            <PurchaseAdminItemUpdateDataContainer />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
