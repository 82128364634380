export const PURCHASE_ADMIN_ITEM_PAYMENT_CONFIRM_STORE_NAME =
  "PURCHASE_ADMIN_ITEM_PAYMENT_CONFIRM";

export const PURCHASE_ADMIN_ITEM_PAYMENT_CONFIRM_API = {
  CHANGE_PASSWORD: {
    ENDPOINT: (purchaseId) => `/payment/purchase/${purchaseId}/confirm`,
    TYPE: "POST",
  },
  SET_INVOICE: {
    ENDPOINT: (purchaseId) => `/purchase/${purchaseId}/invoice`,
    TYPE: "PATCH",
  },
};

export const PURCHASE_ADMIN_ITEM_PAYMENT_CONFIRM_ACTION_TYPE = {
  FORM_PENDING: "PURCHASE_ADMIN_ITEM_PAYMENT_CONFIRM_ACTION_TYPE.FORM_PENDING",
  FORM_SUCCESS: "PURCHASE_ADMIN_ITEM_PAYMENT_CONFIRM_ACTION_TYPE.FORM_SUCCESS",
  FORM_ERROR: "PURCHASE_ADMIN_ITEM_PAYMENT_CONFIRM_ACTION_TYPE.FORM_ERROR",
  FORM_RESET: "PURCHASE_ADMIN_ITEM_PAYMENT_CONFIRM_ACTION_TYPE.FORM_RESET",
};

export const PURCHASE_ADMIN_ITEM_PAYMENT_CONFIRM_DATA_NAME = {
  // ID: 'id',
};
