import React from "react";
import Grid from "@mui/material/Grid";

import { TextComponent } from "../../../lib/common/text/text.component";
import { PURCHASE_CUSTOMER_ITEM_DATA_NAME } from "../../purchase-customer-item/purchase-customer-item.constant";
import { Link } from "@mui/material";

export function PurchaseCustomerItemDataViewComponent(props) {
  const { data } = props;
  console.log("data", data);

  return (
    <Grid container spacing={4} sx={{ pt: 4 }}>
      <Grid item>
        <Grid container spacing={3}>
          <Grid item>
            <TextComponent
              gutterBottom
              variant="dataViewTitle"
              tid="PURCHASE.CUSTOMER.ITEM_DATA.ID"
            />
            <TextComponent variant="dataViewContent">
              {data[PURCHASE_CUSTOMER_ITEM_DATA_NAME.ID]}
            </TextComponent>
          </Grid>

          <Grid item>
            <TextComponent
              gutterBottom
              variant="dataViewTitle"
              tid="PURCHASE.CUSTOMER.ITEM_DATA.CREATE_DATE"
            />
            <TextComponent variant="dataViewContent">
              {data[PURCHASE_CUSTOMER_ITEM_DATA_NAME.CREATE_DATE]}
            </TextComponent>
          </Grid>

          <Grid item>
            <TextComponent
              gutterBottom
              variant="dataViewTitle"
              tid="PURCHASE.CUSTOMER.ITEM_DATA.TRACK_NUMBER"
            />
            {data[PURCHASE_CUSTOMER_ITEM_DATA_NAME.TRACK_NUMBER] && (
              <TextComponent variant="dataViewContent">
                {data[PURCHASE_CUSTOMER_ITEM_DATA_NAME.TRACK_NUMBER]}
              </TextComponent>
            )}
            {!data[PURCHASE_CUSTOMER_ITEM_DATA_NAME.TRACK_NUMBER] && (
              <TextComponent
                gutterBottom
                variant="dataViewContent"
                tid="PURCHASE.CUSTOMER.ITEM_DATA.TRACK_NUMBER_NULL"
              />
            )}
          </Grid>

          {data[PURCHASE_CUSTOMER_ITEM_DATA_NAME.INVOICE_LINK] && (
            <Grid item>
              <TextComponent
                gutterBottom
                variant="dataViewTitle"
                tid="PURCHASE.CUSTOMER.ITEM_DATA.INVOICE_URL"
              />
              <Link
                sx={{
                  color: "#ededed",
                  textDecorationColor: "#ededed !important",
                }}
                href={data[PURCHASE_CUSTOMER_ITEM_DATA_NAME.INVOICE_LINK]}
              >
                <TextComponent
                  tid="PURCHASE.CUSTOMER.ITEM_DATA.INVOICE_DOWNLOAD"
                  variant="dataViewContent"
                />
              </Link>
            </Grid>
          )}

          <Grid item>
            <TextComponent
              gutterBottom
              variant="dataViewTitle"
              tid="PURCHASE.CUSTOMER.ITEM_DATA.FULL_NAME"
            />
            <TextComponent variant="dataViewContent">
              {data?.customer[PURCHASE_CUSTOMER_ITEM_DATA_NAME.FULL_NAME]}
            </TextComponent>
          </Grid>

          <Grid item>
            <TextComponent
              gutterBottom
              variant="dataViewTitle"
              tid="PURCHASE.CUSTOMER.ITEM_DATA.POST"
            />
            <TextComponent variant="dataViewContent">
              {data?.customer[PURCHASE_CUSTOMER_ITEM_DATA_NAME.POST_OFFICE]}
            </TextComponent>
          </Grid>

          <Grid item>
            <TextComponent
              gutterBottom
              variant="dataViewTitle"
              tid="PURCHASE.CUSTOMER.ITEM_DATA.REGION"
            />
            <TextComponent
              variant="dataViewContent"
              tid="PURCHASE.CUSTOMER.ITEM_DATA.REGION_VALUE"
              tvalue={{
                region: data?.customer[PURCHASE_CUSTOMER_ITEM_DATA_NAME.REGION],
                city: data?.customer[PURCHASE_CUSTOMER_ITEM_DATA_NAME.CITY],
              }}
            />
          </Grid>

          <Grid item>
            <TextComponent
              gutterBottom
              variant="dataViewTitle"
              tid="PURCHASE.CUSTOMER.ITEM_DATA.STATUS"
            />
            <TextComponent variant="dataViewContent">
              {data[PURCHASE_CUSTOMER_ITEM_DATA_NAME.STATUS].text}
            </TextComponent>
          </Grid>

          <Grid item>
            <TextComponent
              gutterBottom
              variant="dataViewTitle"
              tid="PURCHASE.CUSTOMER.ITEM_DATA.CONTACTS"
            />
            <TextComponent variant="dataViewContent">
              {data?.customer[PURCHASE_CUSTOMER_ITEM_DATA_NAME.PHONE] || (
                <TextComponent tid="PURCHASE.CUSTOMER.ITEM_DATA.CONTACTS_NULL" />
              )}
            </TextComponent>
          </Grid>

          <Grid item>
            <TextComponent
              gutterBottom
              variant="dataViewTitle"
              tid="PURCHASE.CUSTOMER.ITEM_DATA.EMAIL"
            />
            <TextComponent variant="dataViewContent">
              {data?.customer[PURCHASE_CUSTOMER_ITEM_DATA_NAME.EMAIL]}
            </TextComponent>
          </Grid>

          <Grid item>
            <TextComponent
              gutterBottom
              variant="dataViewTitle"
              tid="PURCHASE.CUSTOMER.ITEM_DATA.COMMENTS"
            />
            <TextComponent variant="dataViewContent">
              {data[PURCHASE_CUSTOMER_ITEM_DATA_NAME.COMMENTS] || (
                <TextComponent tid="PURCHASE.CUSTOMER.ITEM_DATA.COMMENTS_NULL" />
              )}
            </TextComponent>
          </Grid>

          <Grid item>
            <TextComponent
              gutterBottom
              variant="dataViewTitle"
              tid="PURCHASE.CUSTOMER.ITEM_DATA.WEIGHT"
            />
            <TextComponent variant="dataViewContent">
              {data[PURCHASE_CUSTOMER_ITEM_DATA_NAME.WEIGHT] || ""}
            </TextComponent>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
