import { PURCHASE_CUSTOMER_LIST_DATA_NAME } from "./purchase-customer-list.constant";

import {
  convertPurchaseType,
  convertPurchaseStatus,
} from "../../lib/common/purchase/purchase.convert";

import { convertDate } from "../../lib/common/convert/convert.core";
import { convertPaymentSystemType } from "../../lib/common/payment/payment.convert";
import { convertDatetime } from "../../lib/common/convert/convert.ua";

export const convertPurchaseCustomerList = (d) => ({
  [PURCHASE_CUSTOMER_LIST_DATA_NAME.LIST]: d[
    PURCHASE_CUSTOMER_LIST_DATA_NAME.LIST
  ].map((data) => {
    const purchaseType = convertPurchaseType(
      data[PURCHASE_CUSTOMER_LIST_DATA_NAME.TYPE]
    );

    const paymentData = data[PURCHASE_CUSTOMER_LIST_DATA_NAME.PAYMENT];

    const customerData = {
      [PURCHASE_CUSTOMER_LIST_DATA_NAME.PHONE]:
        data[PURCHASE_CUSTOMER_LIST_DATA_NAME.PHONE],
      [PURCHASE_CUSTOMER_LIST_DATA_NAME.FULL_NAME]:
        data[PURCHASE_CUSTOMER_LIST_DATA_NAME.FULL_NAME],
      [PURCHASE_CUSTOMER_LIST_DATA_NAME.CITY]:
        data[PURCHASE_CUSTOMER_LIST_DATA_NAME.CITY],
      [PURCHASE_CUSTOMER_LIST_DATA_NAME.REGION]:
        data[PURCHASE_CUSTOMER_LIST_DATA_NAME.REGION],
      [PURCHASE_CUSTOMER_LIST_DATA_NAME.POST_OFFICE]:
        data[PURCHASE_CUSTOMER_LIST_DATA_NAME.POST_OFFICE],
    };

    const payment = paymentData
      ? {
          [PURCHASE_CUSTOMER_LIST_DATA_NAME.CONFIRM]:
            paymentData[PURCHASE_CUSTOMER_LIST_DATA_NAME.CONFIRM],
          [PURCHASE_CUSTOMER_LIST_DATA_NAME.SYSTEM]: convertPaymentSystemType(
            paymentData[PURCHASE_CUSTOMER_LIST_DATA_NAME.SYSTEM]
          ),
        }
      : null;

    return {
      [PURCHASE_CUSTOMER_LIST_DATA_NAME.ID]:
        data[PURCHASE_CUSTOMER_LIST_DATA_NAME.ID],
      [PURCHASE_CUSTOMER_LIST_DATA_NAME.PRICE]:
        data[PURCHASE_CUSTOMER_LIST_DATA_NAME.PRICE],
      [PURCHASE_CUSTOMER_LIST_DATA_NAME.WEIGHT]:
        data[PURCHASE_CUSTOMER_LIST_DATA_NAME.WEIGHT],

      [PURCHASE_CUSTOMER_LIST_DATA_NAME.STATUS]: convertPurchaseStatus(
        data[PURCHASE_CUSTOMER_LIST_DATA_NAME.STATUS]
      ),

      [PURCHASE_CUSTOMER_LIST_DATA_NAME.TYPE]: purchaseType,

      [PURCHASE_CUSTOMER_LIST_DATA_NAME.CREATE_DATE]: convertDatetime(
        data[PURCHASE_CUSTOMER_LIST_DATA_NAME.CREATE_DATE]
      ),

      [PURCHASE_CUSTOMER_LIST_DATA_NAME.PAID]:
        !!data[PURCHASE_CUSTOMER_LIST_DATA_NAME.PAYMENT],

      [PURCHASE_CUSTOMER_LIST_DATA_NAME.TRACK_NUMBER]:
        data[PURCHASE_CUSTOMER_LIST_DATA_NAME.TRACK_NUMBER],

      [PURCHASE_CUSTOMER_LIST_DATA_NAME.INVOICE_LINK]:
        data[PURCHASE_CUSTOMER_LIST_DATA_NAME.INVOICE_LINK],

      [PURCHASE_CUSTOMER_LIST_DATA_NAME.CUSTOMER]: customerData,

      [PURCHASE_CUSTOMER_LIST_DATA_NAME.CART]:
        data[PURCHASE_CUSTOMER_LIST_DATA_NAME.CART],

      [PURCHASE_CUSTOMER_LIST_DATA_NAME.PAYMENT]: payment,
    };
  }),
});
