export const GDS_ADMIN_CREATE_ROUTE_PATH = "/admin/gds/create";

export const GDS_ADMIN_CREATE_STORE_NAME = "GDS_ADMIN_CREATE";

export const GDS_ADMIN_CREATE_API = {
  GDS_ADMIN_CREATE: {
    ENDPOINT: "/gds/",
    TYPE: "POST",
  },
};

export const GDS_SIZE_OPTION = [
  { id: 1, value: 1, tid: "GDS.DATA.SIZE.M" },
  { id: 2, value: 2, tid: "GDS.DATA.SIZE.L" },
  { id: 3, value: 3, tid: "GDS.DATA.SIZE.STICKERS" },
];

export const GDS_ADMIN_CREATE_ACTION_TYPE = {
  FORM_PENDING: "GDS_ADMIN_CREATE_ACTION_TYPE.FORM_PENDING",
  FORM_SUCCESS: "GDS_ADMIN_CREATE_ACTION_TYPE.FORM_SUCCESS",
  FORM_ERROR: "GDS_ADMIN_CREATE_ACTION_TYPE.FORM_ERROR",
  FORM_RESET: "GDS_ADMIN_CREATE_ACTION_TYPE.FORM_RESET",
};

export const GDS_ADMIN_CREATE_DATA_NAME = {
  ORDER: "order",
  AMOUNT: "amount",
  PRICE: "price",
  WEIGHT: "weight",
  TITLE: "title",
  DESCRIPTION: "description",
  URL: "url",
  FILE_PREVIEW: "filePreviewImageId",
  FILE_LIST: "fileImageListId",
  CATEGORY: "category",
  TYPE: "type",
  TAG: "tag",
  SIZE: "size",
  BODY: "body",
  AMOUNT_STATUS: "amountStatus",
  PPO: "ppoId",
};
