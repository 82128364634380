import { PURCHASE_STATUS_TYPE } from "../../lib/common/purchase/purchase.type";
import { PURCHASE_ADMIN_ITEM_UPDATE_DATA_DATA_NAME } from "./purchase-admin-item-delete.constant";

export const convertPurchaseAdminItemUpdateDataFormData = (data) => ({
  [PURCHASE_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.COMMENTS]:
    data[PURCHASE_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.COMMENTS] || undefined,
  [PURCHASE_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.NOTES]:
    data[PURCHASE_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.NOTES] || undefined,
  [PURCHASE_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.STATUS]:
    PURCHASE_STATUS_TYPE.DELETED,
  [PURCHASE_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.TRACK_NUMBER]: Number(
    data[PURCHASE_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.TRACK_NUMBER]
  ),
  [PURCHASE_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.FULL_NAME]:
    data[PURCHASE_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.FULL_NAME],
  [PURCHASE_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.REGION]:
    data[PURCHASE_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.REGION],
  [PURCHASE_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.CITY]:
    data[PURCHASE_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.CITY],
  [PURCHASE_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.POST_OFFICE]:
    data[PURCHASE_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.POST_OFFICE],
  [PURCHASE_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.PHONE]:
    data[PURCHASE_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.PHONE],
});
