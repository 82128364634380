import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";

import { FieldTextComponent } from "../../../lib/common/field-text/field-text.component";
import { FieldFileContainer } from "../../../lib/common/field-file/field-file.container";
import { FieldSelectComponent } from "../../../lib/common/field-select/field-select.component";

import { TextComponent } from "../../../lib/common/text";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { GDS_ITEM_ADMIN_ITEM_UPDATE_DATA_DATA_NAME } from "../gds-item-admin-item-update-data.constant";

import {
  GDS_CATEGORY_OPTION,
  GDS_TAG_OPTION,
  GDS_TYPE_OPTION,
  GDS_STATUS_OPTION,
} from "../../../lib/common/gds/gds.type";
import { GDS_SIZE_OPTION } from "../../gds-item-admin-create/gds-item-admin-create.constant";

export const GdsItemAdminItemUpdateDataFormComponent = (props) => {
  const {
    handleChange,
    handleBlur,
    handleSubmit,
    values,
    errors,
    touched,
    isValid,
    isSubmitting,

    pageLoading,
    isSuccess,
    isError,
    isPending,
    setFieldValue,
  } = props;

  const isFieldError = (name) => {
    return errors[name] && touched[name] && errors[name];
  };

  const getFieldError = (name) => isFieldError(name) && errors[name];

  const isSubmitDisabled = () => {
    if (isError) {
      return false;
    }

    if (isPending) {
      return true;
    }

    if (JSON.stringify(touched) === "{}") {
      return true;
    }

    if (!isValid || isSuccess || pageLoading) {
      return true;
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box sx={{ pt: 4 }}>
        <Grid spacing={3} container>
          <Grid item>
            <FieldTextComponent
              type="number"
              label={
                <TextComponent tid="GDS_ITEM.ADMIN.ITEM_UPDATE_DATA.FORM.ORDER" />
              }
              name={GDS_ITEM_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.ORDER}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values[GDS_ITEM_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.ORDER]}
              error={isFieldError(
                GDS_ITEM_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.ORDER
              )}
              fullWidth
              errorText={getFieldError(
                GDS_ITEM_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.ORDER
              )}
            />
          </Grid>

          <Grid item>
            <FieldSelectComponent
              option={GDS_STATUS_OPTION}
              label={
                <TextComponent tid="GDS.ADMIN.ITEM_UPDATE_DATA.FORM.STATUS" />
              }
              name={GDS_ITEM_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.STATUS}
              onChange={handleChange}
              setFieldValue={setFieldValue}
              onBlur={handleBlur}
              value={values[GDS_ITEM_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.STATUS]}
              error={isFieldError(
                GDS_ITEM_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.STATUS
              )}
              fullWidth
              errorText={getFieldError(
                GDS_ITEM_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.STATUS
              )}
            />
          </Grid>

          <Grid item>
            <FieldTextComponent
              type="number"
              label={<TextComponent tid="GDS_ITEM.ADMIN.CREATE.FORM.AMOUNT" />}
              name={GDS_ITEM_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.AMOUNT}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values[GDS_ITEM_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.AMOUNT]}
              error={isFieldError(
                GDS_ITEM_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.AMOUNT
              )}
              fullWidth
              errorText={getFieldError(
                GDS_ITEM_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.AMOUNT
              )}
            />
          </Grid>
          <Grid item>
            <FieldSelectComponent
              option={GDS_SIZE_OPTION}
              label={<TextComponent tid="GDS_ITEM.ADMIN.CREATE.FORM.SIZE" />}
              name={GDS_ITEM_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.SIZE}
              onChange={handleChange}
              setFieldValue={setFieldValue}
              onBlur={handleBlur}
              value={values[GDS_ITEM_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.SIZE]}
              error={isFieldError(
                GDS_ITEM_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.SIZE
              )}
              fullWidth
              errorText={getFieldError(
                GDS_ITEM_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.SIZE
              )}
            />
          </Grid>

          <Grid item>
            <Button type="sumbit" disabled={isSubmitDisabled()} fullWidth>
              <TextComponent tid="GDS_ITEM.ADMIN.ITEM_UPDATE_DATA.FORM.SUBMIT" />
            </Button>
          </Grid>
        </Grid>
      </Box>
    </form>
  );
};
