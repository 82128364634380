import { GDS_ITEM_ADMIN_ITEM_DATA_DATA_NAME } from "./gds-item-admin-item-data.constant";

import { convertGdsItemStatus } from "../../lib/common/gds-item/gds-item.convert";

import { convertDatetime } from "../../lib/common/convert/convert.core";
import { GDS_SIZE_VIEW_VALUE } from "../../lib/common/gds/gds.type";

export const convertGdsItemAdminItemData = (data) => ({
  [GDS_ITEM_ADMIN_ITEM_DATA_DATA_NAME.CREATE_DATE]: convertDatetime(
    data[GDS_ITEM_ADMIN_ITEM_DATA_DATA_NAME.CREATE_DATE]
  ),
  [GDS_ITEM_ADMIN_ITEM_DATA_DATA_NAME.UPDATE_DATE]:
    data[GDS_ITEM_ADMIN_ITEM_DATA_DATA_NAME.UPDATE_DATE],
  [GDS_ITEM_ADMIN_ITEM_DATA_DATA_NAME.NAME]:
    data[GDS_ITEM_ADMIN_ITEM_DATA_DATA_NAME.NAME],
  [GDS_ITEM_ADMIN_ITEM_DATA_DATA_NAME.ID]:
    data[GDS_ITEM_ADMIN_ITEM_DATA_DATA_NAME.ID],
  [GDS_ITEM_ADMIN_ITEM_DATA_DATA_NAME.GDS]:
    data[GDS_ITEM_ADMIN_ITEM_DATA_DATA_NAME.GDS],
  [GDS_ITEM_ADMIN_ITEM_DATA_DATA_NAME.SIZE]:
    data[GDS_ITEM_ADMIN_ITEM_DATA_DATA_NAME.SIZE],
  [GDS_ITEM_ADMIN_ITEM_DATA_DATA_NAME.ORDER]:
    data[GDS_ITEM_ADMIN_ITEM_DATA_DATA_NAME.ORDER],
  [GDS_ITEM_ADMIN_ITEM_DATA_DATA_NAME.STATUS]: convertGdsItemStatus(
    data[GDS_ITEM_ADMIN_ITEM_DATA_DATA_NAME.STATUS]
  ),
  [GDS_ITEM_ADMIN_ITEM_DATA_DATA_NAME.AMOUNT]:
    data[GDS_ITEM_ADMIN_ITEM_DATA_DATA_NAME.AMOUNT],

  [GDS_ITEM_ADMIN_ITEM_DATA_DATA_NAME.UPDATE_DATE]: convertDatetime(
    data[GDS_ITEM_ADMIN_ITEM_DATA_DATA_NAME.UPDATE_DATE]
  ),
});
