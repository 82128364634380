export const USER_ADMIN_ITEM_UPDATE_DATA_STORE_NAME =
  'USER_ADMIN_ITEM_UPDATE_DATA';

export const USER_ADMIN_ITEM_UPDATE_DATA_API = {
  CHANGE_DATA: {
    ENDPOINT: (userId) => `/user/admin/user/${userId}`,
    TYPE: 'PATCH',
  },
};

export const USER_ADMIN_ITEM_UPDATE_DATA_ACTION_TYPE = {
  FORM_PENDING: 'USER_ADMIN_ITEM_UPDATE_DATA_ACTION_TYPE.FORM_PENDING',
  FORM_SUCCESS: 'USER_ADMIN_ITEM_UPDATE_DATA_ACTION_TYPE.FORM_SUCCESS',
  FORM_ERROR: 'USER_ADMIN_ITEM_UPDATE_DATA_ACTION_TYPE.FORM_ERROR',
  FORM_RESET: 'USER_ADMIN_ITEM_UPDATE_DATA_ACTION_TYPE.FORM_RESET',
};

export const USER_ADMIN_ITEM_UPDATE_DATA_DATA_NAME = {
  PASSWORD: 'password',
  EMAIL: 'email',
  ROLE: 'role',
  TIMEZONE: 'timezone',
  FULL_NAME: 'fullName',
  PHONE: 'phone',
  CITY: 'city',
  REGION: 'region',
  POST_OFFICE: 'postOffice',
};
