export const GDS_ITEM_ADMIN_LIST_ROUTE_PATH = "/admin/gds/:gdsId/item";

export const GDS_ITEM_ADMIN_LIST_ROUTE_PATH_DYNAMIC = (gdsId) =>
  `/admin/gds/${gdsId}/item`;

export const GDS_ITEM_ADMIN_LIST_STORE_NAME = "GDS_ITEM_ADMIN_LIST";

export const GDS_ITEM_ADMIN_LIST_API = {
  GDS_ITEM_ADMIN_LIST: {
    ENDPOINT: (gdsId) => `/gds/${gdsId}/item/admin`,
    TYPE: "GET",
  },
};

export const GDS_ITEM_ADMIN_LIST_ACTION_TYPE = {
  REQUEST_PENDING: "GDS_ITEM_ADMIN_LIST_ACTION_TYPE.REQUEST_PENDING",
  REQUEST_SUCCESS: "GDS_ITEM_ADMIN_LIST_ACTION_TYPE.REQUEST_SUCCESS",
  REQUEST_ERROR: "GDS_ITEM_ADMIN_LIST_ACTION_TYPE.REQUEST_ERROR",
};

export const GDS_ITEM_ADMIN_LIST_DATA_NAME = {
  ID: "id",
  ORDER: "order",
  STATUS: "status",

  AMOUNT: "amount",
  SIZE: "size",
  NAME: "name",
  CREATE_DATE: "createDate",
  UPDATE_DATE: "updateDate",
  ID: "id",
  GDS_ID: "gdsId",

  LIST: "list",
  SKIP: "skip",
  TAKE: "take",
  SORT_ORDER: "sortOrder",
  SORT_ID: "sortId",
};

export const GDS_SIZE_VIEW_VALUE = {
  1: "S",
  2: "S-M",
  3: "M",
  4: "M-L",
  5: "L",
  6: "L-XL",
  7: "XL",
  8: "2XL",
};
