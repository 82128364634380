import ExcelJS from "exceljs";
import { convertDataGdsList, convertDateToUkrainianFormat } from "./excel.convert";

export function createExcelFile(gdsList, date, price) {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("Видаткова");
  const gdsListLength = gdsList?.length || 0;
  const convertDate = convertDateToUkrainianFormat(date);
  const convertGdsList = convertDataGdsList(gdsList);

  // convertGdsList должен соответствовать формату с ['№', 'Товар'...]
  const data = [
    [`Видаткова накладна № 10 від ${convertDate}`],
    ['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''],
    ['Постачальник:', '', 'АРГУНОВ КОСТЯНТИН РОМАНОВИЧ \nп/р UA803052990000026004031214198 у банку ПАТ "ПРИВАТБАНК", м.Київ,\nУкраїна, 61144,ХАРКІВСЬКА ОБЛАСТЬ, М. ХАРКІВ, САЛТІВСЬКИЙ РАЙОН ВУЛ.ГВАРДІЙЦІВ-ШИРОНІНЦІВ, будинок № 81, кв.81,\nтел.: +38 (095) 369-79-67,\nкод за ДРФО 3619304955'],
    ['Покупець:', "", 'Фізична особа'],
    ['Договір:', "", 'Основний'],
    ['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''],
    ['№', 'Товар', "", "", "", "", "", "", "", 'Кількість', "", "", 'Ціна', "", 'Сума', ""],
    ['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''],
    ...convertGdsList,
    ['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''],
    [`Всього найменувань ${gdsListLength}, на суму ${price} грн.`]
  ];

  function applyStyles(cell, options) {
    cell.font = options?.font || {};
    cell.alignment = options?.alignment || {};
    cell.width = options?.width || {};
    cell.fill = options?.fill || {};
  }

  function applyGdsHeaderStyles(column) {
    applyStyles(worksheet.getCell(column + "7"), {
      font: { bold: true },
      alignment: { vertical: "middle", horizontal: "center" },
    });
  }

  // Вставляем данные и применяем стили
  data.forEach((row, rowIndex) => {
    const newRow = worksheet.addRow(row);
    newRow.eachCell((cell, colNumber) => {
      applyStyles(cell, { font: { name: "Arial", size: 9 } });
    });
  });

  // стилизация хедера товаров
  ["A", "B", "J", "M", "O"].forEach((column) => {
    applyGdsHeaderStyles(column);
  });

  applyStyles(worksheet.getCell("A1"), { font: { size: 14, bold: true } });
  applyStyles(worksheet.getCell("A3"), {
    font: { underline: true },
    alignment: { vertical: "middle" },
  });
  applyStyles(worksheet.getCell("A4"), { font: { underline: true } });

  applyStyles(
    worksheet.getCell(`A${10 + gdsListLength}:D${10 + gdsListLength}`),
    { font: { bold: true } }
  );
  // Основная информация
  worksheet.mergeCells("A1:P1");
  worksheet.mergeCells("C3:P3");
  worksheet.mergeCells("A3:B3");
  worksheet.mergeCells("A4:B4");
  worksheet.mergeCells("A5:B5");
  worksheet.mergeCells("C4:P4");
  worksheet.mergeCells("C5:P5");
  worksheet.mergeCells(`A${10 + gdsListLength}:P${10 + gdsListLength}`);

  // Хедер для товаров
  worksheet.mergeCells("A7:A8");
  worksheet.mergeCells("B7:I8");
  worksheet.mergeCells("J7:L8");
  worksheet.mergeCells("M7:N8");
  worksheet.mergeCells("O7:P8");

  // Товары
  gdsList.forEach((e, index) => {
    applyStyles(worksheet.getCell(`A${9 + index}`), {
      alignment: { vertical: "middle", horizontal: "center" },
    });
    applyStyles(worksheet.getCell(`J${9 + index}`), {
      alignment: { vertical: "middle", horizontal: "right" },
    });
    applyStyles(worksheet.getCell(`M${9 + index}`), {
      alignment: { vertical: "middle", horizontal: "right" },
    });
    applyStyles(worksheet.getCell(`O${9 + index}`), {
      alignment: { vertical: "middle", horizontal: "right" },
    });

    worksheet.mergeCells(`B${9 + index}:I${9 + index}`);
    worksheet.mergeCells(`J${9 + index}:L${9 + index}`);
    worksheet.mergeCells(`M${9 + index}:N${9 + index}`);
    worksheet.mergeCells(`O${9 + index}:P${9 + index}`);
  });

  // Сохраняем документ в файл
  return workbook.xlsx
    .writeBuffer()
    .then((data) => {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheet.sheet",
      });

      const formData = new FormData();
      formData.append("file", blob, `${date}.xlsx`);

      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = `${date}.xlsx`;
      anchor.click();
      window.URL.revokeObjectURL(url);

      return formData;
    })
    .catch((error) => {
      console.log("Произошла ошибка при создании файла:", error);
      throw error;
    });
}
