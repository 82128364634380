import Box from "@mui/material/Box";

import { TextComponent } from "../../../lib/common/text";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { ModalConfirmComponent } from "../../../lib/common/modal-confirm/modal-confirm.component";

export const PurchaseAdminItemPaymentConfirmFormComponent = (props) => {
  const {
    handleSubmit,
    errors,
    touched,
    isValid,
    submitForm,
    pageLoading,
    isSuccess,
    isError,
    isPending,
    isDisabled,
  } = props;

  const isFieldError = (name) => {
    return errors[name] && touched[name] && errors[name];
  };

  const getFieldError = (name) => isFieldError(name) && errors[name];

  const isSubmitDisabled = () => {
    if (isDisabled) {
      return true;
    }

    if (isError) {
      return false;
    }

    if (isPending) {
      return true;
    }

    // if (JSON.stringify(touched) === '{}') {
    //   return true;
    // }

    if (!isValid || isSuccess || pageLoading) {
      return true;
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box sx={{ pt: 4 }}>
        <Grid spacing={3} container>
          <Grid item>
            <ModalConfirmComponent
              action={submitForm}
              disabled={isSubmitDisabled()}
            >
              <Button disabled={isSubmitDisabled()} fullWidth>
                <TextComponent tid="PURCHASE.ADMIN.PAYMENT.CONFIRM.FORM.SUBMIT" />
              </Button>
            </ModalConfirmComponent>
          </Grid>
        </Grid>
      </Box>
    </form>
  );
};
