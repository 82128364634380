import React from "react";

import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";

import { FieldTextComponent } from "../../../lib/common/field-text/field-text.component";
import { FieldSelectComponent } from "../../../lib/common/field-select/field-select.component";

import { TextComponent } from "../../../lib/common/text";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { GDS_ADMIN_CREATE_DATA_NAME } from "../gds-admin-create.constant";

import { FieldFileContainer } from "../../../lib/common/field-file/field-file.container";
import {
  GDS_CATEGORY_OPTION,
  GDS_TAG_OPTION,
  GDS_TYPE_OPTION,
  GDS_SIZE_OPTION,
} from "../../../lib/common/gds/gds.type";
import { FormControlLabel, FormGroup, Paper, Switch } from "@mui/material";

export const GdsAdminCreateFormComponent = (props) => {
  const [isAmountStatus, setOpenAmountStatus] = React.useState();

  const {
    handleChange,
    handleBlur,
    handleSubmit,
    values,
    errors,
    touched,
    isValid,
    isSubmitting,
    setFieldValue,

    pageLoading,
    isSuccess,
    isError,
    isPending,
  } = props;

  const isFieldError = (name) => {
    return errors[name] && touched[name] && errors[name];
  };

  const getFieldError = (name) => isFieldError(name) && errors[name];

  const isSubmitDisabled = () => {
    if (isError) {
      return false;
    }

    if (isPending) {
      return true;
    }

    if (JSON.stringify(touched) === "{}") {
      return true;
    }

    if (!isValid || isSuccess || pageLoading) {
      return true;
    }
    return false;
  };

  const hanldePreviewChange = (v) => {
    setFieldValue(v);
  };

  const handleClickAmountStatus = () => {
    setOpenAmountStatus((prev) => {
      if (!prev === false) {
        setFieldValue(GDS_ADMIN_CREATE_DATA_NAME.AMOUNT, 0);
      }
      return !prev;
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box sx={{ pt: 4 }}>
        <Grid spacing={3} container>
          <Grid item>
            <FieldTextComponent
              type="number"
              label={<TextComponent tid="GDS.ADMIN.CREATE.FORM.ORDER" />}
              name={GDS_ADMIN_CREATE_DATA_NAME.ORDER}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values[GDS_ADMIN_CREATE_DATA_NAME.ORDER]}
              error={isFieldError(GDS_ADMIN_CREATE_DATA_NAME.ORDER)}
              fullWidth
              errorText={getFieldError(GDS_ADMIN_CREATE_DATA_NAME.ORDER)}
            />
          </Grid>
          <Grid item>
            <FieldSelectComponent
              option={GDS_CATEGORY_OPTION}
              label={<TextComponent tid="GDS.ADMIN.CREATE.FORM.CATEGORY" />}
              name={GDS_ADMIN_CREATE_DATA_NAME.CATEGORY}
              onChange={handleChange}
              setFieldValue={setFieldValue}
              onBlur={handleBlur}
              value={values[GDS_ADMIN_CREATE_DATA_NAME.CATEGORY]}
              error={isFieldError(GDS_ADMIN_CREATE_DATA_NAME.CATEGORY)}
              fullWidth
              errorText={getFieldError(GDS_ADMIN_CREATE_DATA_NAME.CATEGORY)}
            />
          </Grid>
          <Grid item>
            <FieldSelectComponent
              option={GDS_TYPE_OPTION}
              label={<TextComponent tid="GDS.ADMIN.CREATE.FORM.TYPE" />}
              name={GDS_ADMIN_CREATE_DATA_NAME.TYPE}
              onChange={handleChange}
              setFieldValue={setFieldValue}
              onBlur={handleBlur}
              value={values[GDS_ADMIN_CREATE_DATA_NAME.TYPE]}
              error={isFieldError(GDS_ADMIN_CREATE_DATA_NAME.TYPE)}
              fullWidth
              errorText={getFieldError(GDS_ADMIN_CREATE_DATA_NAME.TYPE)}
            />
          </Grid>
          <Grid item>
            <FieldTextComponent
              type="text"
              label={<TextComponent tid="GDS.ADMIN.CREATE.FORM.TITLE" />}
              name={GDS_ADMIN_CREATE_DATA_NAME.TITLE}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values[GDS_ADMIN_CREATE_DATA_NAME.TITLE]}
              error={isFieldError(GDS_ADMIN_CREATE_DATA_NAME.TITLE)}
              fullWidth
              errorText={getFieldError(GDS_ADMIN_CREATE_DATA_NAME.TITLE)}
            />
          </Grid>
          <Grid item>
            <FieldTextComponent
              type="text"
              label={<TextComponent tid="GDS.ADMIN.CREATE.FORM.DESCRIPTION" />}
              name={GDS_ADMIN_CREATE_DATA_NAME.DESCRIPTION}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values[GDS_ADMIN_CREATE_DATA_NAME.DESCRIPTION]}
              error={isFieldError(GDS_ADMIN_CREATE_DATA_NAME.DESCRIPTION)}
              fullWidth
              multiline
              rows={1}
              errorText={getFieldError(GDS_ADMIN_CREATE_DATA_NAME.DESCRIPTION)}
            />
          </Grid>
          <Grid item>
            <FieldTextComponent
              type="text"
              label={<TextComponent tid="GDS.ADMIN.CREATE.FORM.BODY" />}
              name={GDS_ADMIN_CREATE_DATA_NAME.BODY}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values[GDS_ADMIN_CREATE_DATA_NAME.BODY]}
              error={isFieldError(GDS_ADMIN_CREATE_DATA_NAME.BODY)}
              fullWidth
              multiline
              rows={1}
              errorText={getFieldError(GDS_ADMIN_CREATE_DATA_NAME.BODY)}
            />
          </Grid>
          <Grid item>
            <FieldTextComponent
              type="number"
              label={<TextComponent tid="GDS.ADMIN.CREATE.FORM.WEIGHT" />}
              name={GDS_ADMIN_CREATE_DATA_NAME.WEIGHT}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values[GDS_ADMIN_CREATE_DATA_NAME.WEIGHT]}
              error={isFieldError(GDS_ADMIN_CREATE_DATA_NAME.WEIGHT)}
              fullWidth
              errorText={getFieldError(GDS_ADMIN_CREATE_DATA_NAME.WEIGHT)}
            />
          </Grid>
          <Grid item>
            <FieldSelectComponent
              option={GDS_TAG_OPTION}
              label={<TextComponent tid="GDS.ADMIN.CREATE.FORM.TAG" />}
              name={GDS_ADMIN_CREATE_DATA_NAME.TAG}
              onChange={handleChange}
              multiple
              setFieldValue={setFieldValue}
              onBlur={handleBlur}
              value={values[GDS_ADMIN_CREATE_DATA_NAME.TAG]}
              error={isFieldError(GDS_ADMIN_CREATE_DATA_NAME.TAG)}
              fullWidth
              errorText={getFieldError(GDS_ADMIN_CREATE_DATA_NAME.TAG)}
            />
          </Grid>

          <Grid item>
            <FieldTextComponent
              type="number"
              label={<TextComponent tid="GDS.ADMIN.CREATE.FORM.PRICE" />}
              name={GDS_ADMIN_CREATE_DATA_NAME.PRICE}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values[GDS_ADMIN_CREATE_DATA_NAME.PRICE]}
              error={isFieldError(GDS_ADMIN_CREATE_DATA_NAME.PRICE)}
              fullWidth
              errorText={getFieldError(GDS_ADMIN_CREATE_DATA_NAME.PRICE)}
            />
          </Grid>

          <Grid item>
            <Paper>
              <FormGroup>
                <FormControlLabel
                  sx={{ py: 1, px: 2, m: 0 }}
                  name={GDS_ADMIN_CREATE_DATA_NAME.AMOUNT_STATUS}
                  control={
                    <Switch
                      checked={values[GDS_ADMIN_CREATE_DATA_NAME.AMOUNT_STATUS]}
                      onChange={(e) => {
                        handleChange(e);
                        handleClickAmountStatus();
                      }}
                    />
                  }
                  label={
                    <TextComponent tid="GDS.ADMIN.CREATE.FORM.IS_AMOUNT" />
                  }
                />
              </FormGroup>
            </Paper>
          </Grid>
          <Grid item>
            <FieldTextComponent
              disabled={!isAmountStatus}
              type="number"
              label={<TextComponent tid="GDS.ADMIN.CREATE.FORM.AMOUNT" />}
              name={GDS_ADMIN_CREATE_DATA_NAME.AMOUNT}
              onChange={handleChange}
              onBlur={handleBlur}
              value={
                isAmountStatus ? values[GDS_ADMIN_CREATE_DATA_NAME.AMOUNT] : 0
              }
              error={isFieldError(GDS_ADMIN_CREATE_DATA_NAME.AMOUNT)}
              fullWidth
              errorText={getFieldError(GDS_ADMIN_CREATE_DATA_NAME.AMOUNT)}
            />
          </Grid>
          <Grid item>
            <FieldTextComponent
              type="number"
              label={<TextComponent tid="GDS.ADMIN.CREATE.FORM.PPO" />}
              name={GDS_ADMIN_CREATE_DATA_NAME.PPO}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values[GDS_ADMIN_CREATE_DATA_NAME.PPO]}
              error={isFieldError(GDS_ADMIN_CREATE_DATA_NAME.PPO)}
              fullWidth
              errorText={getFieldError(GDS_ADMIN_CREATE_DATA_NAME.PPO)}
            />
          </Grid>
          <Grid item>
            <FieldTextComponent
              type="text"
              label={<TextComponent tid="GDS.ADMIN.CREATE.FORM.URL" />}
              name={GDS_ADMIN_CREATE_DATA_NAME.URL}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values[GDS_ADMIN_CREATE_DATA_NAME.URL]}
              error={isFieldError(GDS_ADMIN_CREATE_DATA_NAME.URL)}
              fullWidth
              errorText={getFieldError(GDS_ADMIN_CREATE_DATA_NAME.URL)}
            />
          </Grid>

          <Grid item>
            <FieldFileContainer
              label={<TextComponent tid="GDS.ADMIN.CREATE.FORM.FILE_PREVIEW" />}
              name={GDS_ADMIN_CREATE_DATA_NAME.FILE_PREVIEW}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values[GDS_ADMIN_CREATE_DATA_NAME.FILE_PREVIEW]}
              error={isFieldError(GDS_ADMIN_CREATE_DATA_NAME.FILE_PREVIEW)}
              errorText={getFieldError(GDS_ADMIN_CREATE_DATA_NAME.FILE_PREVIEW)}
            />
          </Grid>
          <Grid item>
            <FieldFileContainer
              label={<TextComponent tid="GDS.ADMIN.CREATE.FORM.FILE_LIST" />}
              name={GDS_ADMIN_CREATE_DATA_NAME.FILE_LIST}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values[GDS_ADMIN_CREATE_DATA_NAME.FILE_LIST]}
              error={isFieldError(GDS_ADMIN_CREATE_DATA_NAME.FILE_LIST)}
              errorText={getFieldError(GDS_ADMIN_CREATE_DATA_NAME.FILE_LIST)}
            />
          </Grid>

          <Grid item>
            <Divider />
          </Grid>

          <Grid item>
            <Button type="sumbit" disabled={isSubmitDisabled()} fullWidth>
              <TextComponent tid="GDS.ADMIN.CREATE.FORM.SUBMIT" />
            </Button>
          </Grid>
        </Grid>
      </Box>
    </form>
  );
};
