import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";

import { TextComponent } from "../../text";

export const FieldFileFormComponent = (props) => {
  const { onFileAdd } = props;

  return (
    <Paper
      fullWidth
      sx={{ width: "100%", borderColor: "rgba(255, 255, 255, 0.12)" }}
    >
      <Button
        fullWidth
        variant="grey"
        color="black"
        size="small"
        component="label"
        sx={{ height: "46px" }}
      >
        <TextComponent variant="fieldText" tid="FILE.FIELD.SUBMIT" />
        <input onChange={onFileAdd} value="" type="file" hidden accept="*" />
      </Button>
    </Paper>
  );
};
