import { GDS_ITEM_ADMIN_ITEM_UPDATE_DATA_DATA_NAME } from "./gds-item-admin-item-update-data.constant";
import { convertSelectFieldValue } from "../../lib/common/field-select/field-select.convert";

export const convertGdsItemAdminItemUpdateDataFormData = (data) => ({
  [GDS_ITEM_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.ORDER]: Number(
    data[GDS_ITEM_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.ORDER]
  ),
  [GDS_ITEM_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.AMOUNT]: Number(
    data[GDS_ITEM_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.AMOUNT]
  ),
  [GDS_ITEM_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.SIZE]: Number(
    data[GDS_ITEM_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.SIZE].id
  ),
  [GDS_ITEM_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.STATUS]: convertSelectFieldValue(
    data[GDS_ITEM_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.STATUS]
  ),
});
