import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import { UserAdminItemDataContainer } from "../user-admin-item-data/user-admin-item-data.container";
import { UserAdminItemUpdateDataContainer } from "../user-admin-item-update-data/user-admin-item-update-data.container";

export function UserAdminItemPage() {
  return (
    <Container maxWidth="sm">
      <Grid spacing={6} container>
        <Grid item>
          <UserAdminItemDataContainer />
        </Grid>
        <Grid item>
          <UserAdminItemUpdateDataContainer />
        </Grid>
      </Grid>
    </Container>
  );
}
