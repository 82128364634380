import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import InputMask from "react-input-mask";

import { TextComponent } from "../../../lib/common/text";
import { FieldTextComponent } from "../../../lib/common/field-text/field-text.component";
import { PURCHASE_CUSTOMER_ITEM_UPDATE_DATA_DATA_NAME } from "../purchase-customer-item-update-data.constant";
import { ModalConfirmComponent } from "../../../lib/common/modal-confirm/modal-confirm.component";
import { FieldSelectComponent } from "../../../lib/common/field-select/field-select.component";
import { REGION_OPTION } from "../../account-settings-update-user-data/account-settings-update-user-data.constant";

export const PurchaseCustomerItemUpdateDataFormComponent = (props) => {
  const {
    handleChange,
    handleBlur,
    handleSubmit,
    values,
    errors,
    touched,
    isValid,
    submitForm,
    pageLoading,
    isSuccess,
    isError,
    isPending,
    isErrorStatus,
  } = props;

  const isFieldError = (name) => {
    return errors[name] && touched[name] && errors[name];
  };

  const getFieldError = (name) => isFieldError(name) && errors[name];

  const isSubmitDisabled = () => {
    if (isErrorStatus) {
      return true;
    }

    if (isError) {
      return false;
    }

    if (isPending) {
      return true;
    }

    if (JSON.stringify(touched) === "{}") {
      return true;
    }

    if (!isValid || isSuccess || pageLoading) {
      return true;
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box sx={{ pt: 4 }}>
        <Grid spacing={3} container>
          <Grid item>
            <FieldTextComponent
              label={
                <TextComponent tid="PURCHASE.ADMIN.UPDATE.DATA.FORM.NOTES" />
              }
              name={PURCHASE_CUSTOMER_ITEM_UPDATE_DATA_DATA_NAME.NOTES}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values[PURCHASE_CUSTOMER_ITEM_UPDATE_DATA_DATA_NAME.NOTES]}
              error={isFieldError(
                PURCHASE_CUSTOMER_ITEM_UPDATE_DATA_DATA_NAME.NOTES
              )}
              fullWidth
              errorText={getFieldError(
                PURCHASE_CUSTOMER_ITEM_UPDATE_DATA_DATA_NAME.NOTES
              )}
            />
          </Grid>
          <Grid item>
            <FieldTextComponent
              label={
                <TextComponent tid="PURCHASE.ADMIN.UPDATE.DATA.FORM.FULL_NAME" />
              }
              name={PURCHASE_CUSTOMER_ITEM_UPDATE_DATA_DATA_NAME.FULL_NAME}
              onChange={handleChange}
              onBlur={handleBlur}
              value={
                values[PURCHASE_CUSTOMER_ITEM_UPDATE_DATA_DATA_NAME.FULL_NAME]
              }
            />
          </Grid>
          <Grid item>
            <InputMask
              mask="+380 (99) 999 99 99"
              type="phone"
              placeholder="+380"
              maskChar={null}
              id={PURCHASE_CUSTOMER_ITEM_UPDATE_DATA_DATA_NAME.PHONE}
              name={PURCHASE_CUSTOMER_ITEM_UPDATE_DATA_DATA_NAME.PHONE}
              label={
                <TextComponent tid="PURCHASE.ADMIN.UPDATE.DATA.FORM.PHONE" />
              }
              value={values[PURCHASE_CUSTOMER_ITEM_UPDATE_DATA_DATA_NAME.PHONE]}
              onChange={handleChange}
              onBlur={handleBlur}
              error={isFieldError(
                PURCHASE_CUSTOMER_ITEM_UPDATE_DATA_DATA_NAME.PHONE
              )}
              fullWidth
              disableUnderline
              errorText={getFieldError(
                PURCHASE_CUSTOMER_ITEM_UPDATE_DATA_DATA_NAME.PHONE
              )}
            >
              {(inputProps) => <FieldTextComponent {...inputProps} />}
            </InputMask>
          </Grid>
          <Grid item>
            <FieldTextComponent
              label={
                <TextComponent tid="PURCHASE.ADMIN.UPDATE.DATA.FORM.CITY" />
              }
              name={PURCHASE_CUSTOMER_ITEM_UPDATE_DATA_DATA_NAME.CITY}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values[PURCHASE_CUSTOMER_ITEM_UPDATE_DATA_DATA_NAME.CITY]}
            />
          </Grid>
          <Grid item>
            <FieldSelectComponent
              option={REGION_OPTION}
              label={
                <TextComponent tid="ACCOUNT.UPDATE_USER_DATA.FORM.REGION" />
              }
              name={PURCHASE_CUSTOMER_ITEM_UPDATE_DATA_DATA_NAME.REGION}
              onChange={handleChange}
              onBlur={handleBlur}
              error={isFieldError(
                PURCHASE_CUSTOMER_ITEM_UPDATE_DATA_DATA_NAME.REGION
              )}
              defaultValue={
                values[PURCHASE_CUSTOMER_ITEM_UPDATE_DATA_DATA_NAME.REGION]
              }
              fullWidth
              errorText={getFieldError(
                PURCHASE_CUSTOMER_ITEM_UPDATE_DATA_DATA_NAME.REGION
              )}
            />
          </Grid>
          <Grid item>
            <FieldTextComponent
              label={
                <TextComponent tid="PURCHASE.ADMIN.UPDATE.DATA.FORM.POST_OFFICE" />
              }
              name={PURCHASE_CUSTOMER_ITEM_UPDATE_DATA_DATA_NAME.POST_OFFICE}
              onChange={handleChange}
              onBlur={handleBlur}
              value={
                values[PURCHASE_CUSTOMER_ITEM_UPDATE_DATA_DATA_NAME.POST_OFFICE]
              }
            />
          </Grid>
          <Grid item>
            <ModalConfirmComponent
              disabled={isSubmitDisabled()}
              action={submitForm}
            >
              <Button disabled={isErrorStatus} fullWidth>
                <TextComponent tid="PURCHASE.ADMIN.UPDATE.DATA.FORM.SUBMIT" />
              </Button>
            </ModalConfirmComponent>
          </Grid>
        </Grid>
      </Box>
    </form>
  );
};
