import VALIDATION from "./validation.json";
import AUTH from "./auth.json";
import ACCOUNT from "./account.json";
import ERROR from "./error.json";
import NAVIGATION from "./navigation.json";
import USER from "./user.json";
import PURCHASE from "./purchase.json";
import FILE from "./file.json";
import GDS from "./gds.json";
import PAYMENT from "./payment.json";
import MODAL from "./modal.json";
import NOTICE from "./notice.json";
import CONVERT from "./convert.json";
import GDS_ITEM from "./gds-item.json";

export const RU = {
  translation: {
    VALIDATION,
    AUTH,
    ACCOUNT,
    ERROR,
    NAVIGATION,
    USER,
    PURCHASE,
    FILE,
    GDS,
    PAYMENT,
    MODAL,
    NOTICE,
    CONVERT,
    GDS_ITEM,
  },
};
