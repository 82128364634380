import React from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  getRequestData,
  isRequestError,
  isRequestPending,
  isRequestSuccess,
  isRequestUpdatePending,
} from "../../main/store/store.service";
import { NAVIGATION_STORE_NAME } from "../../lib/common/navigation";
import { PurchaseListUploadComponent } from "./purchase-list-upload.component";
import { PURCHASE_ADMIN_LIST_STORE_NAME } from "../purchase-admin-list/purchase-admin-list.constant";
import { getPurchaseAdminList } from "../purchase-admin-list/purchase-admin-list.action";
import { PURCHASE_ADMIN_ITEM_TIME_CREATE_DATA_NAME } from "./purchase-list-upload.constant";
import { purchaseListUploadFormValidation } from "./purchase-list-upload.validation";
import {
  generateProductSizeReport,
  toConvertArrayJsonToXlsx,
} from "../../lib/common/xlsx/xlsx.core";

export function PurchaseListUploadContainer() {
  const dispatch = useDispatch();
  const { state, pageLoading, purchaseState } = useSelector((state) => ({
    state: state[PURCHASE_ADMIN_LIST_STORE_NAME],
    purchaseState: state[PURCHASE_ADMIN_LIST_STORE_NAME],
    pageLoading: state[NAVIGATION_STORE_NAME].pageLoading,
  }));

  React.useEffect(() => {
    dispatch(getPurchaseAdminList());
  }, []);

  const getInitialValue = () => {
    return {
      [PURCHASE_ADMIN_ITEM_TIME_CREATE_DATA_NAME.FROM_TIME]: null,
      [PURCHASE_ADMIN_ITEM_TIME_CREATE_DATA_NAME.TO_TIME]: null,
    };
  };

  const data = getRequestData(state.request);

  const handleUploadPurchase = (dateRange, type) => {
    const fromTime = new Date(dateRange.fromTime);
    const toTime = new Date(dateRange.toTime);

    const filteredData = data?.list?.filter((item, index) => {
      const createDate = new Date(item.createDateStock);
      return (
        createDate >= fromTime &&
        createDate <= toTime &&
        item?.payment?.confirm === true
      );
    });

    if (type === "purchase") {
      return toConvertArrayJsonToXlsx(filteredData);
    }

    if (type === "gds") {
      return generateProductSizeReport(filteredData);
    }
  };

  return (
    <PurchaseListUploadComponent
      handleUploadPurchase={handleUploadPurchase}
      initialValue={getInitialValue()}
      isPending={isRequestPending(state.request)}
      isUpdating={isRequestUpdatePending(state.request)}
      isError={isRequestError(state.request)}
      isSuccess={isRequestSuccess(state.request)}
      pageLoading={pageLoading}
      validation={purchaseListUploadFormValidation}
      data={data}
    />
  );
}
