import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { PurchaseAdminItemPaymentConfirmComponent } from "./purchase-admin-item-payment-confirm.component";
import { purchaseAdminItemPaymentConfirmFormValidation } from "./purchase-admin-item-payment-confirm.validation";
import { convertPurchaseAdminItemPaymentConfirmFormData } from "./purchase-admin-item-payment-confirm.convert";
import {
  resetPurchaseAdminItemPaymentConfirmFormState,
  setPurchaseAdminItemFileLinkConfirmForm,
  uploadPurchaseAdminItemFileConfirmForm,
  uploadPurchaseAdminItemPaymentConfirmForm,
  uploadPurchaseAdminItemSetInvoiceLink,
} from "./purchase-admin-item-payment-confirm.action";
import {
  PURCHASE_ADMIN_ITEM_PAYMENT_CONFIRM_STORE_NAME,
  PURCHASE_ADMIN_ITEM_PAYMENT_CONFIRM_DATA_NAME,
  PURCHASE_ADMIN_ITEM_PAYMENT_CONFIRM_API,
} from "./purchase-admin-item-payment-confirm.constant";
import { NAVIGATION_STORE_NAME } from "../../lib/common/navigation";
import {
  getRequestData,
  getRequestErrorMessage,
  isRequestError,
  isRequestPending,
  isRequestSuccess,
} from "../../main/store/store.service";
import { PURCHASE_ADMIN_ITEM_STORE_NAME } from "../purchase-admin-item/purchase-admin-item.constant";
import { createExcelFile } from "../../lib/common/xlsx/excel";
import { PURCHASE_STATUS_TYPE } from "../../lib/common/purchase/purchase.type";

export function PurchaseAdminItemPaymentConfirmContainer() {
  let { purchaseId } = useParams();
  const dispatch = useDispatch();
  const { state, pageLoading, data } = useSelector((state) => ({
    state: state[PURCHASE_ADMIN_ITEM_PAYMENT_CONFIRM_STORE_NAME],
    pageLoading: state[NAVIGATION_STORE_NAME].pageLoading,
    data: state[PURCHASE_ADMIN_ITEM_STORE_NAME],
  }));

  const purchaseData = getRequestData(data.request);
  const isDisabled = purchaseData?.status.id === PURCHASE_STATUS_TYPE.DELETED;

  React.useEffect(() => {
    dispatch(resetPurchaseAdminItemPaymentConfirmFormState());
  }, []);

  console.log("purchaseData", purchaseData);

  const purchaseAdminItemPaymentConfirmFormSendData = async (values) => {
    if (isDisabled) {
      return;
    }

    const data = convertPurchaseAdminItemPaymentConfirmFormData(values);
    data.purchaseId = purchaseId;

    if (purchaseData.invoiceLink) {
      window.open(purchaseData.invoiceLink, "_blank");
      return;
    }
    try {
      const formData = await createExcelFile(
        purchaseData.cart.gdsOrders,
        purchaseData.createDate,
        purchaseData.price
      );

      const linkData = await dispatch(
        uploadPurchaseAdminItemFileConfirmForm(formData, purchaseId)
      );

      dispatch(uploadPurchaseAdminItemSetInvoiceLink(data, linkData?.url));
    } catch (error) {
      console.error("Ошибка при создании Excel-файла или загрузке:", error);
    }

    dispatch(uploadPurchaseAdminItemPaymentConfirmForm(data));
  };

  const getInitialValue = () => {
    return {
      [PURCHASE_ADMIN_ITEM_PAYMENT_CONFIRM_DATA_NAME.ID]: "",
    };
  };

  return (
    <PurchaseAdminItemPaymentConfirmComponent
      isDisabled={isDisabled}
      isPending={isRequestPending(state.form)}
      isError={isRequestError(state.form)}
      isSuccess={isRequestSuccess(state.form)}
      initialValue={getInitialValue()}
      validation={purchaseAdminItemPaymentConfirmFormValidation}
      onSubmitForm={purchaseAdminItemPaymentConfirmFormSendData}
      pageLoading={pageLoading}
      errorMessage={getRequestErrorMessage(state.form)}
    />
  );
}
