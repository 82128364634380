import React from "react";

import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import { Backdrop, CircularProgress } from "@mui/material";

import { TextComponent } from "../../../lib/common/text/text.component";
import { PURCHASE_CUSTOMER_ITEM_DATA_NAME } from "../../purchase-customer-item/purchase-customer-item.constant";

export function PurchaseCustomerItemDataPaymentViewComponent(props) {
  const { data } = props;
  const [isLoading, setIsLoading] = React.useState(false);

  const handlePay = async () => {
    setIsLoading(true);

    try {
      const apiBaseUrl = process.env.REACT_APP_API;

      const response = await fetch(
        `${apiBaseUrl}payment/link/liqpay/${data.id}`
      );
      const result = await response.json();

      if (result.data) {
        window.open(result.data, "_blank");
      } else {
        console.error("No data field found in the response");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading && (
        <Backdrop
          sx={{
            color: "#121212",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <Grid container spacing={4} sx={{ pt: 4 }}>
        <Grid item>
          <Grid container spacing={3}>
            <Grid item>
              <TextComponent
                gutterBottom
                variant="dataViewTitle"
                tid="PURCHASE.CUSTOMER.ITEM_PAYMENT.PRICE"
              />
              <TextComponent
                tid="PURCHASE.CUSTOMER.ITEM_PAYMENT.PRICE_DATA"
                tvalue={{
                  price: data[PURCHASE_CUSTOMER_ITEM_DATA_NAME.PRICE],
                }}
                variant="dataViewContent"
              />
            </Grid>

            <Grid item>
              <TextComponent
                gutterBottom
                variant="dataViewTitle"
                tid="PURCHASE.CUSTOMER.ITEM_PAYMENT.PAID"
              />
              {data[PURCHASE_CUSTOMER_ITEM_DATA_NAME.PAID] ? (
                <TextComponent
                  tid="PURCHASE.CUSTOMER.ITEM_PAYMENT.PAID_DATA"
                  tvalue={{
                    confirm:
                      data[PURCHASE_CUSTOMER_ITEM_DATA_NAME.PAYMENT].confirm,
                    system:
                      data[PURCHASE_CUSTOMER_ITEM_DATA_NAME.PAYMENT].system
                        .text,
                  }}
                  variant="dataViewContent"
                />
              ) : (
                <TextComponent
                  tid="PURCHASE.CUSTOMER.ITEM_PAYMENT.PAID_NONE"
                  variant="dataViewContent"
                />
              )}
            </Grid>
          </Grid>
        </Grid>

        {!data[PURCHASE_CUSTOMER_ITEM_DATA_NAME.IS_PAID] && (
          <React.Fragment>
            <Grid item>
              <Divider />
            </Grid>

            <Grid item>
              <Grid container spacing={2}>
                <Grid item>
                  <TextComponent
                    tid="PURCHASE.CUSTOMER.ITEM_PAYMENT.NEED_PAYMENT_INFO"
                    variant="body1"
                  />
                </Grid>
                <Grid item>
                  <Button onClick={handlePay} fullWidth>
                    <TextComponent tid="PURCHASE.CUSTOMER.ITEM_PAYMENT.PAY_BUTTON" />
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </React.Fragment>
        )}
      </Grid>
    </>
  );
}
