import { PURCHASE_CUSTOMER_ITEM_ROUTE_PATH_DYNAMIC } from "../purchase-customer-item/purchase-customer-item.constant";
import { PURCHASE_CUSTOMER_ITEM_UPDATE_ROUTE_PATH_DYNAMIC } from "../purchase-customer-item-update/purchase-customer-item-update.constant";

export const getPurchaseCustomerItemTabGds = (purchaseId) => [
  {
    id: 0,
    tid: "NAVIGATION.TAB.PURCHASE.CUSTOMER.ITEM",
    path: PURCHASE_CUSTOMER_ITEM_ROUTE_PATH_DYNAMIC({
      purchaseId,
    }),
  },
  {
    id: 1,
    tid: "NAVIGATION.TAB.PURCHASE.CUSTOMER.ITEM_UPDATE",
    path: PURCHASE_CUSTOMER_ITEM_UPDATE_ROUTE_PATH_DYNAMIC({ purchaseId }),
  },
];
