export const typography = {
  fontFamily:
    '"Inter","IBM Plex Sans",-apple-system,BlinkMacSystemFont,"Segoe UI",Inter,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
  fontFamilyCode:
    '"IBM Plex Mono",-apple-system,BlinkMacSystemFont,"Segoe UI",Inter,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
  fontFamilyTagline:
    '"PlusJakartaSans-ExtraBold",-apple-system,BlinkMacSystemFont,"Segoe UI",Inter,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
  fontFamilySystem:
    '"Inter",-apple-system,BlinkMacSystemFont,"Segoe UI",Inter,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
  fontWeightExtraBold: 800,
  heading: {
    fontFamily:
      '"SpaceGrotesk","Inter",-apple-system,BlinkMacSystemFont,"Segoe UI",Inter,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
    fontSize: '22px',
    fontWeight: 700,
    lineHeight: 1,
    color: '#ededed',
  },
  fieldLabel: {
    fontFamily:
      '"Inter","PlusJakartaSans-ExtraBold",-apple-system,BlinkMacSystemFont,"Segoe UI",Inter,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: 1,
    color: '#ededed',
  },
  errorText: {
    fontFamily:
      '"Inter","PlusJakartaSans-ExtraBold",-apple-system,BlinkMacSystemFont,"Segoe UI",Inter,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: 1,
    color: '#EB5757',
  },
  title: {
    fontFamily:
      '"SpaceGrotesk","PlusJakartaSans-ExtraBold",-apple-system,BlinkMacSystemFont,"Segoe UI",Inter,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
    fontSize: '18px',
    fontWeight: 600,
    lineHeight: 1,
    color: '#ededed',
  },
  titleSmall: {
    fontFamily:
      '"SpaceGrotesk","PlusJakartaSans-ExtraBold",-apple-system,BlinkMacSystemFont,"Segoe UI",Inter,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: 1,
    color: '#ededed',
  },
  subtitle: {
    fontFamily:
      '"SpaceGrotesk","IBM Plex Sans",-apple-system,BlinkMacSystemFont,"Segoe UI",Inter,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: 1.57,
  },
  subtext: {
    fontFamily:
      '"Inter","PlusJakartaSans-ExtraBold",-apple-system,BlinkMacSystemFont,"Segoe UI",Inter,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: 1.4,
    color: '#ededed',
  },
  fieldText: {
    fontFamily:
      '"Inter","PlusJakartaSans-ExtraBold",-apple-system,BlinkMacSystemFont,"Segoe UI",Inter,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: 1,
    color: '#ededed',
  },
  button: {
    textTransform: 'initial',
    fontWeight: 700,
    letterSpacing: 0,
    fontFamily:
      '"SpaceGrotesk","IBM Plex Sans",-apple-system,BlinkMacSystemFont,"Segoe UI",Inter,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
    fontSize: '0.875rem',
    lineHeight: 1.75,
  },
  navigationItem: {
    fontSize: '16px',
    lineHeight: 1.5,
    letterSpacing: 0,
    color: '#ededed',
    fontFamily:
      '"SpaceGrotesk","IBM Plex Sans",-apple-system,BlinkMacSystemFont,"Segoe UI",Inter,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
    fontWeight: 500,
  },
  body1: {
    fontSize: '16px',
    lineHeight: 1.5,
    letterSpacing: 0,
    color: '#ededed',
    fontFamily:
      '"Inter","IBM Plex Sans",-apple-system,BlinkMacSystemFont,"Segoe UI",Inter,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
    fontWeight: 400,
  },
  body1Small: {
    fontSize: '14px',
    lineHeight: 1.5,
    letterSpacing: 0,
    color: '#ededed',
    fontFamily:
      '"Inter","IBM Plex Sans",-apple-system,BlinkMacSystemFont,"Segoe UI",Inter,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
    fontWeight: 400,
  },
  body2: {
    fontSize: '16px',
    lineHeight: 1.5,
    color: '#ededed',
    letterSpacing: 0,
    fontFamily:
      '"Inter","IBM Plex Sans",-apple-system,BlinkMacSystemFont,"Segoe UI",Inter,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
    fontWeight: 400,
  },
  caption: {
    display: 'inline-block',
    fontSize: '0.75rem',
    lineHeight: 1.5,
    letterSpacing: 0,
    fontWeight: 700,
    fontFamily:
      '"Inter","IBM Plex Sans",-apple-system,BlinkMacSystemFont,"Segoe UI",Inter,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
  },
  selectOption: {
    fontSize: '14px',
    lineHeight: 1.4,
    letterSpacing: 0,
    color: '#ededed',
    fontFamily:
      '"Inter","IBM Plex Sans",-apple-system,BlinkMacSystemFont,"Segoe UI",Inter,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
    fontWeight: 400,
  },

  htmlFontSize: 16,
  fontSize: 14,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 700,

  overline: {
    fontFamily:
      'Inter,"IBM Plex Sans",-apple-system,BlinkMacSystemFont,"Segoe UI",Inter,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
    fontWeight: 400,
    fontSize: '0.75rem',
    lineHeight: 2.66,
    textTransform: 'uppercase',
  },

  dataViewTitle: {
    fontSize: '14px',
    lineHeight: 1,
    textTransform: 'uppercase',
    color: 'rgb(199, 143, 59)',
    letterSpacing: 0,
    fontFamily:
      '"Inter","IBM Plex Sans",-apple-system,BlinkMacSystemFont,"Segoe UI",Inter,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
    fontWeight: 600,
  },
  dataViewContent: {
    fontSize: '16px',
    lineHeight: 1,
    color: '#ededed',
    letterSpacing: 0,
    fontFamily:
      '"Inter","IBM Plex Sans",-apple-system,BlinkMacSystemFont,"Segoe UI",Inter,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
    fontWeight: 400,
  },

  noticeData: {
    fontSize: '16px',
    lineHeight: 1.4,
    letterSpacing: 0,
    color: '#ededed',
    fontFamily:
      '"Inter","IBM Plex Sans",-apple-system,BlinkMacSystemFont,"Segoe UI",Inter,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
    fontWeight: 400,
  },
  noticeTime: {
    fontSize: '14px',
    lineHeight: 1,
    letterSpacing: 0,
    opacity: 0.5,
    color: '#ededed',
    fontFamily:
      '"Inter","IBM Plex Sans",-apple-system,BlinkMacSystemFont,"Segoe UI",Inter,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
    fontWeight: 400,
  },
};
