import * as jwtDecode from "jwt-decode";

import { AUTH_HEADER, AUTH_STORAGE } from "./auth.constant";
import { setLocalData } from "../store/store.service";
import { httpRequest } from "../http/index";

// import { getBrowserCookie } from "../cookie";

export function authDecode(raw) {
  try {
    return jwtDecode(raw);
  } catch (error) {
    return {};
  }
}

export function setAutorizationHeader(tokenHash) {
  const token = `Bearer ${tokenHash}`;
  httpRequest.defaults.headers.common[AUTH_HEADER] = token;
}

export function setAutorization(token) {
  token = token || null;

  setAutorizationHeader(token);
  setLocalData(AUTH_STORAGE, token);
}

export function setCurrentAuthCookie() {
  // const authToken = getBrowserCookie(AUTH_STORAGE);

  if (authToken) {
    setAutorizationHeader(authToken);
  }
}
