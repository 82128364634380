import { httpRequest } from "../../main/http";

import {
  GDS_ADMIN_ITEM_DATA_API,
  GDS_ADMIN_ITEM_DATA_ACTION_TYPE,
  GDS_ADMIN_ITEM_DATA_STORE_NAME,
} from "./gds-admin-item-data.constant";

import { convertGdsAdminItemData } from "./gds-admin-item-data.convert";

export function getGdsAdminItemData(data) {
  return async (dispatch) => {
    dispatch({
      type: GDS_ADMIN_ITEM_DATA_ACTION_TYPE.REQUEST_PENDING,
    });

    try {
      const res = await httpRequest({
        method: GDS_ADMIN_ITEM_DATA_API.GDS_ADMIN_ITEM_DATA.TYPE,
        url: GDS_ADMIN_ITEM_DATA_API.GDS_ADMIN_ITEM_DATA.ENDPOINT(data.gdsId),
      });

      dispatch({
        type: GDS_ADMIN_ITEM_DATA_ACTION_TYPE.REQUEST_SUCCESS,
        data: convertGdsAdminItemData(res.data),
        gdsId: data.gdsId,
      });
    } catch (error) {
      if (error) {
        console.log(error);
      }
      if (error.response) {
        dispatch({
          type: GDS_ADMIN_ITEM_DATA_ACTION_TYPE.REQUEST_ERROR,
          errorMessage: error.response.data.message,
        });
      }
    }
  };
}

export function reloadGdsAdminItemData() {
  return async (dispatch, getState) => {
    const { gdsId } = getState()[GDS_ADMIN_ITEM_DATA_STORE_NAME];

    dispatch({
      type: GDS_ADMIN_ITEM_DATA_ACTION_TYPE.REQUEST_UPDATE_PENDING,
    });

    try {
      const res = await httpRequest({
        method: GDS_ADMIN_ITEM_DATA_API.GDS_ADMIN_ITEM_DATA.TYPE,
        url: GDS_ADMIN_ITEM_DATA_API.GDS_ADMIN_ITEM_DATA.ENDPOINT(gdsId),
      });

      dispatch({
        type: GDS_ADMIN_ITEM_DATA_ACTION_TYPE.REQUEST_UPDATE_SUCCESS,
        data: convertGdsAdminItemData(res.data),
      });
    } catch (error) {
      if (error) {
        console.log(error);
      }
      if (error.response) {
        dispatch({
          type: GDS_ADMIN_ITEM_DATA_ACTION_TYPE.REQUEST_ERROR,
          errorMessage: error.response.data.message,
        });
      }
    }
  };
}
