import { text, TextComponent } from "../../../lib/common/text";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";

import { AUTH_LOGIN_ROUTE_PATH } from "../../auth-login/auth-login.constant";
import { ACCOUNT_RECOVERY_ROUTE_PATH } from "../../account-recovery/account-recovery.constant";

export const AuthSignupFooterComponent = (props) => {
  const navigate = useNavigate();

  return (
    <Box>
      <Box>
        <Box>
          <TextComponent
            variant="body1"
            component="span"
            tid="AUTH.SIGNUP.FOOTER.LOGIN_LINK_TEXT"
          />{" "}
          <Link
            component="button"
            variant="body2"
            onClick={() => {
              navigate(AUTH_LOGIN_ROUTE_PATH);
            }}
          >
            <TextComponent tid="AUTH.SIGNUP.FOOTER.LOGIN_LINK" />
          </Link>
        </Box>
        <Box>
          <TextComponent
            variant="body1"
            component="span"
            tid="AUTH.SIGNUP.FOOTER.RECOVERY_LINK_TEXT"
          />{" "}
          <Link
            component="button"
            variant="body2"
            onClick={() => {
              navigate(ACCOUNT_RECOVERY_ROUTE_PATH);
            }}
          >
            <TextComponent tid="AUTH.SIGNUP.FOOTER.RECOVERY_LINK" />
          </Link>
        </Box>
      </Box>
      <Box sx={{ pt: 4 }}>
        <TextComponent
          variant="body1"
          component="span"
          tid="AUTH.SIGNUP.FOOTER.TERMS_LINK_TEXT"
        />{" "}
        <Link
          component="button"
          variant="body2"
          href={text("AUTH.SIGNUP.FOOTER.PRIVACY_LINK_VALUE")}
        >
          <TextComponent tid="AUTH.SIGNUP.FOOTER.TERMS_LINK" />
        </Link>{" "}
        <TextComponent
          variant="body1"
          component="span"
          tid="AUTH.SIGNUP.FOOTER.PRIVACY_LINK_TEXT"
        />{" "}
        <Link
          component="button"
          variant="body2"
          href={text("AUTH.SIGNUP.FOOTER.PRIVACY_LINK_VALUE")}
        >
          <TextComponent tid="AUTH.SIGNUP.FOOTER.PRIVACY_LINK" />
        </Link>{" "}
      </Box>
    </Box>
  );
};
