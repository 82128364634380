import { PURCHASE_ADMIN_ITEM_UPDATE_ROUTE_PATH_DYNAMIC } from "../purchase-admin-item-update/purchase-admin-item-update.constant";
import { PURCHASE_ADMIN_ITEM_ROUTE_PATH_DYNAMIC } from "../purchase-admin-item/purchase-admin-item.constant";

export const getPurchaseAdminItemGds = (purchaseId) => [
  {
    id: 0,
    tid: "NAVIGATION.TAB.PURCHASE.ADMIN.ITEM",
    path: PURCHASE_ADMIN_ITEM_ROUTE_PATH_DYNAMIC({ purchaseId }),
  },
  {
    id: 1,
    tid: "NAVIGATION.TAB.PURCHASE.ADMIN.ITEM_UPDATE",
    path: PURCHASE_ADMIN_ITEM_UPDATE_ROUTE_PATH_DYNAMIC({ purchaseId }),
  },
];
