import React from "react";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import { TextComponent } from "../../../lib/common/text/text.component";
import { GDS_ADMIN_ITEM_ROUTE_PATH_DYNAMIC } from "../../gds-admin-item/gds-admin-item.constant";
import { GDS_ADMIN_LIST_DATA_NAME } from "../gds-admin-list.constant";
import Divider from "@mui/material/Divider";

export function GdsAdminListViewComponent(props) {
  const {
    data: { list: data },
  } = props;

  if (data.length === 0) {
    return (
      <Box sx={{ px: 6, pt: 4 }}>
        <TextComponent variant="body1" tid="GDS.ADMIN.LIST.LIST_IS_EMPTY" />
      </Box>
    );
  }

  const navigate = useNavigate();

  const handleClickItem = (gdsId) => (e) => {
    e.preventDefault();
    return navigate(GDS_ADMIN_ITEM_ROUTE_PATH_DYNAMIC(gdsId));
  };
  return (
    <List sx={{ pt: 4 }}>
      {data.map((item, itemIndex) => (
        <React.Fragment>
          <ListItem
            onClick={handleClickItem(item.id)}
            button
            variant="list"
            component="a"
            href={GDS_ADMIN_ITEM_ROUTE_PATH_DYNAMIC(item.id)}
          >
            <Grid sx={{ flexDirection: "row" }} container>
              <Grid item xs={12} md={2}>
                <TextComponent
                  variant="body1"
                  gutterBottom
                  tid="GDS.ADMIN.LIST.LIST.ID"
                  tvalue={{
                    id: item[GDS_ADMIN_LIST_DATA_NAME.ID],
                  }}
                />
                <TextComponent
                  variant="body1"
                  tid="GDS.ADMIN.LIST.LIST.ORDER"
                  tvalue={{
                    order: item[GDS_ADMIN_LIST_DATA_NAME.ORDER],
                  }}
                />
              </Grid>

              <Grid item xs={12} md>
                <TextComponent
                  variant="body1"
                  gutterBottom
                  tid="GDS.ADMIN.LIST.LIST.TITLE"
                  tvalue={{
                    title: item[GDS_ADMIN_LIST_DATA_NAME.TITLE],
                  }}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextComponent
                  variant="body1"
                  gutterBottom
                  tid="GDS.ADMIN.LIST.LIST.TYPE"
                  tvalue={{
                    type: item[GDS_ADMIN_LIST_DATA_NAME.TYPE].text,
                  }}
                />
                <TextComponent
                  variant="body1"
                  gutterBottom
                  tid="GDS.ADMIN.LIST.LIST.CATEGORY"
                  tvalue={{
                    category: item[GDS_ADMIN_LIST_DATA_NAME.CATEGORY].text,
                  }}
                />
                <TextComponent
                  variant="body1"
                  tid="GDS.ADMIN.LIST.LIST.CREATE_DATE"
                  tvalue={{
                    createDate: item[GDS_ADMIN_LIST_DATA_NAME.CREATE_DATA],
                  }}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <TextComponent
                  variant="body1"
                  gutterBottom
                  tid="GDS.ADMIN.LIST.LIST.PRICE"
                  tvalue={{
                    price: item[GDS_ADMIN_LIST_DATA_NAME.PRICE],
                  }}
                />

                <TextComponent
                  variant="body1"
                  gutterBottom
                  tid={
                    item[GDS_ADMIN_LIST_DATA_NAME.WEIGHT]
                      ? "GDS.ADMIN.LIST.LIST.WEIGHT"
                      : "GDS.ADMIN.LIST.LIST.STOCK_NOT_SET"
                  }
                  tvalue={{
                    weight: item[GDS_ADMIN_LIST_DATA_NAME.WEIGHT],
                  }}
                />

                <TextComponent
                  variant="body1"
                  gutterBottom
                  tid="GDS.ADMIN.LIST.LIST.AMOUNT"
                  tvalue={{
                    amount: item[GDS_ADMIN_LIST_DATA_NAME.AMOUNT],
                  }}
                />

                {item[GDS_ADMIN_LIST_DATA_NAME.SIZE] && (
                  <TextComponent
                    variant="body1"
                    gutterBottom
                    tid="GDS.ADMIN.LIST.LIST.SIZE"
                    tvalue={{
                      sizes: item[GDS_ADMIN_LIST_DATA_NAME.SIZE],
                    }}
                  />
                )}
              </Grid>
            </Grid>
          </ListItem>
          {itemIndex !== data.length - 1 && (
            <ListItem>
              <Divider sx={{ width: "100%", opacity: 0.35 }} />
            </ListItem>
          )}
        </React.Fragment>
      ))}
    </List>
  );
}
