import Box from '@mui/material/Box';

import { FieldTextComponent } from '../../../lib/common/field-text/field-text.component';
import { FieldPasswordComponent } from '../../../lib/common/field-password/field-password.component';

import { TextComponent } from '../../../lib/common/text';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { AUTH_LOGIN_DATA_NAME } from '../auth-login.constant';

export const AuthLoginFormComponent = (props) => {
  const {
    handleChange,
    handleBlur,
    handleSubmit,
    values,
    errors,
    touched,
    isValid,
    isSubmitting,
    isPending,

    pageLoading,
    isSuccess,
    isError,
  } = props;

  const isFieldError = (name) => {
    return errors[name] && touched[name] && errors[name];
  };

  const getFieldError = (name) => isFieldError(name) && errors[name];

  const isSubmitDisabled = () => {
    if (isError) {
      return false;
    }

    if (isPending) {
      return true;
    }

    if (JSON.stringify(touched) === '{}') {
      return true;
    }

    if (!isValid || isSuccess || pageLoading) {
      return true;
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box sx={{ py: 4 }}>
        <Grid spacing={3} container>
          <Grid item>
            <FieldTextComponent
              label={<TextComponent tid="AUTH.LOGIN.FORM.LOGIN" />}
              name={AUTH_LOGIN_DATA_NAME.LOGIN}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values[AUTH_LOGIN_DATA_NAME.LOGIN]}
              error={isFieldError(AUTH_LOGIN_DATA_NAME.LOGIN)}
              fullWidth
              errorText={getFieldError(AUTH_LOGIN_DATA_NAME.LOGIN)}
            />
          </Grid>
          <Grid item>
            <FieldPasswordComponent
              label={<TextComponent tid="AUTH.LOGIN.FORM.PASSWORD" />}
              name={AUTH_LOGIN_DATA_NAME.PASSWORD}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values[AUTH_LOGIN_DATA_NAME.PASSWORD]}
              error={isFieldError(AUTH_LOGIN_DATA_NAME.PASSWORD)}
              fullWidth
              errorText={getFieldError(AUTH_LOGIN_DATA_NAME.PASSWORD)}
            />
          </Grid>
          <Grid item>
            <Button type="sumbit" disabled={isSubmitDisabled()} fullWidth>
              <TextComponent tid="AUTH.LOGIN.FORM.SUBMIT" />
            </Button>
          </Grid>
        </Grid>
      </Box>
    </form>
  );
};
