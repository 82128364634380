export const ACCOUNT_SETTINGS_UPDATE_USER_DATA_STORE_NAME =
  "ACCOUNT_SETTINGS_UPDATE_USER_DATA";

export const ACCOUNT_SETTINGS_UPDATE_USER_DATA_API = {
  CHANGE_USER_DATA: {
    ENDPOINT: "/user-settings/user-data",
    TYPE: "PATCH",
  },
};

export const ACCOUNT_SETTINGS_UPDATE_USER_ACTION_TYPE = {
  FORM_PENDING: "ACCOUNT_SETTINGS_UPDATE_USER_ACTION_TYPE.FORM_PENDING",
  FORM_SUCCESS: "ACCOUNT_SETTINGS_UPDATE_USER_ACTION_TYPE.FORM_SUCCESS",
  FORM_ERROR: "ACCOUNT_SETTINGS_UPDATE_USER_ACTION_TYPE.FORM_ERROR",
  FORM_RESET: "ACCOUNT_SETTINGS_UPDATE_USER_ACTION_TYPE.FORM_RESET",
};

export const ACCOUNT_SETTINGS_UPDATE_USER_DATA_NAME = {
  CITY: "city",
  REGION: "region",
  PHONE: "phone",
  POST_OFFICE: "postOffice",
  FULL_NAME: "fullName",
};

export const REGION_OPTION = [
  { id: 1, value: "Вінницька", tid: "Вінницька" },
  { id: 2, value: "Волинська", tid: "Волинська" },
  { id: 3, value: "Дніпропетровська", tid: "Дніпропетровська" },
  { id: 4, value: "Донецька", tid: "Донецька" },
  { id: 5, value: "Житомирська", tid: "Житомирська" },
  { id: 6, value: "Закарпатська", tid: "Закарпатська" },
  { id: 7, value: "Запорізька", tid: "Запорізька" },
  { id: 8, value: "Івано-Франківська", tid: "Івано-Франківська" },
  { id: 9, value: "Київська", tid: "Київська" },
  { id: 10, value: "Кіровоградська", tid: "Кіровоградська" },
  { id: 11, value: "Луганська", tid: "Луганська" },
  { id: 12, value: "Львівська", tid: "Львівська" },
  { id: 13, value: "Миколаївська", tid: "Миколаївська" },
  { id: 14, value: "Одеська", tid: "Одеська" },
  { id: 15, value: "Полтавська", tid: "Полтавська" },
  { id: 16, value: "Рівненська", tid: "Рівненська" },
  { id: 17, value: "Сумська", tid: "Сумська" },
  { id: 18, value: "Тернопільська", tid: "Тернопільська" },
  { id: 19, value: "Харківська", tid: "Харківська" },
  { id: 20, value: "Херсонська", tid: "Херсонська" },
  { id: 21, value: "Хмельницька", tid: "Хмельницька" },
  { id: 22, value: "Черкаська", tid: "Черкаська" },
  { id: 23, value: "Чернівецька", tid: "Чернівецька" },
  { id: 24, value: "Чернігівська", tid: "Чернігівська" },
];
