import { useParams } from "react-router-dom";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import { TabContainer } from "../../lib/common/tab/tab.container";
import { getGdsAdminItemTab } from "../gds-admin-item/gds-admin-item.constant";
import { GdsItemAdminListContainer } from "./gds-item-admin-list.container";
import { GdsItemAdminCreateInfoContainer } from "../gds-item-admin-create-info/gds-item-admin-create-info.container";
import { GdsItemAdminListPaginationContainer } from "../gds-item-admin-list-pagination/gds-item-admin-list-pagination.container";

export function GdsItemAdminListPage() {
  const { gdsId } = useParams();

  const GDS_ADMIN_ITEM_TAB = getGdsAdminItemTab(gdsId);
  return (
    <Container maxWidth="sm">
      <Grid spacing={6} container>
        <Grid item>
          <TabContainer tab={GDS_ADMIN_ITEM_TAB} />
        </Grid>
        <Grid item>
          <GdsItemAdminCreateInfoContainer />
        </Grid>
        <Grid item>
          <GdsItemAdminListPaginationContainer />
        </Grid>
        <Grid item sx={{ width: "100%", alignSelf: "baseline" }}>
          <GdsItemAdminListContainer />
        </Grid>
      </Grid>
    </Container>
  );
}
