import { combineReducers } from "redux";
import { AUTH_SIGNUP_STORE_NAME } from "../../core/auth-signup/auth-signup.constant";
import { authSignupStore } from "../../core/auth-signup/auth-signup.store";

import { AUTH_LOGIN_STORE_NAME } from "../../core/auth-login/auth-login.constant";
import { authLoginStore } from "../../core/auth-login/auth-login.store";

import { authStore, AUTH_STORE_NAME } from "../../lib/common/auth";
import {
  navigationStore,
  NAVIGATION_STORE_NAME,
} from "../../lib/common/navigation";

import { PURCHASE_ADMIN_ITEM_DELETE_STORE_NAME } from "../../core/purchase-admin-item-delete/purchase-admin-item-delete.constant";
import { purchaseItemDeleteDataStore } from "../../core/purchase-admin-item-delete/purchase-admin-item-delete.store";

import { ACCOUNT_UPDATE_AUTH_STORE_NAME } from "../../core/account-update-auth/account-update-auth.constant";
import { accountUpdateAuthStore } from "../../core/account-update-auth/account-update-auth.store";

import { ACCOUNT_RECOVERY_STORE_NAME } from "../../core/account-recovery/account-recovery.constant";
import { accountRecoveryStore } from "../../core/account-recovery/account-recovery.store";

import { ACCOUNT_VERIFICATION_EMAIL_STORE_NAME } from "../../core/account-verification-email/account-verification-email.constant";
import { accountVerificationEmailStore } from "../../core/account-verification-email/account-verification-email.store";

import { ACCOUNT_SETTINGS_UPDATE_PASSWORD_STORE_NAME } from "../../core/account-settings-update-password/account-settings-update-password.constant";
import { accountSettingsUpdatePasswordStore } from "../../core/account-settings-update-password/account-settings-update-password.store";

import { ACCOUNT_SETTINGS_UPDATE_EMAIL_STORE_NAME } from "../../core/account-settings-update-email/account-settings-update-email.constant";
import { accountSettingsUpdateEmailStore } from "../../core/account-settings-update-email/account-settings-update-email.store";

import { ACCOUNT_SETTINGS_UPDATE_TIMEZONE_STORE_NAME } from "../../core/account-settings-update-timezone/account-settings-update-timezone.constant";
import { accountSettingsUpdateTimezoneStore } from "../../core/account-settings-update-timezone/account-settings-update-timezone.store";

import { USER_ADMIN_CREATE_STORE_NAME } from "../../core/user-admin-create/user-admin-create.constant";
import { userAdminCreateStore } from "../../core/user-admin-create/user-admin-create.store";

import { USER_ADMIN_ITEM_DATA_STORE_NAME } from "../../core/user-admin-item-data/user-admin-item-data.constant";
import { userAdminItemDataStore } from "../../core/user-admin-item-data/user-admin-item-data.store";

import { USER_ADMIN_LIST_STORE_NAME } from "../../core/user-admin-list/user-admin-list.constant";
import { userAdminListStore } from "../../core/user-admin-list/user-admin-list.store";

import { USER_ADMIN_ITEM_UPDATE_DATA_STORE_NAME } from "../../core/user-admin-item-update-data/user-admin-item-update-data.constant";
import { userAdminItemUpdateDataStore } from "../../core/user-admin-item-update-data/user-admin-item-update-data.store";

import { PURCHASE_CUSTOMER_LIST_STORE_NAME } from "../../core/purchase-customer-list/purchase-customer-list.constant";
import { purchaseCustomerListStore } from "../../core/purchase-customer-list/purchase-customer-list.store";

import { GDS_ADMIN_LIST_STORE_NAME } from "../../core/gds-admin-list/gds-admin-list.constant";
import { gdsAdminListStore } from "../../core/gds-admin-list/gds-admin-list.store";

import { GDS_ADMIN_CREATE_STORE_NAME } from "../../core/gds-admin-create/gds-admin-create.constant";
import { gdsAdminCreateStore } from "../../core/gds-admin-create/gds-admin-create.store";

import { GDS_ADMIN_ITEM_DATA_STORE_NAME } from "../../core/gds-admin-item-data/gds-admin-item-data.constant";
import { gdsAdminItemDataStore } from "../../core/gds-admin-item-data/gds-admin-item-data.store";

import { GDS_ADMIN_ITEM_UPDATE_DATA_STORE_NAME } from "../../core/gds-admin-item-update-data/gds-admin-item-update-data.constant";
import { gdsAdminItemUpdateDataStore } from "../../core/gds-admin-item-update-data/gds-admin-item-update-data.store";

import { PURCHASE_ADMIN_LIST_STORE_NAME } from "../../core/purchase-admin-list/purchase-admin-list.constant";
import { purchaseAdminListStore } from "../../core/purchase-admin-list/purchase-admin-list.store";

import { GDS_ADMIN_ITEM_DELETE_STORE_NAME } from "../../core/gds-admin-item-delete/gds-admin-item-delete.constant";
import { gdsAdminItemDeleteStore } from "../../core/gds-admin-item-delete/gds-admin-item-delete.store";

import { PURCHASE_ADMIN_ITEM_STORE_NAME } from "../../core/purchase-admin-item/purchase-admin-item.constant";
import { purchaseAdminItemStore } from "../../core/purchase-admin-item/purchase-admin-item.store";

import { PURCHASE_CUSTOMER_ITEM_STORE_NAME } from "../../core/purchase-customer-item/purchase-customer-item.constant";
import { purchaseCustomerItemStore } from "../../core/purchase-customer-item/purchase-customer-item.store";

import { PURCHASE_ADMIN_ITEM_UPDATE_CUSTOMER_STORE_NAME } from "../../core/purchase-admin-item-update-customer/purchase-admin-item-update-customer.constant";
import { purchaseAdminItemUpdateCustomerStore } from "../../core/purchase-admin-item-update-customer/purchase-admin-item-update-customer.store";

import { PURCHASE_ADMIN_ITEM_PAYMENT_CONFIRM_STORE_NAME } from "../../core/purchase-admin-item-payment-confirm/purchase-admin-item-payment-confirm.constant";
import { purchaseAdminItemPaymentConfirmStore } from "../../core/purchase-admin-item-payment-confirm/purchase-admin-item-payment-confirm.store";

import { PURCHASE_ADMIN_ITEM_PAYMENT_CREATE_STORE_NAME } from "../../core/purchase-admin-item-payment-create/purchase-admin-item-payment-create.constant";
import { purchaseAdminItemPaymentCreateStore } from "../../core/purchase-admin-item-payment-create/purchase-admin-item-payment-create.store";

import { noticeAccountListStore } from "../../core/notice-account-list/notice-account-list.store";
import { NOTICE_ACCOUNT_LIST_STORE_NAME } from "../../core/notice-account-list/notice-account-list.constant";

import { NOTICE_ADMIN_LIST_STORE_NAME } from "../../core/notice-admin-list/notice-admin-list.constant";
import { noticeAdminListStore } from "../../core/notice-admin-list/notice-admin-list.store";

import { NOTICE_ACCOUNT_LIST_SHORT_STORE_NAME } from "../../core/notice-account-list-short/notice-account-list-short.constant";
import { noticeAccountListShortStore } from "../../core/notice-account-list-short/notice-account-list-short.store";

import { NOTICE_ACCOUNT_ITEM_UPDATE_VIEW_STORE_NAME } from "../../core/notice-account-item-update-view/notice-account-item-update-view.constant";
import { noticeAccountItemUpdateViewStore } from "../../core/notice-account-item-update-view/notice-account-item-update-view.store";

import { fileAdminListStore } from "../../core/file-admin-list/file-admin-list.store";
import { FILE_ADMIN_LIST_STORE_NAME } from "../../core/file-admin-list/file-admin-list.constant";

import { PURCHASE_ADMIN_ITEM_UPDATE_DATA_STORE_NAME } from "../../core/purchase-admin-item-update-data/purchase-admin-item-update-data.constant";
import { purchaseAdminItemUpdateDataStore } from "../../core/purchase-admin-item-update-data/purchase-admin-item-update-data.store";

import { PURCHASE_CUSTOMER_ITEM_UPDATE_DATA_STORE_NAME } from "../../core/purchase-customer-item-update-data/purchase-customer-item-update-data.constant";
import { purchaseCustomerItemUpdateDataStore } from "../../core/purchase-customer-item-update-data/purchase-customer-item-update-data.store";

import { ACCOUNT_SETTINGS_UPDATE_USER_DATA_STORE_NAME } from "../../core/account-settings-update-user-data/account-settings-update-user-data.constant";
import { accountSettingsUpdateUserDataStore } from "../../core/account-settings-update-user-data/account-settings-update-user-data.store";

import { PURCHASE_ADMIN_ITEM_UPDATE_GDS_ITEM_STORE_NAME } from "../../core/purchase-admin-item-update-gds-item/purchase-admin-item-update-gds-item.constant";
import { purchaseAdminItemUpdateGdsItemStore } from "../../core/purchase-admin-item-update-gds-item/purchase-admin-item-update-gds-item.store";

import { GDS_ITEM_ADMIN_LIST_STORE_NAME } from "../../core/gds-item-admin-list/gds-item-admin-list.constant";
import { gdsItemAdminListStore } from "../../core/gds-item-admin-list/gds-item-admin-list.store";

import { GDS_ITEM_ADMIN_CREATE_STORE_NAME } from "../../core/gds-item-admin-create/gds-item-admin-create.constant";
import { gdsItemAdminCreateStore } from "../../core/gds-item-admin-create/gds-item-admin-create.store";

import { GDS_ITEM_ADMIN_ITEM_DATA_STORE_NAME } from "../../core/gds-item-admin-item-data/gds-item-admin-item-data.constant";
import { gdsItemAdminItemDataStore } from "../../core/gds-item-admin-item-data/gds-item-admin-item-data.store";

import { GDS_ITEM_ADMIN_ITEM_UPDATE_DATA_STORE_NAME } from "../../core/gds-item-admin-item-update-data/gds-item-admin-item-update-data.constant";
import { gdsItemAdminItemUpdateDataStore } from "../../core/gds-item-admin-item-update-data/gds-item-admin-item-update-data.store";

import { GDS_ITEM_ADMIN_ITEM_DELETE_STORE_NAME } from "../../core/gds-item-admin-item-delete/gds-item-admin-item-delete.constant";
import { gdsItemAdminItemDeleteStore } from "../../core/gds-item-admin-item-delete/gds-item-admin-item-delete.store";

export const reducers = combineReducers({
  [AUTH_STORE_NAME]: authStore,
  [NAVIGATION_STORE_NAME]: navigationStore,
  [AUTH_SIGNUP_STORE_NAME]: authSignupStore,
  [AUTH_LOGIN_STORE_NAME]: authLoginStore,
  [ACCOUNT_UPDATE_AUTH_STORE_NAME]: accountUpdateAuthStore,
  [ACCOUNT_RECOVERY_STORE_NAME]: accountRecoveryStore,
  [ACCOUNT_VERIFICATION_EMAIL_STORE_NAME]: accountVerificationEmailStore,
  [ACCOUNT_SETTINGS_UPDATE_PASSWORD_STORE_NAME]:
    accountSettingsUpdatePasswordStore,
  [ACCOUNT_SETTINGS_UPDATE_EMAIL_STORE_NAME]: accountSettingsUpdateEmailStore,
  [USER_ADMIN_CREATE_STORE_NAME]: userAdminCreateStore,
  [USER_ADMIN_ITEM_DATA_STORE_NAME]: userAdminItemDataStore,
  [USER_ADMIN_LIST_STORE_NAME]: userAdminListStore,
  [ACCOUNT_SETTINGS_UPDATE_TIMEZONE_STORE_NAME]:
    accountSettingsUpdateTimezoneStore,
  [USER_ADMIN_ITEM_UPDATE_DATA_STORE_NAME]: userAdminItemUpdateDataStore,
  [PURCHASE_CUSTOMER_LIST_STORE_NAME]: purchaseCustomerListStore,
  [PURCHASE_CUSTOMER_LIST_STORE_NAME]: purchaseCustomerListStore,
  [GDS_ADMIN_LIST_STORE_NAME]: gdsAdminListStore,
  [GDS_ADMIN_CREATE_STORE_NAME]: gdsAdminCreateStore,
  [GDS_ADMIN_ITEM_DATA_STORE_NAME]: gdsAdminItemDataStore,
  [GDS_ADMIN_ITEM_UPDATE_DATA_STORE_NAME]: gdsAdminItemUpdateDataStore,
  [PURCHASE_ADMIN_LIST_STORE_NAME]: purchaseAdminListStore,
  [GDS_ADMIN_ITEM_DELETE_STORE_NAME]: gdsAdminItemDeleteStore,
  [PURCHASE_ADMIN_ITEM_STORE_NAME]: purchaseAdminItemStore,
  [PURCHASE_CUSTOMER_ITEM_STORE_NAME]: purchaseCustomerItemStore,
  [PURCHASE_ADMIN_ITEM_UPDATE_CUSTOMER_STORE_NAME]:
    purchaseAdminItemUpdateCustomerStore,
  [PURCHASE_ADMIN_ITEM_PAYMENT_CONFIRM_STORE_NAME]:
    purchaseAdminItemPaymentConfirmStore,
  [PURCHASE_ADMIN_ITEM_PAYMENT_CREATE_STORE_NAME]:
    purchaseAdminItemPaymentCreateStore,
  [NOTICE_ACCOUNT_LIST_STORE_NAME]: noticeAccountListStore,
  [NOTICE_ADMIN_LIST_STORE_NAME]: noticeAdminListStore,
  [NOTICE_ACCOUNT_LIST_SHORT_STORE_NAME]: noticeAccountListShortStore,
  [NOTICE_ACCOUNT_ITEM_UPDATE_VIEW_STORE_NAME]:
    noticeAccountItemUpdateViewStore,
  [FILE_ADMIN_LIST_STORE_NAME]: fileAdminListStore,
  [PURCHASE_ADMIN_ITEM_UPDATE_DATA_STORE_NAME]:
    purchaseAdminItemUpdateDataStore,
  [PURCHASE_CUSTOMER_ITEM_UPDATE_DATA_STORE_NAME]:
    purchaseCustomerItemUpdateDataStore,
  [PURCHASE_ADMIN_ITEM_DELETE_STORE_NAME]: purchaseItemDeleteDataStore,
  [ACCOUNT_SETTINGS_UPDATE_USER_DATA_STORE_NAME]:
    accountSettingsUpdateUserDataStore,
  [PURCHASE_ADMIN_ITEM_UPDATE_GDS_ITEM_STORE_NAME]:
    purchaseAdminItemUpdateGdsItemStore,
  [GDS_ITEM_ADMIN_LIST_STORE_NAME]: gdsItemAdminListStore,
  [GDS_ITEM_ADMIN_CREATE_STORE_NAME]: gdsItemAdminCreateStore,
  [GDS_ITEM_ADMIN_ITEM_DATA_STORE_NAME]: gdsItemAdminItemDataStore,
  [GDS_ITEM_ADMIN_ITEM_UPDATE_DATA_STORE_NAME]: gdsItemAdminItemUpdateDataStore,
  [GDS_ITEM_ADMIN_ITEM_DELETE_STORE_NAME]: gdsItemAdminItemDeleteStore,
});
