import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';

import { TextComponent } from '../../lib/common/text';
import Button from '@mui/material/Button';
import { Backdrop, CircularProgress } from '@mui/material';

export function AccountLogoutComponent(props) {
  const { onSubmitForm, isLoading } = props;
  return (
    <Box>
      <Paper sx={{ p: 6 }}>
        <Box>
          <Box sx={{ pb: 4 }}>
            <TextComponent
              variant="title"
              component="div"
              tid="ACCOUNT.LOGOUT.HEADING"
            />
          </Box>
          <Divider />
          <Button sx={{ mt: 4 }} onClick={onSubmitForm} fullWidth>
            <TextComponent tid="ACCOUNT.LOGOUT.FORM.SUBMIT" />
          </Button>
          {isLoading && (
            <Backdrop
              sx={{
                color: '#121212',
                zIndex: (theme) => theme.zIndex.drawer + 1,
              }}
              open
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          )}
        </Box>
      </Paper>
    </Box>
  );
}
