import { text } from '../text/text.core';

import {
  GDS_STATUS_VALUE,
  GDS_TAG_VALUE,
  GDS_TYPE_VALUE,
  GDS_CATEGORY_VALUE,
  GDS_SIZE_VALUE,
  GDS_SIZE_VIEW_VALUE,
} from './gds.type';

export const convertGdsStatus = (gdsStatusId) => {
  if (!gdsStatusId) {
    return null;
  }

  return {
    id: gdsStatusId,
    value: GDS_STATUS_VALUE[gdsStatusId],
    text: text(`GDS.DATA.STATUS.${GDS_STATUS_VALUE[gdsStatusId]}`),
  };
};

export const convertGdsCategory = (gdsCategoryId) => {
  if (!gdsCategoryId) {
    return null;
  }

  return {
    id: gdsCategoryId,
    value: GDS_CATEGORY_VALUE[gdsCategoryId],
    text: text(`GDS.DATA.CATEGORY.${GDS_CATEGORY_VALUE[gdsCategoryId]}`),
  };
};

export const convertGdsType = (gdsTypeId) => {
  if (!gdsTypeId) {
    return null;
  }

  return {
    id: gdsTypeId,
    value: GDS_TYPE_VALUE[gdsTypeId],
    text: text(`GDS.DATA.TYPE.${GDS_TYPE_VALUE[gdsTypeId]}`),
  };
};

export const convertGdsTag = (gdsTagId) => {
  if (!gdsTagId) {
    return null;
  }

  return {
    id: Number(gdsTagId),
    value: GDS_TAG_VALUE[gdsTagId],
    text: text(`GDS.DATA.TAG.${GDS_TAG_VALUE[gdsTagId]}`),
  };
};

export const convertGdsTagList = (list) => {
  if (!list) {
    return [];
  }

  if (list.length === 0) {
    return [];
  }

  return list.map(convertGdsTag);
};

export const convertGdsSize = (gdsSizeId) => {
  if (!gdsSizeId) {
    return null;
  }

  return {
    id: Number(gdsSizeId),
    value: GDS_SIZE_VALUE[gdsSizeId],
    text: text(`GDS.DATA.SIZE.${GDS_SIZE_VALUE[gdsSizeId]}`),
  };
};

export const convertGdsSizeList = (list) => {
  if (!list) {
    return [];
  }

  if (list.length === 0) {
    return [];
  }

  return list.map(convertGdsSize);
};

export const convertSize = (list) => {
  return list.map((e) => GDS_SIZE_VIEW_VALUE[e]);
};
