import { PURCHASE_CUSTOMER_ITEM_UPDATE_DATA_DATA_NAME } from "./purchase-customer-item-update-data.constant";

export const convertPurchaseCustomerItemUpdateDataFormData = (data) => ({
  [PURCHASE_CUSTOMER_ITEM_UPDATE_DATA_DATA_NAME.COMMENTS]:
    data[PURCHASE_CUSTOMER_ITEM_UPDATE_DATA_DATA_NAME.COMMENTS] || undefined,
  [PURCHASE_CUSTOMER_ITEM_UPDATE_DATA_DATA_NAME.FULL_NAME]:
    data[PURCHASE_CUSTOMER_ITEM_UPDATE_DATA_DATA_NAME.FULL_NAME],
  [PURCHASE_CUSTOMER_ITEM_UPDATE_DATA_DATA_NAME.REGION]:
    data[PURCHASE_CUSTOMER_ITEM_UPDATE_DATA_DATA_NAME.REGION].value,
  [PURCHASE_CUSTOMER_ITEM_UPDATE_DATA_DATA_NAME.CITY]:
    data[PURCHASE_CUSTOMER_ITEM_UPDATE_DATA_DATA_NAME.CITY],
  [PURCHASE_CUSTOMER_ITEM_UPDATE_DATA_DATA_NAME.POST_OFFICE]:
    data[PURCHASE_CUSTOMER_ITEM_UPDATE_DATA_DATA_NAME.POST_OFFICE],

  [PURCHASE_CUSTOMER_ITEM_UPDATE_DATA_DATA_NAME.PHONE]:
    data[PURCHASE_CUSTOMER_ITEM_UPDATE_DATA_DATA_NAME.PHONE],
});
