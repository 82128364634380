import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  getRequestData,
  getRequestErrorMessage,
  isRequestError,
  isRequestPending,
  isRequestSuccess,
} from "../../main/store/store.service";
import { NAVIGATION_STORE_NAME } from "../../lib/common/navigation";

import {
  uploadPurchaseAdminItemUpdateDataForm,
  resetPurchaseAdminItemUpdateDataFormState,
} from "./purchase-admin-item-update-data.action";
import {
  PURCHASE_ADMIN_ITEM_UPDATE_DATA_STORE_NAME,
  PURCHASE_ADMIN_ITEM_UPDATE_DATA_DATA_NAME,
} from "./purchase-admin-item-update-data.constant";
import { convertPurchaseAdminItemUpdateDataFormData } from "./purchase-admin-item-update-data.convert";
import { purchaseAdminItemUpdateDataFormValidation } from "./purchase-admin-item-update-data.validation";
import { PurchaseAdminItemUpdateDataComponent } from "./purchase-admin-item-update-data.component";
import {
  PURCHASE_ADMIN_ITEM_DATA_NAME,
  PURCHASE_ADMIN_ITEM_STORE_NAME,
} from "../purchase-admin-item/purchase-admin-item.constant";
import { REGION_OPTION } from "../account-settings-update-user-data/account-settings-update-user-data.constant";

export function PurchaseAdminItemUpdateDataContainer() {
  let { purchaseId } = useParams();
  const dispatch = useDispatch();
  const { state, pageLoading, purchaseState } = useSelector((state) => ({
    state: state[PURCHASE_ADMIN_ITEM_UPDATE_DATA_STORE_NAME],
    purchaseState: state[PURCHASE_ADMIN_ITEM_STORE_NAME],
    pageLoading: state[NAVIGATION_STORE_NAME].pageLoading,
  }));

  React.useEffect(() => {
    dispatch(resetPurchaseAdminItemUpdateDataFormState());
  }, []);

  const purchaseAdminItemUpdateDataFormSendData = (values) => {
    const data = convertPurchaseAdminItemUpdateDataFormData(values);

    data.purchaseId = purchaseId;

    dispatch(uploadPurchaseAdminItemUpdateDataForm(data));
  };

  const getInitialValue = () => {
    if (isRequestSuccess(purchaseState.request)) {
      const data = getRequestData(purchaseState.request);

      return {
        [PURCHASE_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.COMMENTS]:
          data[PURCHASE_ADMIN_ITEM_DATA_NAME.COMMENTS] || "",
        [PURCHASE_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.NOTES]:
          data[PURCHASE_ADMIN_ITEM_DATA_NAME.NOTES] || "",
        [PURCHASE_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.STATUS]:
          data[PURCHASE_ADMIN_ITEM_DATA_NAME.STATUS],
        [PURCHASE_ADMIN_ITEM_DATA_NAME.TRACK_NUMBER]:
          data[PURCHASE_ADMIN_ITEM_DATA_NAME.TRACK_NUMBER],
        [PURCHASE_ADMIN_ITEM_DATA_NAME.PHONE]:
          data.customer[PURCHASE_ADMIN_ITEM_DATA_NAME.PHONE] || "",
        [PURCHASE_ADMIN_ITEM_DATA_NAME.CITY]:
          data.customer[PURCHASE_ADMIN_ITEM_DATA_NAME.CITY] || "",
        [PURCHASE_ADMIN_ITEM_DATA_NAME.REGION]:
          REGION_OPTION.find(
            (e) =>
              e.value === data.customer[PURCHASE_ADMIN_ITEM_DATA_NAME.REGION]
          ) || "",
        [PURCHASE_ADMIN_ITEM_DATA_NAME.POST_OFFICE]:
          data.customer[PURCHASE_ADMIN_ITEM_DATA_NAME.POST_OFFICE] || "",
        [PURCHASE_ADMIN_ITEM_DATA_NAME.FULL_NAME]:
          data.customer[PURCHASE_ADMIN_ITEM_DATA_NAME.FULL_NAME] || "",
      };
    }

    return {};
  };

  return (
    <PurchaseAdminItemUpdateDataComponent
      isPending={isRequestPending(state.form)}
      isError={isRequestError(state.form)}
      isSuccess={isRequestSuccess(state.form)}
      initialValue={getInitialValue()}
      validation={purchaseAdminItemUpdateDataFormValidation}
      onSubmitForm={purchaseAdminItemUpdateDataFormSendData}
      pageLoading={pageLoading}
      errorMessage={getRequestErrorMessage(state.form)}
    />
  );
}
