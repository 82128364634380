import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import InputMask from "react-input-mask";

import { FieldTextComponent } from "../../../lib/common/field-text/field-text.component";
import { FieldSelectComponent } from "../../../lib/common/field-select/field-select.component";

import { TextComponent } from "../../../lib/common/text";
import {
  ACCOUNT_SETTINGS_UPDATE_USER_DATA_NAME,
  REGION_OPTION,
} from "../account-settings-update-user-data.constant";

export const AccountSettingsUpdateUserDataFormComponent = (props) => {
  const {
    handleChange,
    handleBlur,
    handleSubmit,
    values,
    errors,
    touched,
    isValid,

    pageLoading,
    isSuccess,
    isError,
    isPending,
    initialValue,
  } = props;

  const isFieldError = (name) => {
    return errors[name] && touched[name] && errors[name];
  };

  const getFieldError = (name) => isFieldError(name) && errors[name];

  const isSubmitDisabled = () => {
    if (isError) {
      return false;
    }

    if (isPending) {
      return true;
    }

    if (JSON.stringify(touched) === "{}") {
      return true;
    }

    if (!isValid || isSuccess || pageLoading) {
      return true;
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box sx={{ pt: 4 }}>
        <Grid spacing={3} container>
          <Grid item>
            <FieldTextComponent
              label={
                <TextComponent tid="ACCOUNT.UPDATE_USER_DATA.FORM.FULL_NAME" />
              }
              name={ACCOUNT_SETTINGS_UPDATE_USER_DATA_NAME.FULL_NAME}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values[ACCOUNT_SETTINGS_UPDATE_USER_DATA_NAME.FULL_NAME]}
              error={isFieldError(
                ACCOUNT_SETTINGS_UPDATE_USER_DATA_NAME.FULL_NAME
              )}
              fullWidth
              errorText={getFieldError(
                ACCOUNT_SETTINGS_UPDATE_USER_DATA_NAME.FULL_NAME
              )}
            />
          </Grid>
          <Grid item>
            <InputMask
              mask="+380 (99) 999 99 99"
              type="phone"
              placeholder="+380"
              maskChar={null}
              id={ACCOUNT_SETTINGS_UPDATE_USER_DATA_NAME.PHONE}
              name={ACCOUNT_SETTINGS_UPDATE_USER_DATA_NAME.PHONE}
              label={
                <TextComponent tid="ACCOUNT.UPDATE_USER_DATA.FORM.PHONE" />
              }
              value={values[ACCOUNT_SETTINGS_UPDATE_USER_DATA_NAME.PHONE]}
              onChange={handleChange}
              onBlur={handleBlur}
              error={isFieldError(ACCOUNT_SETTINGS_UPDATE_USER_DATA_NAME.PHONE)}
              fullWidth
              disableUnderline
              errorText={getFieldError(
                ACCOUNT_SETTINGS_UPDATE_USER_DATA_NAME.PHONE
              )}
            >
              {(inputProps) => <FieldTextComponent {...inputProps} />}
            </InputMask>
          </Grid>
          <Grid item>
            <FieldTextComponent
              label={<TextComponent tid="ACCOUNT.UPDATE_USER_DATA.FORM.CITY" />}
              name={ACCOUNT_SETTINGS_UPDATE_USER_DATA_NAME.CITY}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values[ACCOUNT_SETTINGS_UPDATE_USER_DATA_NAME.CITY]}
              error={isFieldError(ACCOUNT_SETTINGS_UPDATE_USER_DATA_NAME.CITY)}
              fullWidth
              errorText={getFieldError(
                ACCOUNT_SETTINGS_UPDATE_USER_DATA_NAME.CITY
              )}
            />
          </Grid>
          <Grid item>
            <FieldSelectComponent
              option={REGION_OPTION}
              label={
                <TextComponent tid="ACCOUNT.UPDATE_USER_DATA.FORM.REGION" />
              }
              name={ACCOUNT_SETTINGS_UPDATE_USER_DATA_NAME.REGION}
              onChange={handleChange}
              onBlur={handleBlur}
              // value={values[ACCOUNT_SETTINGS_UPDATE_USER_DATA_NAME.REGION]}
              error={isFieldError(
                ACCOUNT_SETTINGS_UPDATE_USER_DATA_NAME.REGION
              )}
              defaultValue={
                values[ACCOUNT_SETTINGS_UPDATE_USER_DATA_NAME.REGION]
              }
              // defaultValue={{ id: 12, value: "Львівська", tid: "Львівська" }}
              fullWidth
              errorText={getFieldError(
                ACCOUNT_SETTINGS_UPDATE_USER_DATA_NAME.REGION
              )}
            />
          </Grid>
          <Grid item>
            <FieldTextComponent
              label={
                <TextComponent tid="ACCOUNT.UPDATE_USER_DATA.FORM.POST_OFFICE" />
              }
              name={ACCOUNT_SETTINGS_UPDATE_USER_DATA_NAME.POST_OFFICE}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values[ACCOUNT_SETTINGS_UPDATE_USER_DATA_NAME.POST_OFFICE]}
              error={isFieldError(
                ACCOUNT_SETTINGS_UPDATE_USER_DATA_NAME.POST_OFFICE
              )}
              fullWidth
              errorText={getFieldError(
                ACCOUNT_SETTINGS_UPDATE_USER_DATA_NAME.POST_OFFICE
              )}
            />
          </Grid>
          <Grid item>
            <Button type="sumbit" disabled={isSubmitDisabled()} fullWidth>
              <TextComponent tid="ACCOUNT.UPDATE_USER_DATA.FORM.SUBMIT" />
            </Button>
          </Grid>
        </Grid>
      </Box>
    </form>
  );
};
