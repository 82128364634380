import { Link } from "react-router-dom";
import styled from "styled-components";

export const Logo = () => {
  const logoSrc = process.env.REACT_APP_LOGO_URL;

  return (
    <AStyled target="_blank" href={logoSrc}>
      <LogoWrapper>
        <img
          alt="Norh Side Logo"
          src="/static/img/logo/northSideLogo.svg"
          height={50}
          width={50}
        />
        <img
          alt="Norh Side Text"
          src="/static/img/logo/northSideLogoText.svg"
          height={70}
          width={200}
        />
      </LogoWrapper>
    </AStyled>
  );
};

const AStyled = styled.a``;

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;
