import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Alert from "@mui/material/Alert";
import { Formik } from "formik";

import { LoaderBarComponent } from "../../lib/common/loader-bar";
import { TextComponent } from "../../lib/common/text";
import { Logo } from "../../lib/common/logo/logo";
import { AccountVerificationEmailFormComponent } from "./frame/account-verification-email-form.component";

export function AccountVerificationEmailComponent(props) {
  const {
    initialValue,
    pageLoading,
    isPending,
    validation,
    onSubmitForm,
    isError,
    isSuccess,
    errorMessage,
    userEmail,
  } = props;
  return (
    <Box>
      <Box sx={{ pb: 6 }}>
        <Logo />
      </Box>
      <Paper sx={{ p: 6 }}>
        <Box>
          <Box sx={{ pb: 4 }}>
            <TextComponent
              variant="heading"
              gutterBottom
              component="div"
              tid="ACCOUNT.VERIFICATION_EMAIL.HEADING"
            />
            <TextComponent
              variant="subtitle"
              component="div"
              tid="ACCOUNT.VERIFICATION_EMAIL.SUBTITLE"
              tvalue={{ email: userEmail }}
            />
          </Box>
          <Divider />

          <Formik
            initialValues={initialValue}
            validate={validation}
            onSubmit={onSubmitForm}
          >
            {(props) => (
              <AccountVerificationEmailFormComponent
                {...props}
                isPending={isPending}
                isError={isError}
                isSuccess={isSuccess}
                errorMessage={errorMessage}
                pageLoading={pageLoading}
              />
            )}
          </Formik>
          {isError && (
            <Box sx={{ pt: 4 }}>
              <Alert severity="error">
                <TextComponent tid={`ERROR.${errorMessage}`} />
              </Alert>
            </Box>
          )}

          {isPending && <LoaderBarComponent />}
        </Box>
      </Paper>
    </Box>
  );
}
