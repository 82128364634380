import { validate } from '../../main/validate';

import { PURCHASE_CUSTOMER_ITEM_UPDATE_DATA_DATA_NAME } from './purchase-customer-item-update-data.constant';

import { maxLength } from '../../main/validate/validate.service';

const config = {
  [PURCHASE_CUSTOMER_ITEM_UPDATE_DATA_DATA_NAME.COMMENTS]: [maxLength(1000)],
};

export const purchaseCustomerItemUpdateDataFormValidation = (values) =>
  validate(values, config);
