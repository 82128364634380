import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';

import Alert from '@mui/material/Alert';

import { LoaderBarComponent } from '../../lib/common/loader-bar';
import { SkeletonDataViewComponent } from '../../lib/common/skeleton-data-view/skeleton-data-view.component';
import { TextComponent } from '../../lib/common/text';

import { PurchaseCustomerItemDataGdsViewComponent } from './frame/purchase-customer-item-data-gds-view.component';

export function PurchaseCustomerItemDataGdsComponent(props) {
  const { isPending, isError, isSuccess, data, errorMessage } = props;

  return (
    <Box>
      <Paper sx={{ p: 6 }}>
        <Box>
          <Box sx={{ pb: 4 }}>
            <TextComponent
              variant="title"
              component="div"
              tid="PURCHASE.CUSTOMER.ITEM_GDS.TITLE"
            />
          </Box>
          <Divider />
          {isSuccess && (
            <PurchaseCustomerItemDataGdsViewComponent data={data} />
          )}
          {isPending && (
            <Box sx={{ pt: 4 }}>
              <SkeletonDataViewComponent />
            </Box>
          )}
          {isError && (
            <Box sx={{ pt: 4 }}>
              <Alert severity="error">
                <TextComponent tid={`ERROR.${errorMessage}`} />
              </Alert>
            </Box>
          )}
          {isPending && <LoaderBarComponent />}
        </Box>
      </Paper>
    </Box>
  );
}
