export const GDS_ITEM_ADMIN_CREATE_ROUTE_PATH = "/admin/gds/:gdsId/item/create";

export const GDS_ITEM_ADMIN_CREATE_ROUTE_PATH_DYNAMIC = (
  { gdsId } = {
    gdsId: "[gdsId]",
  }
) => `/admin/gds/${gdsId}/item/create`;

export const GDS_ITEM_ADMIN_CREATE_STORE_NAME = "GDS_ITEM_ADMIN_CREATE";

export const GDS_ITEM_ADMIN_CREATE_API = {
  GDS_ITEM_ADMIN_CREATE: {
    ENDPOINT: (gdsId) => `/gds/${gdsId}/item`,
    TYPE: "POST",
  },
};

export const GDS_ITEM_ADMIN_CREATE_ACTION_TYPE = {
  FORM_PENDING: "GDS_ITEM_ADMIN_CREATE_ACTION_TYPE.FORM_PENDING",
  FORM_SUCCESS: "GDS_ITEM_ADMIN_CREATE_ACTION_TYPE.FORM_SUCCESS",
  FORM_ERROR: "GDS_ITEM_ADMIN_CREATE_ACTION_TYPE.FORM_ERROR",
  FORM_RESET: "GDS_ITEM_ADMIN_CREATE_ACTION_TYPE.FORM_RESET",
};

export const GDS_ITEM_ADMIN_CREATE_DATA_NAME = {
  AMOUNT: "amount",
  SIZE: "size",
  ORDER: "order",
  CREATE_DATE: "createDate",
  UPDATE_DATE: "updateDate",
  ID: "id",
  GDS_ID: "gdsId",
  STATUS: "status",
};

export const GDS_ITEM_ADMIN_CREATE_SHORTCUT_LIST = [
  {
    id: 1,
    title: "GDS.SHORTCUT.FRED.TITLE",
    data: "GDS.SHORTCUT.FRED.DATA",
  },
  {
    id: 2,
    title: "GDS.SHORTCUT.REG.TITLE",
    data: "GDS.SHORTCUT.REG.DATA",
  },
  {
    id: 3,
    title: "GDS.SHORTCUT.REGRU.TITLE",
    data: "GDS.SHORTCUT.REGRU.DATA",
  },
];

export const GDS_SIZE_OPTION = [
  { id: 1, value: 1, tid: "GDS.DATA.SIZE.S" },
  { id: 2, value: 2, tid: "GDS.DATA.SIZE.S-M" },
  { id: 3, value: 3, tid: "GDS.DATA.SIZE.M" },
  { id: 4, value: 4, tid: "GDS.DATA.SIZE.M-L" },
  { id: 5, value: 5, tid: "GDS.DATA.SIZE.L" },
  { id: 6, value: 6, tid: "GDS.DATA.SIZE.L-XL" },
  { id: 7, value: 7, tid: "GDS.DATA.SIZE.XL" },
  { id: 8, value: 8, tid: "GDS.DATA.SIZE.2XL" },
];
