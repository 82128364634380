import { validate } from '../../main/validate';

import { ACCOUNT_SETTINGS_UPDATE_USER_DATA_NAME } from './account-settings-update-user-data.constant';

const config = {
  [ACCOUNT_SETTINGS_UPDATE_USER_DATA_NAME.FULL_NAME]: [],
  [ACCOUNT_SETTINGS_UPDATE_USER_DATA_NAME.POST_OFFICE]: [],
  [ACCOUNT_SETTINGS_UPDATE_USER_DATA_NAME.CITY]: [],
  [ACCOUNT_SETTINGS_UPDATE_USER_DATA_NAME.REGION]: [],
  [ACCOUNT_SETTINGS_UPDATE_USER_DATA_NAME.PHONE]: [],
};

export const accountSettingsUpdateUserDataFormValidation = (values) =>
  validate(values, config);
