import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { GdsAdminCreateComponent } from "./gds-admin-create.component";
import { gdsAdminCreateFormValidation } from "./gds-admin-create.validation";
import { convertGdsAdminCreateFormData } from "./gds-admin-create.convert";
import {
  uploadGdsAdminCreateForm,
  resetGdsAdminCreateForm,
} from "./gds-admin-create.action";
import {
  GDS_ADMIN_CREATE_STORE_NAME,
  GDS_ADMIN_CREATE_DATA_NAME,
} from "./gds-admin-create.constant";
import { NAVIGATION_STORE_NAME } from "../../lib/common/navigation";
import {
  getRequestErrorMessage,
  isRequestError,
  isRequestPending,
  isRequestSuccess,
} from "../../main/store/store.service";

export function GdsAdminCreateContainer() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state, pageLoading } = useSelector((state) => ({
    state: state[GDS_ADMIN_CREATE_STORE_NAME],
    pageLoading: state[NAVIGATION_STORE_NAME].pageLoading,
  }));

  const signupFormSendData = (values) => {
    const data = convertGdsAdminCreateFormData(values);
    dispatch(uploadGdsAdminCreateForm(data, navigate));
  };

  React.useEffect(() => {
    dispatch(resetGdsAdminCreateForm());
  }, []);

  const getInitialValue = () => {
    return {
      [GDS_ADMIN_CREATE_DATA_NAME.TAG]: [],
      [GDS_ADMIN_CREATE_DATA_NAME.AMOUNT]: 1,
      [GDS_ADMIN_CREATE_DATA_NAME.AMOUNT_STATUS]: false,
    };
  };

  return (
    <GdsAdminCreateComponent
      isPending={isRequestPending(state.form)}
      isError={isRequestError(state.form)}
      isSuccess={isRequestSuccess(state.form)}
      initialValue={getInitialValue()}
      validation={gdsAdminCreateFormValidation}
      onSubmitForm={signupFormSendData}
      pageLoading={pageLoading}
      errorMessage={getRequestErrorMessage(state.form)}
    />
  );
}
