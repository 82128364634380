import Grid from "@mui/material/Grid";

import { TextComponent } from "../../../lib/common/text/text.component";
import { FileListComponent } from "../../../lib/common/file-list/file-list.component";
import { GDS_ADMIN_ITEM_DATA_DATA_NAME } from "../gds-admin-item-data.constant";

export function GdsAdminItemDataViewComponent(props) {
  const { data } = props;

  return (
    <Grid container spacing={3} sx={{ pt: 4 }}>
      <Grid item>
        <TextComponent
          gutterBottom
          variant="dataViewTitle"
          tid="GDS.ADMIN.ITEM_DATA.ID"
        />
        <TextComponent variant="dataViewContent">
          {data[GDS_ADMIN_ITEM_DATA_DATA_NAME.ID]}
        </TextComponent>
      </Grid>

      <Grid item>
        <TextComponent
          gutterBottom
          variant="dataViewTitle"
          tid="GDS.ADMIN.ITEM_DATA.ORDER"
        />
        <TextComponent variant="dataViewContent">
          {data[GDS_ADMIN_ITEM_DATA_DATA_NAME.ORDER]}
        </TextComponent>
      </Grid>

      <Grid item>
        <TextComponent
          gutterBottom
          variant="dataViewTitle"
          tid="GDS.ADMIN.ITEM_DATA.TITLE"
        />
        <TextComponent sx={{ lineHeight: "1.3em" }} variant="dataViewContent">
          {data[GDS_ADMIN_ITEM_DATA_DATA_NAME.TITLE]}
        </TextComponent>
      </Grid>

      <Grid item>
        <TextComponent
          gutterBottom
          variant="dataViewTitle"
          tid="GDS.ADMIN.ITEM_DATA.DESCRIPTION"
        />
        <TextComponent sx={{ lineHeight: "1.3em" }} variant="dataViewContent">
          {data[GDS_ADMIN_ITEM_DATA_DATA_NAME.DESCRIPTION]}
        </TextComponent>
      </Grid>

      <Grid item>
        <TextComponent
          gutterBottom
          variant="dataViewTitle"
          tid="GDS.ADMIN.ITEM_DATA.BODY"
        />
        <TextComponent sx={{ lineHeight: "1.3em" }} variant="dataViewContent">
          {data[GDS_ADMIN_ITEM_DATA_DATA_NAME.BODY]}
        </TextComponent>
      </Grid>

      <Grid item>
        <TextComponent
          gutterBottom
          variant="dataViewTitle"
          tid="GDS.ADMIN.ITEM_DATA.WEIGHT"
        />
        <TextComponent variant="dataViewContent">
          {data[GDS_ADMIN_ITEM_DATA_DATA_NAME.WEIGHT] || (
            <TextComponent tid="GDS.ADMIN.ITEM_DATA.STOCK_EN_NULL" />
          )}
        </TextComponent>
      </Grid>

      <Grid item>
        <TextComponent
          gutterBottom
          variant="dataViewTitle"
          tid="GDS.ADMIN.ITEM_DATA.CATEGORY"
        />
        <TextComponent variant="dataViewContent">
          {data[GDS_ADMIN_ITEM_DATA_DATA_NAME.CATEGORY].text}
        </TextComponent>
      </Grid>
      <Grid item>
        <TextComponent
          gutterBottom
          variant="dataViewTitle"
          tid="GDS.ADMIN.ITEM_DATA.TYPE"
        />
        <TextComponent variant="dataViewContent">
          {data[GDS_ADMIN_ITEM_DATA_DATA_NAME.TYPE].text}
        </TextComponent>
      </Grid>

      <Grid item>
        <TextComponent
          gutterBottom
          variant="dataViewTitle"
          tid="GDS.ADMIN.ITEM_DATA.AMOUNT"
        />
        <TextComponent variant="dataViewContent">
          {data[GDS_ADMIN_ITEM_DATA_DATA_NAME.AMOUNT]}
        </TextComponent>
      </Grid>

      <Grid item>
        <TextComponent
          gutterBottom
          variant="dataViewTitle"
          tid="GDS.ADMIN.ITEM_DATA.PPO"
        />
        <TextComponent variant="dataViewContent">
          {data[GDS_ADMIN_ITEM_DATA_DATA_NAME.PPO]}
        </TextComponent>
      </Grid>

      <Grid item>
        <TextComponent
          gutterBottom
          variant="dataViewTitle"
          tid="GDS.ADMIN.ITEM_DATA.TAG"
        />
        <TextComponent variant="dataViewContent">
          {data[GDS_ADMIN_ITEM_DATA_DATA_NAME.TAG]
            .map((i) => i.text)
            .join(", ")}
        </TextComponent>
      </Grid>
      
      <Grid item>
        <TextComponent
          gutterBottom
          variant="dataViewTitle"
          tid="GDS.ADMIN.ITEM_DATA.CREATE_DATE"
        />
        <TextComponent variant="dataViewContent">
          {data[GDS_ADMIN_ITEM_DATA_DATA_NAME.CREATE_DATE]}
        </TextComponent>
      </Grid>
      <Grid item>
        <TextComponent
          gutterBottom
          variant="dataViewTitle"
          tid="GDS.ADMIN.ITEM_DATA.UPDATE_DATE"
        />
        <TextComponent variant="dataViewContent">
          {data[GDS_ADMIN_ITEM_DATA_DATA_NAME.UPDATE_DATE]}
        </TextComponent>
      </Grid>

      <Grid item>
        <TextComponent
          gutterBottom
          variant="dataViewTitle"
          tid="GDS.ADMIN.ITEM_DATA.PRICE"
        />
        <TextComponent variant="dataViewContent">
          {data[GDS_ADMIN_ITEM_DATA_DATA_NAME.PRICE]}
        </TextComponent>
      </Grid>

      <Grid item>
        <TextComponent
          gutterBottom
          variant="dataViewTitle"
          tid="GDS.ADMIN.ITEM_DATA.URL"
        />
        <TextComponent variant="dataViewContent">
          {data[GDS_ADMIN_ITEM_DATA_DATA_NAME.URL]}
        </TextComponent>
      </Grid>

      <Grid item>
        <TextComponent
          gutterBottom
          variant="dataViewTitle"
          tid="GDS.ADMIN.ITEM_DATA.FILE_PREVIEW"
        />
        <FileListComponent
          item={data[GDS_ADMIN_ITEM_DATA_DATA_NAME.FILE_PREVIEW]}
        />
      </Grid>
      <Grid item>
        <TextComponent
          gutterBottom
          variant="dataViewTitle"
          tid="GDS.ADMIN.ITEM_DATA.FILE_LIST"
        />
        <FileListComponent
          list={data[GDS_ADMIN_ITEM_DATA_DATA_NAME.FILE_LIST]}
        />
      </Grid>
    </Grid>
  );
}
