import { text } from '../text/text.core';

import { USER_ROLE_VALUE } from './user.type';

export const convertUserRole = (userRoleId) => {
  if (!userRoleId) {
    return null;
  }

  return {
    id: userRoleId,
    value: USER_ROLE_VALUE[userRoleId],
    text: text(`USER.DATA.ROLE.${USER_ROLE_VALUE[userRoleId]}`),
  };
};

export const convertTimezone = (timezoneNumber) => {
  if (Number.isNaN(timezoneNumber)) {
    return null;
  }

  return {
    id: timezoneNumber,
    value: timezoneNumber,
    text: text(
      `USER.DATA.TIMEZONE.${String(timezoneNumber).split('.').join('')}`,
    ),
  };
};
