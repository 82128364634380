import * as XLSX from "xlsx";
import { XLSX_COLUMN_TYPE } from "./xlsx.type";
import {
  GDS_CATEGORY_VIEW_VALUE,
  GDS_SIZE_VALUE,
  GDS_TYPE_VIEW_VALUE,
} from "../gds/gds.type";

export const toConvertArrayJsonToXlsx = (orders) => {
  const wb = XLSX.utils.book_new();
  const wsData = [];
  if (orders.length < 1) {
    alert("Замовлення не знайдені");
    return;
  }

  orders.forEach((order, index) => {
    // Добавление информации о заказе
    const convertOrder = {
      [XLSX_COLUMN_TYPE.TRACK_NUMBER]: order?.trackNumber || "Не вказано",
      [XLSX_COLUMN_TYPE.CREATE_DATE]: order.createDate,

      [XLSX_COLUMN_TYPE.PRICE]: order.price,
      [XLSX_COLUMN_TYPE.WEIGHT]: order.weight,

      [XLSX_COLUMN_TYPE.CUSTOMER_EMAIL]: order.customer.email,
      [XLSX_COLUMN_TYPE.CUSTOMER_FULL_NAME]: order.customer.fullName,
      [XLSX_COLUMN_TYPE.CUSTOMER_CITY]: order.customer.city,
      [XLSX_COLUMN_TYPE.CUSTOMER_REGION]: order.customer.region,
      [XLSX_COLUMN_TYPE.CUSTOMER_PHONE]: order.customer.phone,
      [XLSX_COLUMN_TYPE.CUSTOMER_POST_OFFICE]: order.customer.postOffice,
    };

    order.cart.gdsOrders.forEach((item, itemIndex) => {
      // Добавление информации о каждом элементе заказа
      const convertItem = {
        [XLSX_COLUMN_TYPE.GDS_ORDER_TITLE]: item?.gds?.title,
        [XLSX_COLUMN_TYPE.GDS_ORDER_PRICE]: item?.gds?.price,
        [XLSX_COLUMN_TYPE.GDS_ORDER_WEIGHT]: item?.gds?.weight,
        [XLSX_COLUMN_TYPE.GDS_ORDER_AMOUNT]: item?.amount,
        [XLSX_COLUMN_TYPE.GDS_ORDER_CATEGORY]:
          GDS_CATEGORY_VIEW_VALUE[item?.gds?.category],
        [XLSX_COLUMN_TYPE.GDS_ORDER_TYPE]:
          GDS_TYPE_VIEW_VALUE[item?.gds?.type] || "Не вказано",
        [XLSX_COLUMN_TYPE.SIZE]:
          GDS_SIZE_VALUE[item?.gdsItem?.size] || "Не вказано",
      };

      // Добавление информации о заказе и товаре на одной строке
      if (itemIndex === 0) {
        wsData.push({ ...convertOrder, ...convertItem });
      } else {
        // Для последующих элементов добавляем только информацию о товаре
        wsData.push({ ...convertItem });
      }
    });

    // Добавление пустой строки между заказами
    wsData.push({});
  });

  const ws = XLSX.utils.json_to_sheet(wsData);

  // Установка одинаковой ширины для всех колонок
  const columnWidth = 15;
  const cols = [];
  for (let i = 0; i < Object.keys(wsData[0]).length; i++) {
    cols.push({ width: columnWidth });
  }
  ws["!cols"] = cols;

  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
  XLSX.writeFile(wb, "purchases.xlsx");
};

export const generateProductSizeReport = (orders) => {
  const productSizeCounts = {};
  if (orders.length < 1) {
    alert("Замовлення не знайдені");
    return;
  }
  // Итерируемся по заказам и подсчитываем количество каждого товара с каждым размером
  orders.forEach((order) => {
    if (order?.cart?.gdsOrders && order.cart.gdsOrders.length > 0) {
      order.cart.gdsOrders.forEach((item) => {
        const productName = item.gds.title.toUpperCase();
        const sizeValue = item?.gdsItem?.size
          ? GDS_SIZE_VALUE[item.gdsItem.size]
          : "no size";
        const size = sizeValue ? sizeValue.toUpperCase() : "no size";

        if (!productSizeCounts[productName]) {
          productSizeCounts[productName] = {};
        }

        if (!productSizeCounts[productName][size]) {
          productSizeCounts[productName][size] = 0;
        }

        productSizeCounts[productName][size] += item.amount;
      });
    }
  });

  const wb = XLSX.utils.book_new();
  const wsData = [];

  // Формируем заголовки колонок
  const columns = ["Product"];
  const sizes = new Set();

  // Собираем все уникальные размеры
  for (const product in productSizeCounts) {
    for (const size in productSizeCounts[product]) {
      sizes.add(size);
    }
  }

  // Добавляем колонки для каждого размера
  sizes.forEach((size) => {
    columns.push(size);
  });

  wsData.push(columns);

  // Формируем данные для XLSX
  for (const product in productSizeCounts) {
    const rowData = [product];

    // Заполняем количество товара для каждого размера
    sizes.forEach((size) => {
      const count = productSizeCounts[product][size] || 0;
      rowData.push(count);
    });

    wsData.push(rowData);
  }

  // Создаем лист с данными
  const ws = XLSX.utils.aoa_to_sheet(wsData);
  XLSX.utils.book_append_sheet(wb, ws, "Product Sizes Report");

  // Сохраняем файл
  XLSX.writeFile(wb, "product_sizes_report.xlsx");
};
