import React from "react";

import { useDispatch, useSelector } from "react-redux";

import {
  getNoticeAccountListShort,
  updateNoticeAccountListShort,
} from "./notice-account-list-short.action";
import {
  NOTICE_ACCOUNT_LIST_SHORT_CRON_UPDATE,
  NOTICE_ACCOUNT_LIST_SHORT_STORE_NAME,
} from "./notice-account-list-short.constant";
import {
  getRequestData,
  isRequestError,
  isRequestSuccess,
} from "../../main/store/store.service";
import { NAVIGATION_STORE_NAME } from "../../lib/common/navigation";
import {
  initVisualNoticePlayer,
  clearVisualNotice,
} from "../../lib/common/notice/notice.core";
import { NOTICE_TYPE_TYPE } from "../../lib/common/notice/notice.type";
import { updateNoticeAccountItemViewByIds } from "../notice-account-item-update-view/notice-account-item-update-view.action";
import { updateNoticeAccountList } from "../notice-account-list/notice-account-list.action";
import { NOTICE_ACCOUNT_LIST_STORE_NAME } from "../notice-account-list/notice-account-list.constant";
import { getQuery } from "../../main/navigation";

function usePrevious(value) {
  const ref = React.useRef();
  React.useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export function NoticeAccountListShortHandler() {
  const dispatch = useDispatch();

  const [playVisualNotice, setHandlerPlayVisualNotice] = React.useState(null);

  const { notice, activePath, fullList } = useSelector((state) => ({
    notice: state[NOTICE_ACCOUNT_LIST_SHORT_STORE_NAME].request,
    activePath: state[NAVIGATION_STORE_NAME].activePath,
    fullList: state[NOTICE_ACCOUNT_LIST_STORE_NAME],
  }));

  const isNoticeLoaded = isRequestSuccess(notice);
  const isNoticeError = isRequestError(notice);
  const noticeAmount = isNoticeLoaded ? getRequestData(notice).amount : null;
  const noticeData = getRequestData(notice);

  const isFullListLoader = isRequestSuccess(fullList.request);
  const fullListData = getRequestData(fullList.request);

  const prevAmount = usePrevious({ noticeAmount });

  const cronUpdateData = () => {
    dispatch(updateNoticeAccountListShort());
  };

  React.useEffect(() => {
    if (isNoticeLoaded && !isNoticeError) {
      dispatch(updateNoticeAccountListShort());
    } else {
      dispatch(getNoticeAccountListShort());
    }

    const setIntervalId = setInterval(
      cronUpdateData,
      NOTICE_ACCOUNT_LIST_SHORT_CRON_UPDATE
    );

    return () => {
      clearInterval(setIntervalId);
    };
  }, []);

  React.useEffect(() => {
    if (playVisualNotice) {
      return null;
    }

    if (isNoticeLoaded) {
      setHandlerPlayVisualNotice(initVisualNoticePlayer);
    }
  }, [isNoticeLoaded]);

  React.useEffect(() => {
    if (!prevAmount) {
      return null;
    }

    if (prevAmount.noticeAmount === null) {
      return null;
    }

    if (Number.isNaN(noticeAmount)) {
      return null;
    }

    if (noticeAmount < prevAmount.noticeAmount) {
      clearVisualNotice();
      return null;
    }

    if (noticeAmount > prevAmount.noticeAmount) {
      playVisualNotice(
        "NOTICE.PLAY_VISUAL.NEW",
        { amount: noticeAmount },
        noticeData.list[0].visual
      );
    }
  }, [noticeAmount]);

  return null;
}
