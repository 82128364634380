import React from "react";
import { Formik } from "formik";

import { PurchaseAdminItemCalendarCreateFormComponent } from "./frame/purchase-list-upload-calendar";

export function PurchaseListUploadComponent(props) {
  const {
    data,
    isPending,
    pageLoading,
    isError,
    initialValue,
    validation,
    handleUploadPurchase,
  } = props;

  const isEmptyList = data?.list?.length < 1;

  return (
    <>
      <Formik enableReinitialize initialValues={initialValue}>
        {(props) => (
          <PurchaseAdminItemCalendarCreateFormComponent
            {...props}
            handleUploadPurchase={handleUploadPurchase}
            validation={validation}
            isEmptyList={isEmptyList}
            isPending={isPending}
            isError={isError}
            pageLoading={pageLoading}
          />
        )}
      </Formik>
    </>
  );
}
