import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { GdsAdminItemUpdateDataComponent } from "./gds-admin-item-update-data.component";
import { gdsAdminItemUpdateDataFormValidation } from "./gds-admin-item-update-data.validation";
import { convertGdsAdminItemUpdateDataFormData } from "./gds-admin-item-update-data.convert";

import {
  uploadGdsAdminItemUpdateDataForm,
  resetGdsAdminItemUpdateDataFormState,
} from "./gds-admin-item-update-data.action";
import {
  GDS_ADMIN_ITEM_UPDATE_DATA_STORE_NAME,
  GDS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME,
} from "./gds-admin-item-update-data.constant";
import { NAVIGATION_STORE_NAME } from "../../lib/common/navigation";
import {
  GDS_ADMIN_ITEM_DATA_STORE_NAME,
  GDS_ADMIN_ITEM_DATA_DATA_NAME,
} from "../gds-admin-item-data/gds-admin-item-data.constant";

import {
  getRequestData,
  getRequestErrorMessage,
  isRequestError,
  isRequestPending,
  isRequestSuccess,
} from "../../main/store/store.service";

export function GdsAdminItemUpdateDataContainer() {
  const dispatch = useDispatch();
  const { state, pageLoading, gdsItemStore } = useSelector((state) => ({
    state: state[GDS_ADMIN_ITEM_UPDATE_DATA_STORE_NAME],
    pageLoading: state[NAVIGATION_STORE_NAME].pageLoading,
    gdsItemStore: state[GDS_ADMIN_ITEM_DATA_STORE_NAME],
  }));

  React.useEffect(() => {
    dispatch(resetGdsAdminItemUpdateDataFormState());
  }, []);

  const gdsAdminItemUpdateDataFormSendData = (values) => {
    const data = convertGdsAdminItemUpdateDataFormData(values);
    dispatch(uploadGdsAdminItemUpdateDataForm(data, gdsItemStore.gdsId));
  };

  const getInitialValue = () => {
    if (isRequestSuccess(gdsItemStore.request)) {
      const data = getRequestData(gdsItemStore.request);

      return {
        [GDS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.ORDER]:
          data[GDS_ADMIN_ITEM_DATA_DATA_NAME.ORDER],
        [GDS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.PRICE]:
          data[GDS_ADMIN_ITEM_DATA_DATA_NAME.PRICE],
        [GDS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.WEIGHT]:
          data[GDS_ADMIN_ITEM_DATA_DATA_NAME.WEIGHT],
        [GDS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.AMOUNT]:
          data[GDS_ADMIN_ITEM_DATA_DATA_NAME.AMOUNT],
        [GDS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.PPO]: [
          data[GDS_ADMIN_ITEM_DATA_DATA_NAME.PPO],
        ],

        [GDS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.TITLE]:
          data[GDS_ADMIN_ITEM_DATA_DATA_NAME.TITLE],
        [GDS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.DESCRIPTION]:
          data[GDS_ADMIN_ITEM_DATA_DATA_NAME.DESCRIPTION],
        [GDS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.BODY]: [
          data[GDS_ADMIN_ITEM_DATA_DATA_NAME.BODY],
        ],
        [GDS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.URL]:
          data[GDS_ADMIN_ITEM_DATA_DATA_NAME.URL],

        [GDS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.CATEGORY]:
          data[GDS_ADMIN_ITEM_DATA_DATA_NAME.CATEGORY],
        [GDS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.TAG]:
          data[GDS_ADMIN_ITEM_DATA_DATA_NAME.TAG],

        [GDS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.STATUS]:
          data[GDS_ADMIN_ITEM_DATA_DATA_NAME.STATUS],
        [GDS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.TYPE]:
          data[GDS_ADMIN_ITEM_DATA_DATA_NAME.TYPE],

        [GDS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.FILE_PREVIEW]: [
          data[GDS_ADMIN_ITEM_DATA_DATA_NAME.FILE_PREVIEW],
        ],

        [GDS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.FILE_LIST]:
          data[GDS_ADMIN_ITEM_DATA_DATA_NAME.FILE_LIST],
      };
    }

    return {};
  };

  return (
    <GdsAdminItemUpdateDataComponent
      isPending={isRequestPending(state.form)}
      isError={isRequestError(state.form)}
      isSuccess={isRequestSuccess(state.form)}
      isDependentPending={isRequestPending(gdsItemStore.request)}
      initialValue={getInitialValue()}
      validation={gdsAdminItemUpdateDataFormValidation}
      onSubmitForm={gdsAdminItemUpdateDataFormSendData}
      pageLoading={pageLoading}
      errorMessage={getRequestErrorMessage(state.form)}
    />
  );
}
