export const GDS_ADMIN_ITEM_UPDATE_DATA_STORE_NAME =
  "GDS_ADMIN_ITEM_UPDATE_DATA";

export const GDS_ADMIN_ITEM_UPDATE_DATA_API = {
  CHANGE_DATA: {
    ENDPOINT: (gdsId) => `/gds/${gdsId}`,
    TYPE: "PATCH",
  },
};

export const GDS_ADMIN_ITEM_UPDATE_DATA_ACTION_TYPE = {
  FORM_PENDING: "GDS_ADMIN_ITEM_UPDATE_DATA_ACTION_TYPE.FORM_PENDING",
  FORM_SUCCESS: "GDS_ADMIN_ITEM_UPDATE_DATA_ACTION_TYPE.FORM_SUCCESS",
  FORM_ERROR: "GDS_ADMIN_ITEM_UPDATE_DATA_ACTION_TYPE.FORM_ERROR",
  FORM_RESET: "GDS_ADMIN_ITEM_UPDATE_DATA_ACTION_TYPE.FORM_RESET",
};

export const GDS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME = {
  ORDER: "order",
  AMOUNT: "amount",
  PRICE: "price",
  WEIGHT: "weight",
  TITLE: "title",
  DESCRIPTION: "description",
  URL: "url",
  FILE_PREVIEW: "filePreviewImageId",
  FILE_LIST: "fileImageListId",
  CATEGORY: "category",
  TYPE: "type",
  TAG: "tag",
  STATUS: "status",
  BODY: "body",
  PPO: "ppoId",
};
