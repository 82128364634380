import React, { useState } from "react";
import { Formik } from "formik";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Alert from "@mui/material/Alert";

import { LoaderBarComponent } from "../../lib/common/loader-bar";
import { SkeletonListComponent } from "../../lib/common/skeleton-list/skeleton-list.component";
import { TextComponent } from "../../lib/common/text";
import { AlertMessageElement } from "../../lib/common/alert-message";

import { PurchaseAdminListViewComponent } from "./frame/purchase-admin-list-view.component";

export function PurchaseAdminListComponent(props) {
  const {
    isPending,
    isError,
    isSuccess,
    data,
    errorMessage,
    isUpdating,
    initialValue,
    onSubmitForm,
    pageLoading,
    isErrorField,
    errorMessageField,
    isSuccessField,
    isPendingField,
  } = props;

  return (
    <Box>
      <Paper sx={{ py: 6, pb: 4 }}>
        <Box>
          <Box sx={{ px: 6 }}>
            <Box sx={{ pb: 4 }}>
              <TextComponent
                variant="title"
                component="div"
                tid="PURCHASE.ADMIN.LIST.TITLE"
              />
            </Box>
            <Divider />
          </Box>

          {isSuccess && (
            <Formik initialValues={initialValue} onSubmit={onSubmitForm}>
              {(props) => (
                <PurchaseAdminListViewComponent
                  {...props}
                  handleSubmitForm={onSubmitForm}
                  data={data}
                  pageLoading={pageLoading}
                  isPending={isPending}
                  isError={isError}
                  errorMessage={errorMessage}
                />
              )}
            </Formik>
          )}

          {isErrorField && (
            <AlertMessageElement
              severity="error"
              tid={`ERROR.${errorMessageField}`}
            />
          )}
          {isSuccessField && (
            <AlertMessageElement
              severity="success"
              tid="PURCHASE.ADMIN.ITEM_UPDATE_DATA.SUCCESS_MESSAGE"
            />
          )}
          {isPending && (
            <Box sx={{ px: 6, pt: 4 }}>
              <SkeletonListComponent />
            </Box>
          )}
          {isError && (
            <Box sx={{ px: 6, pt: 4 }}>
              <Alert severity="error">
                <TextComponent tid={`ERROR.${errorMessage}`} />
              </Alert>
            </Box>
          )}
          {isUpdating && <LoaderBarComponent />}
          {isPendingField && <LoaderBarComponent />}
          {isPending && <LoaderBarComponent />}
        </Box>
      </Paper>
    </Box>
  );
}
