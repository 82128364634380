import { httpRequest } from "../../main/http";

import {
  PURCHASE_ADMIN_ITEM_DELETE_API,
  PURCHASE_ADMIN_ITEM_DELETE_ACTION_TYPE,
} from "./purchase-admin-item-delete.constant";

export function uploadPurchaseAdminItemUpdateDataForm(data) {
  return async (dispatch) => {
    dispatch({
      type: PURCHASE_ADMIN_ITEM_DELETE_ACTION_TYPE.FORM_PENDING,
    });

    try {
      await httpRequest({
        method: PURCHASE_ADMIN_ITEM_DELETE_API.CHANGE_DATA.TYPE,
        url: PURCHASE_ADMIN_ITEM_DELETE_API.CHANGE_DATA.ENDPOINT(
          data.purchaseId
        ),
        data,
      });

      dispatch({
        type: PURCHASE_ADMIN_ITEM_DELETE_ACTION_TYPE.FORM_SUCCESS,
      });
    } catch (error) {
      if (error) {
        console.log(error);
      }
      if (error.response) {
        dispatch({
          type: PURCHASE_ADMIN_ITEM_DELETE_ACTION_TYPE.FORM_ERROR,
          errorMessage: error.response.data.message,
        });
      }
    }
  };
}
