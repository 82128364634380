import React from "react";
import { useNavigate } from "react-router-dom";

import { UserAdminCreateInfoComponent } from "./user-admin-create-info.component";

import { USER_ADMIN_CREATE_ROUTE_PATH } from "../user-admin-create/user-admin-create.constant";

export function UserAdminCreateInfoContainer() {
  const navigate = useNavigate();

  const onSubmitForm = () => {
    return navigate(USER_ADMIN_CREATE_ROUTE_PATH);
  };

  return <UserAdminCreateInfoComponent onSubmitForm={onSubmitForm} />;
}
