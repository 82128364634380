export const PURCHASE_ADMIN_ITEM_STORE_NAME = "PURCHASE_ADMIN_ITEM";

export const PURCHASE_ADMIN_ITEM_ROUTE_PATH = "/admin/purchase/:purchaseId";

export const PURCHASE_ADMIN_ITEM_ROUTE_PATH_DYNAMIC = (
  { purchaseId } = {
    purchaseId: "[purchaseId]",
  }
) => `/admin/purchase/${purchaseId}`;

export const PURCHASE_ADMIN_ITEM_API = {
  PURCHASE_ADMIN_ITEM: {
    ENDPOINT: (purchaseId) => `/purchase/${purchaseId}/admin`,
    TYPE: "GET",
  },
};

export const PURCHASE_ADMIN_ITEM_ACTION_TYPE = {
  REQUEST_PENDING: "PURCHASE_ADMIN_ITEM_ACTION_TYPE.REQUEST_PENDING",
  REQUEST_SUCCESS: "PURCHASE_ADMIN_ITEM_ACTION_TYPE.REQUEST_SUCCESS",
  REQUEST_ERROR: "PURCHASE_ADMIN_ITEM_ACTION_TYPE.REQUEST_ERROR",
  REQUEST_UPDATE_SUCCESS:
    "PURCHASE_ADMIN_ITEM_ACTION_TYPE.REQUEST_UPDATE_SUCCESS",
  REQUEST_UPDATE_PENDING:
    "PURCHASE_ADMIN_ITEM_ACTION_TYPE.REQUEST_UPDATE_PENDING",
};

export const PURCHASE_ADMIN_ITEM_DATA_NAME = {
  ID: "id",
  NOTES: "notes",
  METADATA: "metadata",
  PHONE: "phone",
  CITY: "city",
  POST_OFFICE: "postOffice",
  TRACK_NUMBER: "trackNumber",
  CART: "cart",
  INVOICE_LINK: "invoiceLink",
  GDS_ITEM: "gdsItem",
  EMAIL: "email",

  COMMENTS: "comments",
  TYPE: "type",
  CREATE_DATE: "createDate",
  STATUS: "status",
  PRICE: "price",
  VALUTE: "valute",
  TIMEZONE: "timezone",
  PAYMENT: "payment",
  PAYMENT_ID: "id",
  PAID: "paid",
  AMOUNT: "amount",
  CONFIRM: "confirm",
  MANUAL_PAID: "manualPaid",
  SYSTEM: "system",

  WEIGHT: "weight",
  AMOUNT: "amount",
  FULL_NAME: "fullName",
  REGION: "region",
  PAID: "paid",
  CATEGORY: "category",

  GDS: "gds",
  GDS_ID: "id",
  GDS_PRICE: "price",
  GDS_TITLE: "title",

  IS_CUSTOMER: "isCustomer",
  CUSTOMER: "customer",
  CUSTOMER_ID: "id",
  CUSTOMER_EMAIL: "email",
  CUSTOMER_LOGIN: "login",
  CUSTOMER_TIMEZONE: "timezone",
  CUSTOMER_CONFIRM_EMAIL: "confirmEmail",
};

// НИГДЕ НЕ ИСПОЛЬЗУЕТСЯ ???
export const PURCHASE_ADMIN_ITEM_TAB = (purchaseId, redirect) => [
  {
    id: 0,
    tid: "NAVIGATION.TAB.PURCHASE.ADMIN.ITEM",
    path: PURCHASE_ADMIN_ITEM_ROUTE_PATH,
    action: () => {
      redirect(PURCHASE_ADMIN_ITEM_ROUTE_PATH_DYNAMIC({ purchaseId }));
    },
  },
];
