import { GDS_SIZE_VIEW_VALUE } from "../gds/gds.type";

export function convertDataGdsList(inputArray) {
  if (inputArray?.length < 1) {
    return;
  }

  const resultArray = [];

  inputArray.forEach((item, index) => {
    const title = item?.gds?.title;
    const amount = Number(item?.amount);
    const price = parseFloat(item.gds.price.replace(",", "."));
    const totalPrice = price * amount;
    const size = item?.gdsItem?.size
      ? GDS_SIZE_VIEW_VALUE[item.gdsItem.size]
      : "no size";

    const newArray = [
      index + 1,
      `${title}, ${size}`,
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      amount.toString() + " шт.",
      "",
      "",
      price.toString(),
      "",
      totalPrice.toFixed(2),
      "",
    ];

    resultArray.push(newArray);
  });

  return resultArray;
}

export function convertDateToUkrainianFormat(dateString) {
  const date = new Date(dateString);

  if (isNaN(date)) return "Некоректна дата";

  const monthsUkrainian = [
    "січня",
    "лютого",
    "березня",
    "квітня",
    "травня",
    "червня",
    "липня",
    "серпня",
    "вересня",
    "жовтня",
    "листопада",
    "грудня",
  ];

  const day = date.getDate();
  const month = monthsUkrainian[date.getMonth()];
  const year = date.getFullYear();

  return `${day} ${month} ${year} р.`;
}
