import React from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

import { TextComponent } from "../../../lib/common/text/text.component";
import { GDS_ITEM_ADMIN_ITEM_ROUTE_PATH_DYNAMIC } from "../../gds-item-admin-item/gds-item-admin-item.constant";
import {
  GDS_ITEM_ADMIN_LIST_DATA_NAME,
  GDS_SIZE_VIEW_VALUE,
} from "../gds-item-admin-list.constant";

export function GdsItemAdminListViewComponent(props) {
  const {
    data: { list: data },
    gdsId,
  } = props;

  if (data.length === 0) {
    return (
      <Box sx={{ px: 6, pt: 4 }}>
        <TextComponent
          variant="body1"
          tid="GDS_ITEM.ADMIN.LIST.LIST_IS_EMPTY"
        />
      </Box>
    );
  }

  const navigate = useNavigate();

  const handleClickItem = (gdsItemId) => () =>
    navigate(GDS_ITEM_ADMIN_ITEM_ROUTE_PATH_DYNAMIC({ gdsItemId, gdsId }));

  return (
    <List sx={{ pt: 4 }}>
      {data.map((item) => (
        <ListItem onClick={handleClickItem(item.id)} button variant="list">
          <ListItemText
            primary={
              <TextComponent variant="body1">
                <TextComponent
                  tid="GDS_ITEM.ADMIN.LIST.LIST.ID"
                  tvalue={{
                    id: item[GDS_ITEM_ADMIN_LIST_DATA_NAME.ID],
                  }}
                />
                <br />
                <TextComponent
                  tid="GDS_ITEM.ADMIN.LIST.LIST.ORDER"
                  tvalue={{
                    order: item[GDS_ITEM_ADMIN_LIST_DATA_NAME.ORDER],
                  }}
                />
                <br />
                <TextComponent
                  tid="GDS_ITEM.ADMIN.LIST.LIST.STATUS"
                  tvalue={{
                    status: item[GDS_ITEM_ADMIN_LIST_DATA_NAME.STATUS].text,
                  }}
                />
                <br />
                <TextComponent
                  tid="GDS_ITEM.ADMIN.LIST.LIST.SIZE"
                  tvalue={{
                    size: GDS_SIZE_VIEW_VALUE[
                      item[GDS_ITEM_ADMIN_LIST_DATA_NAME.SIZE]
                    ],
                  }}
                />
              </TextComponent>
            }
          />
        </ListItem>
      ))}
    </List>
  );
}
