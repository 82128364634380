import React from "react";

import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";

import { SkeletonDataViewComponent } from "../../lib/common/skeleton-data-view/skeleton-data-view.component";

import { PurchaseCustomerItemDataGdsContainer } from "../purchase-customer-item-data-gds/purchase-customer-item-data-gds.container";

import { PURCHASE_TYPE_TYPE } from "../../lib/common/purchase/purchase.type";

export function PurchaseCustomerItemDataContentComponent(props) {
  const { isPending, isSuccess, data } = props;

  const type = data?.type?.id;
  return (
    <Box>
      {isSuccess && (
        <React.Fragment>
          {type === PURCHASE_TYPE_TYPE.GDS && (
            <PurchaseCustomerItemDataGdsContainer />
          )}
        </React.Fragment>
      )}

      {isPending && (
        <Paper sx={{ p: 6 }}>
          <Box>
            <SkeletonDataViewComponent />
          </Box>
        </Paper>
      )}
    </Box>
  );
}
