import React from "react";
import { useSearchParams, useRouteError } from "react-router-dom";
import Alert from "@mui/material/Alert";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";

import { TextComponent } from "../../lib/common/text";

export function ErrorContainer() {
  let [searchParams, setSearchParams] = useSearchParams();

  const { error } = useRouteError();

  const message = searchParams.get("message");
  const dist = searchParams.get("dist");

  return (
    <Paper>
      <Alert severity="error">
        <Grid container spacing={2}>
          {message ? (
            <Grid item>{message}</Grid>
          ) : (
            <Grid item>
              <TextComponent
                tid={
                  error?.message ? error?.message : "ERROR.PAGE.ERROR_MESSAGE"
                }
              />
            </Grid>
          )}
          {dist && (
            <Grid
              sx={{
                whiteSpace: "break-spaces",
                fontSize: "10px",
                wordBreak: "break-all",
                opacity: 0.5,
              }}
              item
            >
              {dist}
            </Grid>
          )}
        </Grid>
      </Alert>
    </Paper>
  );
}
