import { httpRequest } from '../../main/http';

import {
  PURCHASE_CUSTOMER_ITEM_UPDATE_DATA_API,
  PURCHASE_CUSTOMER_ITEM_UPDATE_DATA_ACTION_TYPE,
} from './purchase-customer-item-update-data.constant';

export function uploadPurchaseCustomerItemUpdateDataForm(data) {
  return async (dispatch) => {
    dispatch({
      type: PURCHASE_CUSTOMER_ITEM_UPDATE_DATA_ACTION_TYPE.FORM_PENDING,
    });

    try {
      await httpRequest({
        method: PURCHASE_CUSTOMER_ITEM_UPDATE_DATA_API.CHANGE_DATA.TYPE,
        url: PURCHASE_CUSTOMER_ITEM_UPDATE_DATA_API.CHANGE_DATA.ENDPOINT(
          data.purchaseId,
        ),
        data,
      });

      dispatch({
        type: PURCHASE_CUSTOMER_ITEM_UPDATE_DATA_ACTION_TYPE.FORM_SUCCESS,
      });
    } catch (error) {
      if (error) {
        console.log(error);
      }
      if (error.response) {
        dispatch({
          type: PURCHASE_CUSTOMER_ITEM_UPDATE_DATA_ACTION_TYPE.FORM_ERROR,
          errorMessage: error.response.data.message,
        });
      }
    }
  };
}
