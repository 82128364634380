export const XLSX_COLUMN_TYPE = {
  ID: 'ID замовлення',
  NOTES: 'Примітки',
  COMMENTS: 'Коментарі',
  PRICE: 'Загальна ціна',
  TYPE_ID: 'Типу замовлення',
  CREATE_DATE: 'Дата створення',
  GDS_ORDER_AMOUNT: 'Кількість',
  GDS_ID: 'ID товару',
  GDS_ORDER_TITLE: 'Назва товару',
  GDS_ORDER_DESCRIPTION: 'Опис товару',
  GDS_ORDER_URL: 'Посилання на товар',
  GDS_ORDER_STATUS: 'Статус товару',
  GDS_ORDER_CATEGORY: 'Категорія товару',
  GDS_ORDER_TYPE: 'Тип товару',
  GDS_ORDER_CREATE_DATE: 'Дата створення товару',
  GDS_ORDER_UPDATE_DATE: 'Дата оновлення товару',
  GDS_ORDER_AMOUNT_VALUE: 'Загальна кількість',
  GDS_ORDER_PRICE: 'Ціна товару',
  GDS_ORDER_WEIGHT: 'Вага товару',
  GDS_ORDER_HAS_ORDER: 'Товар',
  PAID: 'Сплачено',
  PAYMENT: 'Платіж',
  IS_CUSTOMER: 'Клієнт',
  CUSTOMER_EMAIL: 'Email',
  CUSTOMER_ID: 'ID клієнта',
  CUSTOMER_PHONE: 'Телефон',
  CUSTOMER_FULL_NAME: "Повне ім'я",
  CUSTOMER_POST_OFFICE: 'Поштове відділення',
  CUSTOMER_REGION: 'Регіон',
  CUSTOMER_CITY: 'Місто',
  TRACK_NUMBER: 'Номер відстеження',
  WEIGHT: 'Загальна вага',
  SIZE: 'Розмір',
};
