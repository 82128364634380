import { GDS_ADMIN_LIST_DATA_NAME } from './gds-admin-list.constant';

import {
  convertGdsStatus,
  convertGdsType,
  convertGdsCategory,
  convertSize,
} from '../../lib/common/gds/gds.convert';
import { convertDatetime } from '../../lib/common/convert/convert.core';

export const convertGdsAdminList = (d) => ({
  [GDS_ADMIN_LIST_DATA_NAME.LIST]: d.list.map((data) => {
    return {
      [GDS_ADMIN_LIST_DATA_NAME.ORDER]: data[GDS_ADMIN_LIST_DATA_NAME.ORDER],
      [GDS_ADMIN_LIST_DATA_NAME.TITLE]: data[GDS_ADMIN_LIST_DATA_NAME.TITLE],

      [GDS_ADMIN_LIST_DATA_NAME.PRICE]: data[GDS_ADMIN_LIST_DATA_NAME.PRICE],
      [GDS_ADMIN_LIST_DATA_NAME.AMOUNT]: data[GDS_ADMIN_LIST_DATA_NAME.AMOUNT],
      [GDS_ADMIN_LIST_DATA_NAME.WEIGHT]: data[GDS_ADMIN_LIST_DATA_NAME.WEIGHT],
      [GDS_ADMIN_LIST_DATA_NAME.SIZE]: data[GDS_ADMIN_LIST_DATA_NAME.SIZE]
        ? convertSize(data[GDS_ADMIN_LIST_DATA_NAME.SIZE])
        : null,

      [GDS_ADMIN_LIST_DATA_NAME.URL]: data[GDS_ADMIN_LIST_DATA_NAME.URL],
      [GDS_ADMIN_LIST_DATA_NAME.CREATE_DATA]: convertDatetime(
        data[GDS_ADMIN_LIST_DATA_NAME.CREATE_DATA],
      ),
      [GDS_ADMIN_LIST_DATA_NAME.ID]: data[GDS_ADMIN_LIST_DATA_NAME.ID],

      [GDS_ADMIN_LIST_DATA_NAME.STATUS]: convertGdsStatus(
        data[GDS_ADMIN_LIST_DATA_NAME.STATUS],
      ),
      [GDS_ADMIN_LIST_DATA_NAME.TYPE]: convertGdsType(
        data[GDS_ADMIN_LIST_DATA_NAME.TYPE],
      ),
      [GDS_ADMIN_LIST_DATA_NAME.CATEGORY]: convertGdsCategory(
        data[GDS_ADMIN_LIST_DATA_NAME.CATEGORY],
      ),
    };
  }),
  [GDS_ADMIN_LIST_DATA_NAME.CATEGORY]: d[GDS_ADMIN_LIST_DATA_NAME.CATEGORY],
  [GDS_ADMIN_LIST_DATA_NAME.AMOUNT]: d[GDS_ADMIN_LIST_DATA_NAME.AMOUNT],
  [GDS_ADMIN_LIST_DATA_NAME.SKIP]: d[GDS_ADMIN_LIST_DATA_NAME.SKIP],
  [GDS_ADMIN_LIST_DATA_NAME.TAKE]: d[GDS_ADMIN_LIST_DATA_NAME.TAKE],
  [GDS_ADMIN_LIST_DATA_NAME.STATUS]: d[GDS_ADMIN_LIST_DATA_NAME.STATUS],
  [GDS_ADMIN_LIST_DATA_NAME.TYPE]: d[GDS_ADMIN_LIST_DATA_NAME.TYPE],
  [GDS_ADMIN_LIST_DATA_NAME.SORT_ID]: d[GDS_ADMIN_LIST_DATA_NAME.SORT_ID],
  [GDS_ADMIN_LIST_DATA_NAME.SORT_ORDER]: d[GDS_ADMIN_LIST_DATA_NAME.SORT_ORDER],
});
