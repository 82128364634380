import Box from '@mui/material/Box';

import { TextComponent } from '../../../lib/common/text';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { ModalConfirmComponent } from '../../../lib/common/modal-confirm/modal-confirm.component';

export const PurchaseAdminItemDeleteForm = (props) => {
  const {
    handleChange,
    handleBlur,
    handleSubmit,
    values,
    errors,
    touched,
    isValid,
    isSubmitting,
    submitForm,
    pageLoading,
    isSuccess,
    isError,
    isPending,
  } = props;

  const isFieldError = (name) => {
    return errors[name] && touched[name] && errors[name];
  };

  const isSubmitDisabled = () => {
    if (isError) {
      return false;
    }

    if (isPending) {
      return true;
    }

    if (!isValid || isSuccess || pageLoading) {
      return true;
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box sx={{ pt: 4 }}>
        <Grid spacing={3} container>
          <Grid item>
            <ModalConfirmComponent
              action={submitForm}
              disabled={isSubmitDisabled()}
            >
              <Button disabled={isSubmitDisabled()} fullWidth>
                <TextComponent tid="PURCHASE.ADMIN.DELETE.BUTTON" />
              </Button>
            </ModalConfirmComponent>
          </Grid>
        </Grid>
      </Box>
    </form>
  );
};
