import { GDS_ITEM_ADMIN_LIST_ROUTE_PATH_DYNAMIC } from "../gds-item-admin-list/gds-item-admin-list.constant";

export const GDS_ADMIN_ITEM_ROUTE_PATH = "/admin/gds/:gdsId";

export const GDS_ADMIN_ITEM_ROUTE_PATH_DYNAMIC = (gdsId) =>
  `/admin/gds/${gdsId}`;

export const getGdsAdminItemTab = (gdsId) => {
  return [
    {
      id: 0,
      tid: "NAVIGATION.TAB.GDS.ITEM",
      path: GDS_ADMIN_ITEM_ROUTE_PATH_DYNAMIC(gdsId),
    },
    {
      id: 1,
      tid: "NAVIGATION.TAB.GDS.ITEM_LIST",
      path: GDS_ITEM_ADMIN_LIST_ROUTE_PATH_DYNAMIC(gdsId),
    },
  ];
};
