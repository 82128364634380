export const PURCHASE_TYPE_TYPE = {
  GDS: 1,
};

export const PURCHASE_STATUS_TYPE = {
  NEW: 1,
  IN_PROGRESS: 2,
  DONE: 3,
  DELETED: 4,
};

export const PURCHASE_LANG_TYPE = {
  EN: 1,
  RU: 2,
};

export const PURCHASE_TYPE_VALUE = {
  1: "GDS",
};

export const PURCHASE_STATUS_VALUE = {
  1: "NEW",
  2: "IN_PROGRESS",
  3: "DONE",
  4: "DELETED",
};

export const PURCHASE_LANG_VALUE = {
  1: "EN",
  2: "RU",
};

export const PURCHASE_TYPE_OPTION = [
  { id: 1, value: 1, tid: "PURCHASE.DATA.TYPE.GDS" },
];

export const PURCHASE_STATUS_OPTION = [
  { id: 1, value: 1, tid: "PURCHASE.DATA.STATUS.NEW" },
  { id: 2, value: 2, tid: "PURCHASE.DATA.STATUS.IN_PROGRESS" },
  { id: 3, value: 3, tid: "PURCHASE.DATA.STATUS.DONE" },
  { id: 4, value: 4, tid: "PURCHASE.DATA.STATUS.DELETED" },
];

export const PURCHASE_STATUS_VIEW_VALUE = {
  1: "Нове",
  2: "В прогресі",
  3: "Виконано",
  4: "Було видалено",
};

export const PURCHASE_LANG_OPTION = [
  { id: 1, value: 1, tid: "PURCHASE.DATA.LANG.EN" },
  { id: 2, value: 2, tid: "PURCHASE.DATA.LANG.RU" },
];

export const PURCHASE_DATA_NAME = {
  ID: "id",
  TYPE: "type",
  CREATE_DATE: "createDate",

  PAYMENT: "payment",
};
