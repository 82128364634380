export const NOTICE_DATA_TYPE = {
  ID: "id",
  VIEW: "view",
  DATA: "data",
  TYPE: "type",
  CREATE_DATE: "createDate",
  ACTIVE: "active",
  HOUR: "hour",
  USER: "user",
  USER_LOGIN: "login",
  USER_ID: "id",
  TEXT: "text",
  LINK: "link",
  VISUAL: "visual",
  ACTION: "action",
};

export const NOTICE_TYPE_TYPE = {
  USER_CREATE: 1,
  USER_VERIFICATION: 2,
  USER_CONFIRMED: 3,
  USER_RECOVERY: 4,
  USER_RESTORED: 5,
  USER_PASSWORD_CHANGE: 6,
  USER_EMAIL_CHANGE: 7,
  USER_TIMEZONE_CHANGE: 8,
  USER_DATA_CHANGE: 9,
  PURCHASE_CREATE: 10,
  PAYMENT_NEED_PAY: 11,
  PURCHASE_REMOVED_LIKE_CUSTOMER: 12,
  PURCHASE_GET_GDS_ITEM: 13,
  PURCHASE_DONE_FOR_CUSTOMER: 14,
  PURCHASE_UPDATE_STATUS_FOR_CUSTOMER: 15,
  PAYMENT_CREATE: 16,
  PAYMENT_CREATE_FOR_ADMIN: 17,
};

export const NOTICE_TYPE_VALUE = {
  1: "USER_CREATE",
  2: "USER_VERIFICATION",
  3: "USER_CONFIRMED",
  4: "USER_RECOVERY",
  5: "USER_RESTORED",
  6: "USER_PASSWORD_CHANGE",
  7: "USER_EMAIL_CHANGE",
  8: "USER_TIMEZONE_CHANGE",
  9: "USER_DATA_CHANGE",
  10: "PURCHASE_CREATE",
  11: "PAYMENT_NEED_PAY",
  12: "PURCHASE_REMOVED_LIKE_CUSTOMER",
  13: "PURCHASE_GET_GDS_ITEM",
  14: "PURCHASE_DONE_FOR_CUSTOMER",
  15: "PURCHASE_UPDATE_STATUS_FOR_CUSTOMER",
  16: "PAYMENT_CREATE",
  17: "PAYMENT_CREATE_FOR_ADMIN",
};

export const VISUAL_NOTICE_TYPE_TYPE = {
  DEFAULT: 1,
  PAYMENT_CREATE: 2,
  PURCHASE_ADDED: 3,
};
