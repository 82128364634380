import { PURCHASE_ADMIN_ITEM_DATA_NAME } from "./purchase-admin-item.constant";

import {
  convertPurchaseType,
  convertPurchaseStatus,
} from "../../lib/common/purchase/purchase.convert";

import { convertTimezone } from "../../lib/common/user/user.convert";
import { convertDatetime } from "../../lib/common/convert/convert.core";
import { convertPaymentSystemType } from "../../lib/common/payment/payment.convert";

export const convertPurchaseAdminItem = (data) => {
  const paymentData = data[PURCHASE_ADMIN_ITEM_DATA_NAME.PAYMENT] || null;

  const paid = !!data[PURCHASE_ADMIN_ITEM_DATA_NAME.PAYMENT];

  const payment = paid
    ? {
        [PURCHASE_ADMIN_ITEM_DATA_NAME.PAYMENT_ID]:
          paymentData[PURCHASE_ADMIN_ITEM_DATA_NAME.PAYMENT_ID],
        [PURCHASE_ADMIN_ITEM_DATA_NAME.MANUAL_PAID]: Boolean(
          paymentData[PURCHASE_ADMIN_ITEM_DATA_NAME.MANUAL_PAID]
        ),
        [PURCHASE_ADMIN_ITEM_DATA_NAME.CONFIRM]:
          paymentData[PURCHASE_ADMIN_ITEM_DATA_NAME.CONFIRM],
        [PURCHASE_ADMIN_ITEM_DATA_NAME.SYSTEM]: convertPaymentSystemType(
          paymentData[PURCHASE_ADMIN_ITEM_DATA_NAME.SYSTEM]
        ),
      }
    : null;

  const purchaseType = convertPurchaseType(
    data[PURCHASE_ADMIN_ITEM_DATA_NAME.TYPE]
  );

  const isCustomer = !!data[PURCHASE_ADMIN_ITEM_DATA_NAME.CUSTOMER];
  const customerData = data[PURCHASE_ADMIN_ITEM_DATA_NAME.CUSTOMER];

  const customer = isCustomer
    ? {
        ...customerData,
        [PURCHASE_ADMIN_ITEM_DATA_NAME.CUSTOMER_TIMEZONE]: convertTimezone(
          customerData[PURCHASE_ADMIN_ITEM_DATA_NAME.CUSTOMER_TIMEZONE]
        ),
      }
    : null;

  return {
    [PURCHASE_ADMIN_ITEM_DATA_NAME.ID]: data[PURCHASE_ADMIN_ITEM_DATA_NAME.ID],
    [PURCHASE_ADMIN_ITEM_DATA_NAME.METADATA]:
      data[PURCHASE_ADMIN_ITEM_DATA_NAME.METADATA],
    [PURCHASE_ADMIN_ITEM_DATA_NAME.INVOICE_LINK]:
      data[PURCHASE_ADMIN_ITEM_DATA_NAME.INVOICE_LINK],

    [PURCHASE_ADMIN_ITEM_DATA_NAME.NOTES]:
      data[PURCHASE_ADMIN_ITEM_DATA_NAME.NOTES],
    [PURCHASE_ADMIN_ITEM_DATA_NAME.COMMENTS]:
      data[PURCHASE_ADMIN_ITEM_DATA_NAME.COMMENTS],

    [PURCHASE_ADMIN_ITEM_DATA_NAME.PRICE]:
      data[PURCHASE_ADMIN_ITEM_DATA_NAME.PRICE],

    [PURCHASE_ADMIN_ITEM_DATA_NAME.STATUS]: convertPurchaseStatus(
      data[PURCHASE_ADMIN_ITEM_DATA_NAME.STATUS]
    ),

    [PURCHASE_ADMIN_ITEM_DATA_NAME.TYPE]: purchaseType,

    [PURCHASE_ADMIN_ITEM_DATA_NAME.CREATE_DATE]: convertDatetime(
      data[PURCHASE_ADMIN_ITEM_DATA_NAME.CREATE_DATE]
    ),

    [PURCHASE_ADMIN_ITEM_DATA_NAME.CART]:
      data[PURCHASE_ADMIN_ITEM_DATA_NAME.CART],

    [PURCHASE_ADMIN_ITEM_DATA_NAME.PAID]: paid,
    [PURCHASE_ADMIN_ITEM_DATA_NAME.PAYMENT]: payment,

    [PURCHASE_ADMIN_ITEM_DATA_NAME.IS_CUSTOMER]: isCustomer,
    [PURCHASE_ADMIN_ITEM_DATA_NAME.CUSTOMER]: customer,

    [PURCHASE_ADMIN_ITEM_DATA_NAME.TRACK_NUMBER]:
      data[PURCHASE_ADMIN_ITEM_DATA_NAME.TRACK_NUMBER],
    [PURCHASE_ADMIN_ITEM_DATA_NAME.WEIGHT]:
      data[PURCHASE_ADMIN_ITEM_DATA_NAME.WEIGHT],
  };
};
