import { httpRequest } from "../../main/http";

import {
  PURCHASE_CUSTOMER_LIST_API,
  PURCHASE_CUSTOMER_LIST_ACTION_TYPE,
} from "./purchase-customer-list.constant";

import { convertPurchaseCustomerList } from "./purchase-customer-list.convert";

export function getPurchaseCustomerList(data) {
  return async (dispatch) => {
    dispatch({
      type: PURCHASE_CUSTOMER_LIST_ACTION_TYPE.REQUEST_PENDING,
    });

    try {
      const res = await httpRequest({
        method: PURCHASE_CUSTOMER_LIST_API.PURCHASE_CUSTOMER_LIST.TYPE,
        url: PURCHASE_CUSTOMER_LIST_API.PURCHASE_CUSTOMER_LIST.ENDPOINT,
      });

      dispatch({
        type: PURCHASE_CUSTOMER_LIST_ACTION_TYPE.REQUEST_SUCCESS,
        data: convertPurchaseCustomerList(res.data),
      });
    } catch (error) {
      if (error) {
        console.log(error);
      }
      if (error.response) {
        dispatch({
          type: PURCHASE_CUSTOMER_LIST_ACTION_TYPE.REQUEST_ERROR,
          errorMessage: error.response.data.message,
        });
      }
    }
  };
}

export function updatePurchaseCustomerList(data) {
  return async (dispatch) => {
    dispatch({
      type: PURCHASE_CUSTOMER_LIST_ACTION_TYPE.REQUEST_UPDATE_PENDING,
    });

    try {
      const res = await httpRequest({
        method: PURCHASE_CUSTOMER_LIST_API.PURCHASE_CUSTOMER_LIST.TYPE,
        url: PURCHASE_CUSTOMER_LIST_API.PURCHASE_CUSTOMER_LIST.ENDPOINT,
      });

      dispatch({
        type: PURCHASE_CUSTOMER_LIST_ACTION_TYPE.REQUEST_UPDATE_SUCCESS,
        data: convertPurchaseCustomerList(res.data),
      });
    } catch (error) {
      if (error) {
        console.log(error);
      }
      if (error.response) {
        dispatch({
          type: PURCHASE_CUSTOMER_LIST_ACTION_TYPE.REQUEST_ERROR,
          errorMessage: error.response.data.message,
        });
      }
    }
  };
}
