export const NOTICE_ACCOUNT_LIST_ROUTE_PATH = "/account/notification";

export const NOTICE_ACCOUNT_LIST_STORE_NAME = "NOTICE_ACCOUNT_LIST";

export const NOTICE_ACCOUNT_LIST_API = {
  NOTICE_ACCOUNT_LIST: {
    ENDPOINT: `/notice`,
    TYPE: "GET",
  },
};

export const NOTICE_ACCOUNT_LIST_CRON_UPDATE = 5000;

export const NOTICE_ACCOUNT_LIST_ACTION_TYPE = {
  REQUEST_PENDING: "NOTICE_ACCOUNT_LIST_ACTION_TYPE.REQUEST_PENDING",
  REQUEST_UPDATE_PENDING:
    "NOTICE_ACCOUNT_LIST_ACTION_TYPE.REQUEST_UPDATE_PENDING",
  REQUEST_SUCCESS: "NOTICE_ACCOUNT_LIST_ACTION_TYPE.REQUEST_SUCCESS",
  REQUEST_UPDATE_SUCCESS:
    "NOTICE_ACCOUNT_LIST_ACTION_TYPE.REQUEST_UPDATE_SUCCESS",
  REQUEST_ERROR: "NOTICE_ACCOUNT_LIST_ACTION_TYPE.REQUEST_ERROR",
};

export const NOTICE_ACCOUNT_LIST_DATA_NAME = {
  LIST: "list",

  SKIP: "skip",
  TAKE: "take",
  AMOUNT: "amount",

  ID: "id",
  TYPE: "type",
  CREATE_DATE: "createDate",
  VIEW: "view",
  DATA: "data",
};
