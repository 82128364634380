export const GDS_ADMIN_ITEM_DATA_STORE_NAME = 'GDS_ADMIN_ITEM_DATA';

export const GDS_ADMIN_ITEM_DATA_API = {
  GDS_ADMIN_ITEM_DATA: {
    ENDPOINT: (gdsId) => `/gds/${gdsId}/admin`,
    TYPE: 'GET',
  },
};

export const GDS_ADMIN_ITEM_DATA_ACTION_TYPE = {
  REQUEST_PENDING: 'GDS_ADMIN_ITEM_DATA_ACTION_TYPE.REQUEST_PENDING',
  REQUEST_SUCCESS: 'GDS_ADMIN_ITEM_DATA_ACTION_TYPE.REQUEST_SUCCESS',
  REQUEST_ERROR: 'GDS_ADMIN_ITEM_DATA_ACTION_TYPE.REQUEST_ERROR',
  REQUEST_UPDATE_SUCCESS:
    'GDS_ADMIN_ITEM_DATA_ACTION_TYPE.REQUEST_UPDATE_SUCCESS',
  REQUEST_UPDATE_PENDING:
    'GDS_ADMIN_ITEM_DATA_ACTION_TYPE.REQUEST_UPDATE_PENDING',
};

export const GDS_ADMIN_ITEM_DATA_DATA_NAME = {
  ORDER: 'order',
  AMOUNT: 'amount',
  PRICE: 'price',
  WEIGHT: 'weight',
  TITLE: 'title',
  DESCRIPTION: 'description',
  URL: 'url',
  FILE_PREVIEW: 'filePreviewImage',
  FILE_LIST: 'fileImageList',
  CATEGORY: 'category',
  TYPE: 'type',
  TAG: 'tag',
  PPO: "ppoId",

  ID: 'id',
  BODY: 'body',

  STATUS: 'status',
  CREATE_DATE: 'createDate',
  UPDATE_DATE: 'updateDate',
};
