import React from "react";
import { useNavigate } from "react-router-dom";

import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import styled from "styled-components";

import { TextComponent } from "../../../lib/common/text/text.component";
import { PURCHASE_ADMIN_ITEM_DATA_NAME } from "../../purchase-admin-item/purchase-admin-item.constant";
import { GDS_ADMIN_ITEM_ROUTE_PATH_DYNAMIC } from "../../gds-admin-item/gds-admin-item.constant";
import {
  GDS_CATEGORY_VIEW_VALUE,
  GDS_SIZE_VIEW_VALUE,
} from "../../../lib/common/gds/gds.type";
import { PURCHASE_STATUS_VALUE } from "../../../lib/common/purchase/purchase.type";
import { GDS_TYPE } from "../../../lib/common/gds/gds.type";

export function PurchaseAdminItemDataViewComponent(props) {
  const { data } = props;
  const navigate = useNavigate();

  const handleLinkRoute = (path) => (e) => {
    e.preventDefault();
    return navigate(path);
  };

  return (
    <Grid container spacing={4} sx={{ pt: 4 }}>
      <Grid item>
        <Grid container spacing={3}>
          <Grid item>
            <TextComponent
              gutterBottom
              variant="dataViewTitle"
              tid="PURCHASE.ADMIN.ITEM_DATA.ID"
            />
            <TextComponent variant="dataViewContent">
              {data[PURCHASE_ADMIN_ITEM_DATA_NAME.ID]}
            </TextComponent>
          </Grid>

          <Grid item>
            <TextComponent
              gutterBottom
              variant="dataViewTitle"
              tid="PURCHASE.ADMIN.ITEM_DATA.CREATE_DATE"
            />
            <TextComponent variant="dataViewContent">
              {data[PURCHASE_ADMIN_ITEM_DATA_NAME.CREATE_DATE]}
            </TextComponent>
          </Grid>

          {data[PURCHASE_ADMIN_ITEM_DATA_NAME.INVOICE_LINK] && (
            <Grid item>
              <TextComponent
                gutterBottom
                variant="dataViewTitle"
                tid="PURCHASE.ADMIN.ITEM_DATA.INVOICE_URL"
              />
              <Link
                sx={{
                  color: "#ededed",
                  textDecorationColor: "#ededed !important",
                }}
                href={data[PURCHASE_ADMIN_ITEM_DATA_NAME.INVOICE_LINK]}
              >
                <TextComponent
                  tid="PURCHASE.ADMIN.ITEM_DATA.INVOICE_DOWNLOAD"
                  variant="dataViewContent"
                />
              </Link>
            </Grid>
          )}

          <Grid item>
            <TextComponent
              gutterBottom
              variant="dataViewTitle"
              tid="PURCHASE.ADMIN.ITEM_DATA.NOTES"
            />
            <TextComponent variant="dataViewContent">
              {data[PURCHASE_ADMIN_ITEM_DATA_NAME.NOTES] || (
                <TextComponent tid="PURCHASE.ADMIN.ITEM_DATA.NOTES_NULL" />
              )}
            </TextComponent>
          </Grid>

          <Grid item>
            <TextComponent
              gutterBottom
              variant="dataViewTitle"
              tid="PURCHASE.ADMIN.ITEM_DATA.FULL_NAME"
            />
            <TextComponent variant="dataViewContent">
              {data?.customer[PURCHASE_ADMIN_ITEM_DATA_NAME.FULL_NAME] || ""}
            </TextComponent>
          </Grid>

          <Grid item>
            <TextComponent
              gutterBottom
              variant="dataViewTitle"
              tid="PURCHASE.ADMIN.ITEM_DATA.EMAIL"
            />
            <TextComponent variant="dataViewContent">
              {data?.customer[PURCHASE_ADMIN_ITEM_DATA_NAME.EMAIL] || ""}
            </TextComponent>
          </Grid>

          <Grid item>
            <TextComponent
              gutterBottom
              variant="dataViewTitle"
              tid="PURCHASE.ADMIN.ITEM_DATA.REGION"
            />
            <TextComponent
              variant="dataViewContent"
              tid="PURCHASE.ADMIN.ITEM_DATA.REGION_VALUE"
              tvalue={{
                city: data.customer[PURCHASE_ADMIN_ITEM_DATA_NAME.CITY] || "",
                region:
                  data.customer[PURCHASE_ADMIN_ITEM_DATA_NAME.REGION] || "",
              }}
            />
          </Grid>

          <Grid item>
            <TextComponent
              gutterBottom
              variant="dataViewTitle"
              tid="PURCHASE.ADMIN.ITEM_DATA.POST"
            />
            <TextComponent variant="dataViewContent">
              {data?.customer[PURCHASE_ADMIN_ITEM_DATA_NAME.POST_OFFICE] || ""}
            </TextComponent>
          </Grid>

          <Grid item>
            <TextComponent
              gutterBottom
              variant="dataViewTitle"
              tid="PURCHASE.ADMIN.ITEM_DATA.CONTACTS"
            />
            <TextComponent variant="dataViewContent">
              {data?.customer[PURCHASE_ADMIN_ITEM_DATA_NAME.PHONE] || (
                <TextComponent tid="PURCHASE.ADMIN.ITEM_DATA.CONTACTS_NULL" />
              )}
            </TextComponent>
          </Grid>

          <Grid item>
            <TextComponent
              gutterBottom
              variant="dataViewTitle"
              tid="PURCHASE.ADMIN.ITEM_DATA.COMMENTS"
            />
            <TextComponent variant="dataViewContent">
              {data[PURCHASE_ADMIN_ITEM_DATA_NAME.COMMENTS] || (
                <TextComponent tid="PURCHASE.ADMIN.ITEM_DATA.COMMENTS_NULL" />
              )}
            </TextComponent>
          </Grid>
          <Grid item>
            <TextComponent
              gutterBottom
              variant="dataViewTitle"
              tid="PURCHASE.ADMIN.ITEM_DATA.METADATA"
            />
            {data[PURCHASE_ADMIN_ITEM_DATA_NAME.METADATA]?.view && (
              <Grid container spacing={1}>
                {Object.entries(
                  data[PURCHASE_ADMIN_ITEM_DATA_NAME.METADATA]?.view
                ).map((item, i) => (
                  <Grid key={i} item>
                    <TextComponent
                      variant="dataViewContent"
                      tid={`PURCHASE.ADMIN.ITEM_DATA.METADATA_DATA.${item[0].toUpperCase()}`}
                      tvalue={{ value: item[1] }}
                      sx={{ lineHeight: "1.3em" }}
                    />
                  </Grid>
                ))}
              </Grid>
            )}
          </Grid>
          <Grid item>
            <TextComponent
              gutterBottom
              variant="dataViewTitle"
              tid="PURCHASE.ADMIN.LIST.TRACK_NUMBER"
            />
            <TextComponent variant="dataViewContent">
              {data[PURCHASE_ADMIN_ITEM_DATA_NAME.TRACK_NUMBER] || (
                <TextComponent tid="PURCHASE.ADMIN.ITEM_DATA.TRACK_NUMBER_NULL" />
              )}
            </TextComponent>
          </Grid>
          <Grid item>
            <TextComponent
              gutterBottom
              variant="dataViewTitle"
              tid="PURCHASE.ADMIN.LIST.STATUS"
            />
            <TextComponent variant="dataViewContent">
              {PURCHASE_STATUS_VALUE[
                data[PURCHASE_ADMIN_ITEM_DATA_NAME.STATUS].id
              ] || <TextComponent tid="PURCHASE.ADMIN.ITEM_DATA.STATUS_NULL" />}
            </TextComponent>
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <TextComponent
          variant="title"
          component="div"
          tid="PURCHASE.ADMIN.ITEM_DATA.SHOPPING_CART"
        />
      </Grid>

      <Grid item>
        <Divider />
      </Grid>

      {data?.cart?.gdsOrders ? (
        data.cart.gdsOrders.map((e, index) => {
          const sizeNumber = e.gdsItem?.size;
          const sizeView = GDS_SIZE_VIEW_VALUE[sizeNumber];

          return (
            <Grid key={index} item>
              <Grid container spacing={3}>
                <Grid item>
                  <TextComponent
                    gutterBottom
                    variant="dataViewTitle"
                    tid="PURCHASE.ADMIN.ITEM_DATA.GDS_ID"
                  />
                  <Link
                    variant="body1"
                    underline="always"
                    sx={{ color: "#121212" }}
                    target="_blank"
                    href={GDS_ADMIN_ITEM_ROUTE_PATH_DYNAMIC(
                      e[PURCHASE_ADMIN_ITEM_DATA_NAME.GDS].id
                    )}
                    onClick={handleLinkRoute(
                      GDS_ADMIN_ITEM_ROUTE_PATH_DYNAMIC(
                        e[PURCHASE_ADMIN_ITEM_DATA_NAME.GDS].id
                      )
                    )}
                  >
                    <TextComponent variant="dataViewContent">
                      {e[PURCHASE_ADMIN_ITEM_DATA_NAME.GDS].id}
                    </TextComponent>
                  </Link>
                </Grid>
                <Grid item>
                  <TextComponent
                    gutterBottom
                    variant="dataViewTitle"
                    tid="PURCHASE.ADMIN.ITEM_DATA.GDS_TITLE"
                  />
                  <TextComponent variant="dataViewContent">
                    {e[PURCHASE_ADMIN_ITEM_DATA_NAME.GDS].title}
                  </TextComponent>
                </Grid>

                <Grid item>
                  <TextComponent
                    gutterBottom
                    variant="dataViewTitle"
                    tid="PURCHASE.ADMIN.ITEM_DATA.GDS_PRICE"
                  />
                  <TextComponent variant="dataViewContent">
                    {e[PURCHASE_ADMIN_ITEM_DATA_NAME.GDS].price}
                  </TextComponent>
                </Grid>
                <Grid item>
                  <TextComponent
                    gutterBottom
                    variant="dataViewTitle"
                    tid="PURCHASE.ADMIN.ITEM_DATA.GDS_TYPE_TITLE"
                  />
                  <TextComponent
                    variant="dataViewContent"
                    tid={
                      e[PURCHASE_ADMIN_ITEM_DATA_NAME.GDS].type ===
                      GDS_TYPE.ORDER
                        ? "GDS.DATA.TYPE.ORDER"
                        : "GDS.DATA.TYPE.PREORDER"
                    }
                  />
                </Grid>
                <Grid item>
                  <TextComponent
                    gutterBottom
                    variant="dataViewTitle"
                    tid="PURCHASE.ADMIN.ITEM_DATA.WEIGHT"
                  />
                  <TextComponent
                    variant="dataViewContent"
                    tid={e[PURCHASE_ADMIN_ITEM_DATA_NAME.GDS]?.weight}
                  />
                </Grid>
                <Grid item>
                  <TextComponent
                    gutterBottom
                    variant="dataViewTitle"
                    tid="PURCHASE.ADMIN.ITEM_DATA.GDS_CATEGORY_TITLE"
                  />
                  <TextComponent
                    variant="dataViewContent"
                    tid={
                      GDS_CATEGORY_VIEW_VALUE[
                        e[PURCHASE_ADMIN_ITEM_DATA_NAME.GDS].category
                      ]
                    }
                  />
                </Grid>
                <Grid item>
                  <TextComponent
                    gutterBottom
                    variant="dataViewTitle"
                    tid="PURCHASE.ADMIN.ITEM_DATA.GDS_DESCRIPTION"
                  />
                  <TextComponent
                    variant="dataViewContent"
                    tid={e[PURCHASE_ADMIN_ITEM_DATA_NAME.GDS].description}
                  />
                </Grid>
                {e[PURCHASE_ADMIN_ITEM_DATA_NAME.GDS_ITEM]?.size && (
                  <Grid item>
                    <TextComponent
                      gutterBottom
                      variant="dataViewTitle"
                      tid="PURCHASE.ADMIN.ITEM_DATA.SIZE"
                    />
                    <TextComponent variant="dataViewContent" tid={sizeView} />
                  </Grid>
                )}
                {index !== data.cart?.gdsOrders?.length - 1 && (
                  <Grid item>
                    <Divider />
                  </Grid>
                )}
              </Grid>
            </Grid>
          );
        })
      ) : (
        <Grid item>
          <Grid container spacing={3}>
            <Grid item>
              <TextComponent
                variant="dataViewContent"
                tid="PURCHASE.ADMIN.ITEM_DATA.GDS_NOT_SET"
              />
            </Grid>
          </Grid>
        </Grid>
      )}

      <Grid item>
        <TextComponent
          variant="title"
          component="div"
          tid="PURCHASE.ADMIN.ITEM_DATA.IN_GENERAL"
        />
      </Grid>

      <Grid item>
        <Divider />
      </Grid>

      <Grid item>
        <TextComponent
          gutterBottom
          variant="dataViewTitle"
          tid="PURCHASE.ADMIN.ITEM_DATA.WEIGHT"
        />
        <TextComponent variant="dataViewContent">
          {data[PURCHASE_ADMIN_ITEM_DATA_NAME.WEIGHT] || ""}
        </TextComponent>
      </Grid>

      <Grid item>
        <TextComponent
          gutterBottom
          variant="dataViewTitle"
          tid="PURCHASE.ADMIN.ITEM_DATA.ALL_ITEM"
        />
        <TextComponent variant="dataViewContent">
          {data.cart?.gdsOrders?.length}
        </TextComponent>
      </Grid>

      <Grid item>
        <TextComponent
          gutterBottom
          variant="dataViewTitle"
          tid="PURCHASE.ADMIN.ITEM_DATA.PRICE"
        />
        <TextComponent variant="dataViewContent">
          {data[PURCHASE_ADMIN_ITEM_DATA_NAME.PRICE]}
        </TextComponent>
      </Grid>

      <Grid item>
        <Divider />
      </Grid>

      <Grid item>
        <Grid container spacing={3}>
          {data[PURCHASE_ADMIN_ITEM_DATA_NAME.PAID] ? (
            <React.Fragment>
              <Grid item>
                <TextComponent
                  gutterBottom
                  variant="dataViewTitle"
                  tid="PURCHASE.ADMIN.ITEM_DATA.PAYMENT_SYSTEM"
                />
                <TextComponent variant="dataViewContent">
                  {data[PURCHASE_ADMIN_ITEM_DATA_NAME.PAYMENT].system.text}
                </TextComponent>
              </Grid>
              <Grid item>
                <TextComponent
                  gutterBottom
                  variant="dataViewTitle"
                  tid="PURCHASE.ADMIN.ITEM_DATA.PAYMENT_CONFIRM"
                />
                <TextComponent variant="dataViewContent">
                  {data[PURCHASE_ADMIN_ITEM_DATA_NAME.PAYMENT].confirm ? (
                    <TextComponent tid="PURCHASE.ADMIN.ITEM_DATA.PAYMENT_CONFIRM_TRUE" />
                  ) : (
                    <TextComponent tid="PURCHASE.ADMIN.ITEM_DATA.PAYMENT_CONFIRM_FALSE" />
                  )}
                </TextComponent>
              </Grid>
            </React.Fragment>
          ) : (
            <Grid item>
              <TextComponent
                gutterBottom
                variant="dataViewTitle"
                tid="PURCHASE.ADMIN.ITEM_DATA.PAID"
              />
              <TextComponent variant="dataViewContent">
                {data[PURCHASE_ADMIN_ITEM_DATA_NAME.PAID] ? (
                  <TextComponent tid="PURCHASE.ADMIN.ITEM_DATA.PAID_TRUE" />
                ) : (
                  <TextComponent tid="PURCHASE.ADMIN.ITEM_DATA.PAID_FALSE" />
                )}
              </TextComponent>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}
